// Standard lib
import React, { useState } from 'react';
// Imported libs
import { Box, Breadcrumb, BreadcrumbItem, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, Table, Tbody, Td, Text, Th, Thead, Tr, } from '@chakra-ui/react';
import { windowSankey } from '../configmenus/SankeyUtils';
export const SankeyModalWelcome = ({ t, active_page, dict_hook_ref_setter_show_dialog_components, never_see_again, additional_shortcut_item, external_pagination, external_content }) => {
    const [show_wecome, set_show_welcome] = useState(!never_see_again.current);
    dict_hook_ref_setter_show_dialog_components.ref_setter_show_modal_welcome.current = set_show_welcome;
    const content_rc_static = React.createElement(Table, { variant: 'table_welcome_buttons' },
        React.createElement(Thead, null,
            React.createElement(Tr, null, t('Menu.rcc_titre_princ'))),
        React.createElement(Tbody, null,
            React.createElement(Tr, null,
                React.createElement(Td, null, t('Menu.rcc_cdn_bold')),
                React.createElement(Td, null, t('Menu.rcc_cdn'))),
            React.createElement(Tr, null,
                React.createElement(Td, null, t('Menu.rcc_ctrl_scrll_bold')),
                React.createElement(Td, null, t('Menu.rcc_ctrl_scrll'))),
            React.createElement(Tr, null,
                React.createElement(Td, null, t('Menu.rcc_F7_bold')),
                React.createElement(Td, null, t('Menu.rcc_F7'))),
            React.createElement(Tr, null,
                React.createElement(Td, null, t('Menu.rcc_F8_bold')),
                React.createElement(Td, null, t('Menu.rcc_F8'))),
            React.createElement(Tr, null,
                React.createElement(Td, null, t('Menu.rcc_F9_bold')),
                React.createElement(Td, null, t('Menu.rcc_F9')))));
    const content_rc_not_static = React.createElement(React.Fragment, null,
        React.createElement(Box, { display: 'grid', gridTemplateColumns: '50% 50%', gridColumnGap: '0.25rem', width: '100%', justifySelf: 'center' },
            React.createElement(Table, { variant: 'table_welcome_buttons' },
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_select'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cs_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cs'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_click_and_drag_bold')),
                        React.createElement(Td, null, t('Menu.rcc_click_and_drag'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cdn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cdn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ad_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ad'))))),
            React.createElement(Table, { variant: 'table_welcome_buttons' },
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_edi'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_ds_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_ds'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_dn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_dn')))),
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_autre'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_s_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_s'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_fc_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_fc'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_dbm_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_dbm'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_ech_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_ech'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_scrll_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_scrll')))))),
        additional_shortcut_item);
    external_content['rc'] = windowSankey.SankeyToolsStatic ? content_rc_static : content_rc_not_static;
    const content = React.createElement(Modal, { variant: 'modal_welcome', isOpen: show_wecome && !never_see_again.current, onClose: () => set_show_welcome(false) },
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, { display: 'grid', gridTemplateColumns: 'minmax(10rem, 10%) auto', gridGap: '0.25rem' },
                React.createElement(Breadcrumb, { variant: 'pagination_welcome', separator: '', listProps: {
                        'display': 'grid',
                        'gridAutoFlow': 'rows',
                        'gridRowGap': '1rem',
                        'gridTemplateRows': 'repeat(auto, 1fr)',
                        'alignItems': 'center',
                        'padding': '0.25rem',
                        'marginBlockStart': '30vh'
                    } }, Object.entries(external_pagination)
                    .map((k) => {
                    return React.createElement(BreadcrumbItem, { isCurrentPage: active_page === k[0], key: k[0] }, k[1]);
                })),
                React.createElement(Box, { display: 'grid', gridAutoFlow: 'rows', padding: '0rem 0.5rem 0.5rem 0.5rem' },
                    React.createElement(Text, { textStyle: 'h1', fontSize: '1.5rem', color: 'white', background: 'primaire.2', borderRadius: '6px', paddingInlineStart: '1rem', width: 'calc(100% - 2rem)' }, t('welcome.' + active_page)),
                    external_content[active_page])),
            React.createElement(ModalFooter, { style: { justifyContent: 'center' } },
                React.createElement(Box, { layerStyle: 'box_footer_welcome' },
                    React.createElement(Checkbox, { variant: 'checkbox_dont_show_again', isChecked: never_see_again.current, onChange: evt => {
                            never_see_again.current = evt.target.checked;
                            localStorage.setItem('dontSeeAggainWelcome', '1');
                            set_show_welcome(false);
                        } }, t('dontSeeAgain'))))));
    return content;
};
