import React from 'react';
import { FaBan } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';
export const blockers_suite_sankey = (blocker_token_OSP, set_blocker_token_OSP, blocker_token_SSM, set_blocker_token_SSM) => {
    const block_OSP = React.createElement(Button, { variant: blocker_token_OSP ? 'token_blocker_activated' : 'token_blocker_deactivated', onClick: () => {
            set_blocker_token_OSP(!blocker_token_OSP);
        } },
        React.createElement(FaBan, null));
    const block_SSM = React.createElement(Button, { variant: blocker_token_SSM ? 'token_blocker_activated' : 'token_blocker_deactivated', onClick: () => {
            set_blocker_token_SSM(!blocker_token_SSM);
        } },
        React.createElement(FaBan, null));
    return {
        'block_osp': block_OSP,
        'block_ssm': block_SSM,
    };
};
