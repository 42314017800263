import React from 'react';
import FileSaver from 'file-saver';
import { Box, MenuItem, Tag } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
const clickSaveExcelSimple = (url_prefix, data) => {
    const root = window.location.origin;
    let url = root + url_prefix + 'sankey/save_excel_simple';
    const fetchData = {
        method: 'POST',
        body: JSON.stringify(data)
    };
    const showFile = (blob) => {
        const newBlob = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(newBlob, 'sankey.xlsx');
    };
    const cleanFile = () => {
        const fetchData = {
            method: 'POST'
        };
        url = root + url_prefix + 'sankey/clean_excel';
        fetch(url, fetchData);
    };
    fetch(url, fetchData).then(r => r.blob())
        .then(showFile).then(cleanFile);
};
export const MenuEnregistrerExcelSimple = (url_prefix, data) => {
    return React.createElement(MenuItem, { onClick: () => clickSaveExcelSimple(url_prefix, data) },
        React.createElement(FontAwesomeIcon, { style: { 'height': '1rem', 'width': '1rem' }, icon: faTable }),
        React.createElement(Box, null,
            'Excel Simple',
            " ",
            React.createElement(Tag, { colorScheme: "teal", style: { marginLeft: 'auto' } }, "Dev")));
};
