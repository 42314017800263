import React, { useRef, useState } from 'react';
import { Box, Button, TabPanel, Textarea } from '@chakra-ui/react';
import { OSTooltip } from './SankeyUtils';
export const MenuConfigurationLinksTooltip = ({ ComponentUpdater, multi_selected_links, t, menu_for_modal }) => {
    const { updateMenuConfigTextLinkTooltip } = ComponentUpdater;
    const [forceUpdate, setForceUpdate] = useState(false);
    const inputRef = useRef();
    const [editor_content_tooltip, sEditorContentNodeTooltip] = useState('');
    let tmp_editor_content_tooltip = editor_content_tooltip;
    let s_tmp_editor_content_changed = false;
    if (multi_selected_links.current.length > 0) {
        if (multi_selected_links.current[0].tooltip_text !== editor_content_tooltip) {
            s_tmp_editor_content_changed = true;
        }
    }
    const resetTextEditor = () => {
        if (multi_selected_links.current.length > 0) {
            if (typeof multi_selected_links.current[0].tooltip_text !== 'undefined') {
                // Reset textaera
                if (typeof inputRef.current !== 'undefined') {
                    if (inputRef.current !== null) {
                        inputRef.current.value = multi_selected_links.current[0].tooltip_text;
                    }
                }
                // Reset state value
                sEditorContentNodeTooltip(multi_selected_links.current[0].tooltip_text);
            }
            else {
                // Reset textaera
                if (typeof inputRef.current !== 'undefined') {
                    if (inputRef.current !== null) {
                        inputRef.current.value = '';
                    }
                }
                // Reset state value
                sEditorContentNodeTooltip('');
            }
        }
        else {
            // Reset textaera
            if (typeof inputRef.current !== 'undefined') {
                if (inputRef.current !== null) {
                    inputRef.current.value = '';
                }
            }
            // Reset state value
            sEditorContentNodeTooltip('');
        }
        setForceUpdate(!forceUpdate);
    };
    updateMenuConfigTextLinkTooltip.current.push(resetTextEditor);
    const content = React.createElement(React.Fragment, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Noeud.IB')),
        React.createElement(OSTooltip, { label: t('Flux.tooltips.IB') },
            React.createElement(Textarea, { rows: 5, ref: inputRef, defaultValue: editor_content_tooltip, onChange: (evt) => {
                    tmp_editor_content_tooltip = evt.target.value;
                    if (!s_tmp_editor_content_changed) {
                        sEditorContentNodeTooltip(tmp_editor_content_tooltip);
                    }
                }, onBlur: () => {
                    sEditorContentNodeTooltip(tmp_editor_content_tooltip);
                } })),
        React.createElement(Box, { as: 'span', layerStyle: 'options_2cols' },
            React.createElement(Button, { variant: 'menuconfigpanel_option_button_left', isDisabled: !s_tmp_editor_content_changed, backgroundColor: 'red.200', onClick: () => {
                    resetTextEditor();
                } }, t('Menu.annuler')),
            React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', isDisabled: !s_tmp_editor_content_changed, onClick: () => {
                    multi_selected_links.current.map(link => link.tooltip_text = tmp_editor_content_tooltip);
                    sEditorContentNodeTooltip(tmp_editor_content_tooltip);
                } }, t('Menu.submit'))));
    return menu_for_modal ? content :
        // [ 
        //   <Tab>
        //     <Box
        //       layerStyle='submenuconfig_tab'
        //     >
        //       {t('Flux.IS')}
        //     </Box>
        //   </Tab>,
        React.createElement(TabPanel, null, content);
};
