import * as d3 from 'd3';
// Local imports
import { OSPDragElements, OSPReturnOutOfBoundElements, OpposingDragElementsPlus } from './SankeyPlusNodes';
import { GetSankeyMinWidthAndHeight, hideLinkOnDragElement, selectOpenSankeyElementsInSelectionZone } from './import/OpenSankey';
// OpenSankey jscode
import { GetVerticalMarginForSankeyZone } from 'open-sankey/dist/configmenus/SankeyUtils';
export const OSPDrawLabels = (applicaTionData, applicationState, uiElementsRef, contextMenu, applicationContext, GetSankeyMinWidthAndHeight, closeAllMenuContext, applicationDraw, ComponentUpdater, object_to_update, link_function, start_point, resizeCanvas) => {
    const { data } = applicaTionData;
    const { multi_selected_nodes, multi_selected_links, multi_selected_label } = applicationState;
    const { pointer_pos } = contextMenu;
    const inv_scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, data.user_scale]);
    const scale = d3.scaleLinear()
        .range([0, 100])
        .domain([0, data.user_scale]);
    const data_plus = data;
    d3.selectAll('#g_label_handles *').remove();
    const add_labels = () => {
        const g_label = d3.select(' .opensankey #svg #g_label');
        Object.values(data_plus.labels)
            // .filter(zdt=>object_to_update.includes(zdt))
            .forEach(d => {
            g_label.select('#' + d.idLabel).remove();
            const gg_label = g_label.append('g').attr('x', d.x).attr('y', d.y)
                .attr('id', d.idLabel)
                .attr('class', 'gg_label')
                .classed('selected', multi_selected_label.current.includes(d))
                .attr('transform', 'translate(' + d.x + ',' + d.y + ')');
            gg_label.append('rect')
                .attr('class', 'zdt_zone')
                .classed('transparent_border', d.transparent_border)
                .attr('width', d.label_width)
                .attr('height', d.label_height)
                .attr('fill', d.color)
                .style('fill-opacity', +(d.opacity / 100))
                .attr('stroke', d.color_border)
                .attr('rx', 5);
            draw_text_zone_handles(applicaTionData, d, applicationState, applicationDraw, ComponentUpdater, link_function);
            gg_label.on('click', (event) => eventLabelClick(event, d, uiElementsRef, applicationState, multi_selected_label, multi_selected_nodes, multi_selected_links, ComponentUpdater));
            gg_label.on('mousedown', () => closeAllMenuContext());
            gg_label.on('contextmenu', evt => {
                if (!window.SankeyToolsStatic) {
                    evt.preventDefault();
                    pointer_pos.current = [evt.pageX, evt.pageY];
                    if (multi_selected_label.current.includes(d)) {
                        //applicationState.r_setter_editor_content_fo_zdt.current?.forEach(f=>f(d.content));
                        contextMenu.contextualised_zdt.current(d);
                    }
                    else {
                        multi_selected_label.current.forEach(l => {
                            deselect_visualy_zdt(l);
                        });
                        multi_selected_label.current = [d];
                        select_visualy_zdt(d);
                        //applicationState.r_setter_editor_content_fo_zdt.current?.forEach(f=>f(d.content));
                        contextMenu.contextualised_zdt.current(d);
                    }
                }
            });
            // Traite les labels qui sont des zone de texte
            gg_label
                .filter(() => !d.is_image)
                .append('foreignObject')
                .attr('width', d.label_width)
                .attr('height', d.label_height)
                .style('width', d.label_width)
                .style('height', d.label_height)
                .attr('id', d.idLabel + '_text')
                .append('xhtml:div')
                .attr('class', 'ql-editor')
                .html(d.content);
            gg_label
                .filter(() => d.is_image)
                .append('image')
                .attr('width', d.label_width)
                .attr('height', d.label_height)
                .style('width', d.label_width)
                .style('height', d.label_height)
                .attr('id', d.idLabel + '_img')
                .attr('href', d.image_src);
            if (!window.SankeyToolsStatic) {
                gg_label.call(dragLabelEvent(applicaTionData, applicationState, applicationContext, d, GetSankeyMinWidthAndHeight, scale, inv_scale, ComponentUpdater, link_function, start_point, resizeCanvas));
            }
            gg_label.append('rect')
                .attr('id', 'drag_zone_' + d.idLabel)
                .attr('width', d.label_width).attr('height', d.label_height)
                .attr('fill', 'none')
                .style('fill-opacity', 0)
                .attr('stroke', d.color_border)
                .attr('stroke-opacity', 0)
                .attr('stroke-width', (3))
                .attr('rx', 5);
        });
    };
    add_labels();
};
// Function triggered when a free label is selected, it add a thicker border ans some pointer events
export const eventLabelClick = (event, d, uiElementsRef, applicationState, multi_selected_label, multi_selected_nodes, multi_selected_links, ComponentUpdater) => {
    const { updateMenus } = ComponentUpdater;
    const { button_ref, accordion_ref, zdt_accordion_ref } = uiElementsRef;
    if ((event.ctrlKey || event.metaKey) && !(window.SankeyToolsStatic ? window.SankeyToolsStatic : false)) {
        const sankeyTooltip = d3.select('.sankey-tooltip');
        sankeyTooltip.style('opacity', 0);
        // Open side panel
        if (button_ref && button_ref.current && accordion_ref && accordion_ref.current === null) {
            if (multi_selected_label.current.includes(d)) {
                // if label is selected the accordion is not open and the label is deselected
                multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', false));
                multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', false));
                multi_selected_label.current.splice(multi_selected_label.current.indexOf(d), 1);
                updateMenus[1](!updateMenus[0]);
                return;
            }
            button_ref.current.click();
            setTimeout(() => {
                // Open element accordion if not already openend
                if (accordion_ref &&
                    accordion_ref.current &&
                    d3.select(accordion_ref.current).attr('aria-expanded') === 'false') {
                    accordion_ref.current.click();
                }
                // Open node accordion if not already openend
                if (accordion_ref && accordion_ref.current) {
                    if (zdt_accordion_ref &&
                        zdt_accordion_ref.current &&
                        d3.select(zdt_accordion_ref.current).attr('aria-expanded') === 'false') {
                        zdt_accordion_ref.current.click();
                    }
                }
                multi_selected_label.current.push(d);
                multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', true));
                multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', true));
                //applicationState.r_setter_editor_content_fo_zdt.current?.forEach(f=>f(d.content))
                updateMenus[1](!updateMenus[0]);
                ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
            }, 200);
        }
        // Open element accordion if not already openend
        if (accordion_ref &&
            accordion_ref.current &&
            d3.select(accordion_ref.current).attr('aria-expanded') === 'false') {
            accordion_ref.current.click();
        }
        // Open node accordion if not already openend
        if (accordion_ref && accordion_ref.current) {
            if (zdt_accordion_ref &&
                zdt_accordion_ref.current &&
                d3.select(zdt_accordion_ref.current).attr('aria-expanded') === 'false') {
                zdt_accordion_ref.current.click();
            }
        }
        if (multi_selected_label.current.includes(d)) {
            multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', false));
            multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', false));
            multi_selected_label.current.splice(multi_selected_label.current.indexOf(d), 1);
            // If we deselect a zdt use the last one selected as displayed in config
            // if(multi_selected_label.current.length>0)applicationState.r_setter_editor_content_fo_zdt.current?.forEach(f=>f(
            //   multi_selected_label.current[multi_selected_label.current.length-1].content)
            // )
            updateMenus[1](!updateMenus[0]);
        }
        else {
            multi_selected_label.current.push(d);
            multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', true));
            multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', true));
            // Display the content of the last zdt selected in the menu config
            updateMenus[1](!updateMenus[0]);
        }
        ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
    }
    else {
        multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', false));
        multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', false));
        multi_selected_label.current = [];
        multi_selected_nodes.current = [];
        multi_selected_links.current = [];
        updateMenus[1](!updateMenus[0]);
        ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
    }
};
// Function used to drag the free label
// To be dragged you need to select the free label
const dragLabelEvent = (applicationData, applicationState, applicationContext, d, GetSankeyMinWidthAndHeight, scale, inv_scale, ComponentUpdater, link_function, start_point, resizeCanvas) => {
    const { RedrawLinks } = link_function;
    const { data } = applicationData;
    const { multi_selected_label, multi_selected_nodes, ref_getter_mode_selection } = applicationState;
    const { updateComponenSaveInCache } = ComponentUpdater;
    const node_visible = [];
    const data_plus = data;
    return (d3.drag()
        .on('start', (evt) => {
        if (multi_selected_label.current.includes(d)) {
            d3.selectAll('.node_shape').nodes().forEach(element => {
                node_visible.push(d3.select(element).attr('id'));
            });
        }
        else if (ref_getter_mode_selection.current === 's' && !evt.ctrlKey) {
            // const pos = d3.pointer(evt)
            const pos = [evt.x, evt.y];
            start_point.current = pos;
            d3.select('#svg').append('g').attr('class', 'selection_zone')
                .append('rect').attr('x', pos[0]).attr('y', pos[1]).attr('width', 2).attr('height', 2).attr('fill', 'none').attr('stroke', 'black').attr('stroke-width', '2px').attr('stroke-dasharray', '5,5');
        }
        if (!(ref_getter_mode_selection.current === 's' && d3.selectAll('.selection_zone').nodes().length > 0)) {
            hideLinkOnDragElement(applicationData);
        }
    })
        .subject(Object).on('drag', function (event) {
        if (ref_getter_mode_selection.current === 's' && d3.selectAll('.selection_zone').nodes().length > 0) {
            // Create change the size of the selection zone according to the mouse
            const pos = [event.x, event.y];
            const new_x = (pos[0] > start_point.current[0]) ? start_point.current[0] : pos[0];
            const new_w = (pos[0] > start_point.current[0]) ? (pos[0] - start_point.current[0]) : start_point.current[0] - pos[0];
            const new_y = (pos[1] > start_point.current[1]) ? start_point.current[1] : pos[1];
            const new_h = (pos[1] > start_point.current[1]) ? (pos[1] - start_point.current[1]) : start_point.current[1] - pos[1];
            d3.select('.selection_zone rect').attr('x', new_x);
            d3.select('.selection_zone rect').attr('y', new_y);
            d3.select('.selection_zone rect').attr('width', Math.abs(new_w));
            d3.select('.selection_zone rect').attr('height', Math.abs(new_h));
        }
        else {
            // Drag zdt
            // Cherche si des element seront hors zone si on les drag
            // Si c'est le cas, pousse les éléments qui ne sont pas sélectionnés dans la direction opposé
            const out_of_zone_item = OSPReturnOutOfBoundElements(d, data, event, multi_selected_nodes, node_visible);
            // Pousse les element non sélectionnés dans la direction opposé
            if (out_of_zone_item.length > 0) {
                OpposingDragElementsPlus(out_of_zone_item, event, d, applicationData, applicationState);
            }
            OSPDragElements(applicationData, applicationState, applicationContext, d, event, GetSankeyMinWidthAndHeight, scale, inv_scale, ComponentUpdater, link_function);
        }
    })
        .on('end', (evt) => {
        if (ref_getter_mode_selection.current === 's' && d3.selectAll('.selection_zone').nodes().length > 0) {
            zone_selection_label(data_plus, multi_selected_label, evt, ComponentUpdater);
            selectOpenSankeyElementsInSelectionZone(applicationData, applicationState, ComponentUpdater, evt, start_point);
        }
        else if (multi_selected_label.current.length > 0) {
            RedrawLinks(Object.values(applicationData.display_links));
            resizeCanvas();
            updateComponenSaveInCache.current(false);
        }
    }));
};
export const sankey_plus_min_width_and_height = (applicationData) => {
    const { data } = applicationData;
    const [width, height] = GetSankeyMinWidthAndHeight(applicationData);
    let height_plus = 0;
    let width_plus = 0;
    const data_plus = data;
    Object.values(data_plus.labels).forEach(n => {
        height_plus = Math.max(height_plus, n.y + n.label_height);
        width_plus = Math.max(width_plus, (n.x + n.label_width));
    });
    height_plus = height_plus + (data_plus.grid_square_size * 2);
    width_plus = width_plus + (data_plus.grid_square_size * 2);
    const vertical_shift = GetVerticalMarginForSankeyZone();
    const has_scroll_bar = window.innerHeight - document.getElementsByTagName('html')[0].clientHeight;
    width_plus = Math.max(width_plus, window.innerWidth - 60 - has_scroll_bar);
    height_plus = Math.max(height_plus, window.innerHeight - 20 - (vertical_shift));
    return [Math.max(width, width_plus), Math.max(height, height_plus)];
};
export const zone_selection_label = (data, multi_selected_label, evt, ComponentUpdater) => {
    var _a, _b;
    if (d3.selectAll('.selection_zone').nodes().length > 0) {
        const z_x = Number(d3.select('.selection_zone rect').attr('x'));
        const z_y = Number(d3.select('.selection_zone rect').attr('y'));
        const z_w = Number(d3.select('.selection_zone rect').attr('width'));
        const z_h = Number(d3.select('.selection_zone rect').attr('height'));
        const transform_svg = (_b = (_a = d3.select('.opensankey #svg')) === null || _a === void 0 ? void 0 : _a.attr('transform')) !== null && _b !== void 0 ? _b : '';
        const scale_svg = (transform_svg) ? +transform_svg.split('scale(')[1].replace(')', '') : 1;
        if (evt.shiftKey) {
            Object.values(data.labels).filter(n => {
                var _a, _b, _c, _d;
                const width_n = ((_b = (_a = document.getElementById(n.idLabel)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width) !== null && _b !== void 0 ? _b : 0) / scale_svg;
                const height_n = ((_d = (_c = document.getElementById(n.idLabel)) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().height) !== null && _d !== void 0 ? _d : 0) / scale_svg;
                return !multi_selected_label.current.includes(n) && n.x >= z_x && n.x <= (z_x + z_w) && n.y >= z_y && n.y <= (z_y + z_h) && n.x + width_n >= z_x && n.x + width_n <= (z_x + z_w) && n.y + height_n >= z_y && n.y + height_n <= (z_y + z_h);
            }).forEach(n => multi_selected_label.current.push(n));
            multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', true));
            multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', true));
            ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
        }
        else {
            multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', false));
            multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', false));
            multi_selected_label.current = Object.values(data.labels).filter(n => {
                var _a, _b, _c, _d;
                const width_n = ((_b = (_a = document.getElementById(n.idLabel)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width) !== null && _b !== void 0 ? _b : 0) / scale_svg;
                const height_n = ((_d = (_c = document.getElementById(n.idLabel)) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().height) !== null && _d !== void 0 ? _d : 0) / scale_svg;
                return n.x >= z_x && n.x <= (z_x + z_w) && n.y >= z_y && n.y <= (z_y + z_h) && n.x + width_n >= z_x && n.x + width_n <= (z_x + z_w) && n.y + height_n >= z_y && n.y + height_n <= (z_y + z_h);
            });
            multi_selected_label.current.forEach(l => d3.select('#' + l.idLabel).classed('selected', true));
            multi_selected_label.current.forEach(l => d3.select('#gg_zdt_handles_' + l.idLabel).classed('selected', true));
            ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
        }
    }
};
const draw_text_zone_handles = (applicationData, zdt, applicationState, applicationDraw, ComponentUpdater, link_function) => {
    const { multi_selected_label } = applicationState;
    d3.select('.opensankey #g_label_handles').append('g').attr('id', 'gg_zdt_handles_' + zdt.idLabel).attr('class', 'gg_zdt_handles').classed('selected', multi_selected_label.current.includes(zdt));
    ['top', 'bottom', 'left', 'right'].forEach(pos => {
        add_zdt_handle(zdt, pos, applicationData, applicationDraw, ComponentUpdater, link_function, applicationState);
    });
};
const size_zdt_handle = 10;
const add_zdt_handle = (zdt, pos, applicationData, applicationDraw, ComponentUpdater, link_function, applicationState) => {
    var _a, _b;
    // Compute the zoom of the svg so we increase the size of the handles if the svg is de-zoomed
    let svg_k_factor = 1;
    if (d3.select('.opensankey #svg').nodes().length > 0) {
        const transform_svg = (_b = (_a = d3.select('.opensankey #svg')) === null || _a === void 0 ? void 0 : _a.attr('transform')) !== null && _b !== void 0 ? _b : '';
        const scale_svg = (transform_svg) ? +transform_svg.split('scale(')[1].replace(')', '') : 1;
        svg_k_factor = (scale_svg < 1 ? (1 / scale_svg) : 1);
    }
    const gg_zdt = d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel);
    // Draw the circle with parameter commont to all the handles
    const gg_zdt_h_circle = gg_zdt
        .append('rect')
        .attr('class', 'zdt_handles zdt_handle_' + pos)
        .attr('width', size_zdt_handle * svg_k_factor)
        .attr('height', size_zdt_handle * svg_k_factor)
        .attr('fill', 'black')
        .style('cursor', (pos === 'top' || pos === 'bottom') ? 'ns-resize' : 'ew-resize')
        .call(drag_text_zone_hande(zdt, pos, applicationData, applicationDraw, ComponentUpdater, link_function, applicationState));
    // Position the handle
    switch (pos) {
        case 'top':
            gg_zdt_h_circle
                .attr('x', zdt.x + zdt.label_width / 2 - (size_zdt_handle / 2))
                .attr('y', zdt.y + 0 - (size_zdt_handle / 2));
            break;
        case 'bottom':
            gg_zdt_h_circle
                .attr('x', zdt.x + zdt.label_width / 2 - (size_zdt_handle / 2))
                .attr('y', zdt.y + zdt.label_height - (size_zdt_handle / 2));
            break;
        case 'left':
            gg_zdt_h_circle
                .attr('x', zdt.x + 0 - (size_zdt_handle / 2))
                .attr('y', zdt.y + zdt.label_height / 2 - (size_zdt_handle / 2));
            break;
        case 'right':
            gg_zdt_h_circle
                .attr('x', zdt.x + zdt.label_width - (size_zdt_handle / 2))
                .attr('y', zdt.y + zdt.label_height / 2 - (size_zdt_handle / 2));
            break;
    }
};
const drag_text_zone_hande = (zdt, pos, applicationData, applicationDraw, ComponentUpdater, link_function, applicationState) => {
    const { data } = applicationData;
    const g_zdt_h = d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_' + pos);
    const text_zone_shape = d3.select('#' + zdt.idLabel + ' rect');
    const g_text_zone = d3.select('#' + zdt.idLabel);
    return d3.drag()
        .subject(Object)
        .on('start', () => {
        hideLinkOnDragElement(applicationData);
    })
        .on('drag', function (event) {
        // The handles change the width and height of the text_zone
        // The top and left handles also shift the x/y of text zone
        switch (pos) {
            case 'top':
                zdt.label_height -= event.dy;
                zdt.y += event.dy;
                g_text_zone.attr('transform', 'translate(' + zdt.x + ',' + zdt.y + ')');
                g_zdt_h.attr('y', zdt.y - (size_zdt_handle / 2));
                text_zone_shape.attr('height', zdt.label_height);
                // Reposition lateral handles
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_left').attr('y', zdt.y + zdt.label_height / 2 - (size_zdt_handle / 2));
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_right').attr('y', zdt.y + zdt.label_height / 2 - (size_zdt_handle / 2));
                if (zdt.y < 0) {
                    OpposingDragElementsPlus([zdt], event, zdt, applicationData, applicationState);
                    d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_bottom').attr('y', zdt.y + zdt.label_height);
                }
                break;
            case 'bottom':
                zdt.label_height += event.dy;
                g_zdt_h.attr('y', zdt.y + zdt.label_height - (size_zdt_handle / 2));
                text_zone_shape.attr('height', zdt.label_height);
                // Reposition lateral handles
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_left').attr('y', zdt.y + zdt.label_height / 2 - (size_zdt_handle / 2));
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_right').attr('y', zdt.y + zdt.label_height / 2 - (size_zdt_handle / 2));
                if ((zdt.y + zdt.label_height + data.grid_square_size * 2) > data.height) {
                    data.height = (zdt.y + zdt.label_height + data.grid_square_size * 2);
                    const svgSankey = d3.select('.opensankey #svg');
                    svgSankey.style('height', (zdt.y + zdt.label_height + data.grid_square_size * 2) + 'px');
                }
                break;
            case 'left':
                zdt.label_width -= event.dx;
                zdt.x += event.dx;
                g_text_zone.attr('transform', 'translate(' + zdt.x + ',' + zdt.y + ')');
                g_zdt_h.attr('x', zdt.x - (size_zdt_handle / 2));
                text_zone_shape.attr('width', zdt.label_width);
                // Reposition vertical handles
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_top').attr('x', zdt.x + zdt.label_width / 2 - (size_zdt_handle / 2));
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_bottom').attr('x', zdt.x + zdt.label_width / 2 - (size_zdt_handle / 2));
                if (zdt.x < 0) {
                    OpposingDragElementsPlus([zdt], event, zdt, applicationData, applicationState);
                    d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_right').attr('x', zdt.x + zdt.label_width);
                }
                break;
            case 'right':
                zdt.label_width += event.dx;
                g_zdt_h.attr('x', zdt.x + zdt.label_width - (size_zdt_handle / 2));
                text_zone_shape.attr('width', zdt.label_width);
                // Reposition vertical handles
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_top').attr('x', zdt.x + zdt.label_width / 2 - (size_zdt_handle / 2));
                d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel + ' .zdt_handle_bottom').attr('x', zdt.x + zdt.label_width / 2 - (size_zdt_handle / 2));
                if ((zdt.x + zdt.label_width + data.grid_square_size * 2) > data.width) {
                    data.width = (zdt.x + zdt.label_width + data.grid_square_size * 2);
                    const svgSankey = d3.select('.opensankey #svg');
                    svgSankey.style('width', (zdt.x + zdt.label_width + data.grid_square_size * 2) + 'px');
                }
                break;
        }
    })
        .on('end', () => {
        ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
        ComponentUpdater.updateComponenSaveInCache.current(false);
        link_function.RedrawLinks(Object.values(applicationData.display_links));
        applicationDraw.reDrawOSPLabels([zdt]);
    });
};
export const sankey_plus_zoom_text_zone = (evt) => {
    const k_factor = evt.transform.k;
    if (k_factor < 1) {
        d3.selectAll('.opensankey .zdt_handles').attr('r', 10 * (1 / k_factor));
    }
};
const select_visualy_zdt = (zdt) => {
    d3.select('#' + zdt.idLabel + ' rect').attr('stroke-width', 3);
    d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel).style('display', 'inline');
};
const deselect_visualy_zdt = (zdt) => {
    d3.select('#' + zdt.idLabel + ' rect').attr('stroke-width', 1);
    d3.select('.opensankey #gg_zdt_handles_' + zdt.idLabel).style('display', 'none');
};
export const deleteGLabel = (updateMenus, zdt_to_delete) => {
    zdt_to_delete.forEach(zdt => {
        d3.select('#' + zdt.idLabel).remove();
        d3.selectAll('#gg_zdt_handles_' + zdt.idLabel).remove();
    });
    updateMenus[1](!updateMenus[0]);
};
