// Standard libs
import React from 'react';
// Imported libs
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from '@chakra-ui/react';
// Local libs
import SankeyNodeEdition from './SankeyMenuConfigurationNodes';
import SankeyMenuConfigurationLinks from './SankeyMenuConfigurationLinks';
/**
 *  Define configuration menu
 *
 * @param { TODO type } applicationData - TODO description
 * @param { TODO type } applicationState - TODO description
 * @param { TODO type } applicationContext - TODO description
 * @param { TODO type } uiElementsRef - TODO description
 * @param { TODO type } dict_hook_ref_setter_show_dialog_components - TODO description
 * @param { TODO type } menu_configuration_layout - TODO description
 * @param { TODO type } menu_configuration_node_tags - TODO description
 * @param { TODO type } menu_configuration_link_tags - TODO description
 * @param { TODO type } menu_configuration_data_tags - TODO description
 * @param { TODO type } menu_configuration_nodes - TODO description
 * @param { TODO type } menu_configuration_links - TODO description
 * @param { TODO type } additional_accordion_edition_elements - TODO description
 * @param { TODO type } token - TODO description
 *
 */
export const OpenSankeyConfigurationsMenus = (applicationData, applicationState, applicationContext, uiElementsRef, dict_hook_ref_setter_show_dialog_components, menu_configuration_layout, menu_configuration_node_tags, menu_configuration_link_tags, menu_configuration_data_tags, menu_configuration_nodes, menu_configuration_links, additional_accordion_edition_elements, link_function, ComponentUpdater, contextMenu, alt_key_pressed, node_function) => {
    const { data } = applicationData;
    const { t } = applicationContext;
    const { links_accordion_ref, nodes_accordion_ref, accordion_ref } = uiElementsRef;
    const { multi_selected_nodes } = applicationState;
    // const {ref_setter_show_menu_config}=dict_hook_ref_setter_show_dialog_components
    const show_menu_config_tag = ((data.accordeonToShow.includes('EN') ||
        data.accordeonToShow.includes('EF') ||
        data.accordeonToShow.includes('ED')));
    return [
        data.accordeonToShow.includes('MEP') ?
            React.createElement(AccordionItem, null,
                React.createElement(AccordionButton, { onClick: () => {
                        const scroll_x = window.scrollX;
                        const scroll_y = window.scrollY;
                        setTimeout(() => {
                            var _a;
                            (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                        }, 50);
                    } },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.MEP')),
                    React.createElement(AccordionIcon, null)),
                React.createElement(AccordionPanel, null,
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, menu_configuration_layout))) :
            React.createElement(React.Fragment, null),
        React.createElement(AccordionItem, null,
            React.createElement(AccordionButton, { ref: accordion_ref, onClick: () => {
                    const scroll_x = window.scrollX;
                    const scroll_y = window.scrollY;
                    setTimeout(() => {
                        var _a;
                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                    }, 50);
                } },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.Elements')),
                React.createElement(AccordionIcon, null)),
            React.createElement(AccordionPanel, null,
                React.createElement(Accordion, { allowToggle: true, variant: 'accordion_sublevel_style' },
                    React.createElement(AccordionItem, null,
                        React.createElement(AccordionButton, { ref: nodes_accordion_ref, onClick: () => {
                                const scroll_x = window.scrollX;
                                const scroll_y = window.scrollY;
                                setTimeout(() => {
                                    var _a;
                                    (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                }, 50);
                            } },
                            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EdN')),
                            React.createElement(AccordionIcon, null)),
                        React.createElement(AccordionPanel, null,
                            React.createElement(SankeyNodeEdition, { applicationContext: applicationContext, applicationData: applicationData, applicationState: applicationState, multi_selected_nodes: multi_selected_nodes, menu_configuration_nodes: menu_configuration_nodes, link_function: link_function, ComponentUpdater: ComponentUpdater, node_function: node_function }))),
                    React.createElement(AccordionItem, null,
                        React.createElement(AccordionButton, { ref: links_accordion_ref, onClick: () => {
                                const scroll_x = window.scrollX;
                                const scroll_y = window.scrollY;
                                setTimeout(() => {
                                    var _a;
                                    (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                }, 50);
                            } },
                            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EdF')),
                            React.createElement(AccordionIcon, null)),
                        React.createElement(AccordionPanel, null,
                            React.createElement(SankeyMenuConfigurationLinks, { applicationData: applicationData, applicationContext: applicationContext, applicationState: applicationState, menu_configuration_links: menu_configuration_links, link_function: link_function, ComponentUpdater: ComponentUpdater, contextMenu: contextMenu, uiElementsRef: uiElementsRef, alt_key_pressed: alt_key_pressed, dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_components, node_function: node_function }))),
                    additional_accordion_edition_elements))),
        show_menu_config_tag ?
            React.createElement(AccordionItem, null,
                React.createElement(AccordionButton, { onClick: () => {
                        const scroll_x = window.scrollX;
                        const scroll_y = window.scrollY;
                        setTimeout(() => {
                            var _a;
                            (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                        }, 50);
                    } },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('Menu.Etiquettes')),
                    React.createElement(AccordionIcon, null)),
                React.createElement(AccordionPanel, null,
                    React.createElement(Accordion, { allowToggle: true, variant: "accordion_sublevel_style" },
                        React.createElement(AccordionItem, { style: { 'display': (data.accordeonToShow.includes('EN')) ? 'initial' : 'none' } },
                            React.createElement(AccordionButton, { onClick: () => {
                                    const scroll_x = window.scrollX;
                                    const scroll_y = window.scrollY;
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                    }, 50);
                                } },
                                React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EN')),
                                React.createElement(AccordionIcon, null)),
                            React.createElement(AccordionPanel, null, menu_configuration_node_tags)),
                        React.createElement(AccordionItem, { style: { 'display': (data.accordeonToShow.includes('EF')) ? 'initial' : 'none' } },
                            React.createElement(AccordionButton, { onClick: () => {
                                    const scroll_x = window.scrollX;
                                    const scroll_y = window.scrollY;
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                    }, 50);
                                } },
                                React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.EF')),
                                React.createElement(AccordionIcon, null)),
                            React.createElement(AccordionPanel, null, menu_configuration_link_tags)),
                        React.createElement(AccordionItem, { style: { 'display': (data.accordeonToShow.includes('ED')) ? 'initial' : 'none' } },
                            React.createElement(AccordionButton, { onClick: () => {
                                    const scroll_x = window.scrollX;
                                    const scroll_y = window.scrollY;
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                                    }, 50);
                                } },
                                React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.ED')),
                                React.createElement(AccordionIcon, null)),
                            React.createElement(AccordionPanel, null, menu_configuration_data_tags))))) :
            React.createElement(React.Fragment, null)
    ];
};
export const SankeyConfigurationMenu = ({ configuration_menus }) => {
    return (React.createElement(Accordion, { allowToggle: true }, configuration_menus.map((c, i) => {
        return React.createElement(React.Fragment, { key: i }, c);
    })));
};
