import {defineStyle } from '@chakra-ui/react'


export const heading_base_style=defineStyle({
})
export const heading_welcome_style = defineStyle({
  textAlign:'center',
  fontSize:'2rem',
  color:'openSankey.400'
})

export const heading_template_dashboard = defineStyle({
  textAlign:'center',
  fontSize:'2rem',
  textStyle:'h2',
})