import { AssignLinkLocalAttribute } from '../import/OpenSankey';
import { afm_default_node } from './AFMSankeyMenu';
// Fonctions d'individualisation des imports/exports
export const SplitIOrE = (node, importation, data) => {
    //const originalIdNode = node.idNode
    let tmp = [];
    let tmp1;
    let tmp2;
    let tmp3;
    let tmp4;
    let tmp5;
    if (importation) {
        tmp = node.outputLinksId.slice(); // clone
        tmp1 = 'idTarget';
        tmp2 = 'idSource';
        tmp3 = 'outputLinksId';
        tmp4 = 'NodeImportStyle';
        tmp5 = 'LinkImportStyle';
    }
    else {
        tmp = node.inputLinksId.slice(); // clone
        tmp1 = 'idSource';
        tmp2 = 'idTarget';
        tmp3 = 'inputLinksId';
        tmp4 = 'NodeExportStyle';
        tmp5 = 'LinkExportStyle';
    }
    tmp.forEach((idLink) => {
        const input_or_output_link = data.links[idLink];
        const le_nom = data.nodes[input_or_output_link[tmp1]].name + ' ' + node.name;
        let idTrade = data.nodes[input_or_output_link[tmp1]].idNode + '-' + node.idNode + (importation ? 'Importations' : 'Exportations');
        idTrade = idTrade.replaceAll(' ', '');
        const new_node = Object.assign({}, afm_default_node(data), {
            name: le_nom,
            idNode: idTrade,
            tags: JSON.parse(JSON.stringify(node.tags)),
            dimensions: JSON.parse(JSON.stringify(node.dimensions))
        });
        data.additional_nodes[idTrade] = new_node;
        const link = data.links[idLink];
        if (!link) {
            return;
        }
        new_node.style = tmp4;
        link.style = tmp5;
        const extremity_node = data.nodes[link[tmp1]];
        Object.entries(data.additional_nodes[idTrade]['dimensions']).forEach(dim => {
            if (node.dimensions[dim[0]].parent_name === undefined) {
                return;
            }
            dim[1].parent_name = extremity_node.idNode + '-' + node.dimensions[dim[0]].parent_name + (importation ? 'Importations' : 'Exportations');
        });
        Object.keys(extremity_node.dimensions).forEach(dim_key => {
            new_node.dimensions[dim_key] = JSON.parse(JSON.stringify(extremity_node.dimensions[dim_key]));
            if (extremity_node.dimensions[dim_key].parent_name === undefined) {
                return;
            }
            new_node.dimensions[dim_key].parent_name = extremity_node.dimensions[dim_key].parent_name + '-' + node.idNode + (importation ? 'Importations' : 'Exportations');
        });
        Object.keys(extremity_node.tags).forEach(tag_key => {
            if (tag_key === 'Type de noeud') {
                return;
            }
            const tags = [...extremity_node.tags[tag_key]];
            new_node.tags[tag_key] = JSON.parse(JSON.stringify(tags));
        });
        // Création des flux
        const new_link = JSON.parse(JSON.stringify(link));
        AssignLinkLocalAttribute(new_link, 'recycling', false);
        data.additional_links[new_node.idNode] = new_link;
        new_link.idLink = new_node.idNode;
        new_link[tmp2] = new_node.idNode;
        new_node[tmp3].push(new_node.idNode);
    });
};
export const SplitTrade = (data) => {
    let { initial_nodes } = data;
    if (Object.values(initial_nodes).length == 0) {
        data.initial_nodes = JSON.parse(JSON.stringify(data.nodes));
        initial_nodes = data.initial_nodes;
        data.initial_links = JSON.parse(JSON.stringify(data.links));
    }
    // e.g. "International"
    let trade_nodes = Object.values(initial_nodes).filter(n => n.tags && n.tags['Type de noeud'] && n.tags['Type de noeud'][0].includes('echange'));
    trade_nodes.forEach(node => {
        if (node.outputLinksId.length > 0) { // Imports
            SplitIOrE(node, true, data);
        }
        if (node.inputLinksId.length > 0) { // Exports
            SplitIOrE(node, false, data);
        }
        data.removed_nodes[node.idNode] = node;
        node.inputLinksId.forEach(lId => data.removed_links[lId] = data.links[lId]);
        node.outputLinksId.forEach(lId => data.removed_links[lId] = data.links[lId]);
    });
    data.nodes = Object.assign({}, data.initial_nodes, data.additional_nodes);
    Object.keys(data.removed_nodes).forEach(idNode => delete data.nodes[idNode]);
    data.links = Object.assign({}, data.initial_links, data.additional_links);
    Object.keys(data.removed_links).forEach(idLink => delete data.links[idLink]);
};
