export const dev_convert_data = (data) => {
    if (data.unitary_node === undefined) {
        data.unitary_node = [];
    }
    if (data.process_transfo_ref === undefined) {
        data.process_transfo_ref = {};
    }
    if (data.unit_link_value_display === undefined) {
        data.unit_link_value_display = 'percent';
    }
};
