import React, { useState } from 'react';
import * as d3 from 'd3';
import { Box, Checkbox, Button, Tag } from '@chakra-ui/react';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from 'react-icons/fa';
import { NodeColor, ReturnValueNode, IsAllNodeAttrSameValue, AssignNodeValueToCorrectVar, } from './FunctionOSTyped';
/**
 * Function that return component to add in the configuration of nodes Apparence attributes
 */
export const SankeyDevNodesAttributes = ({ t, data, applicationState, is_activated, node_function, menu_for_style }) => {
    const { multi_selected_nodes, ref_selected_style_node } = applicationState;
    const [forceUpdate, setForceUpdate] = useState(false);
    // I have to do this because when we change ref_selected_style_node it only re-render SankeyModalStyleNode
    // who re-render MenuConfigurationLinksAppearence 
    // but SankeyDevNodesAttributes is rendered outside the scope of SankeyModalStyleNode
    // so ref_selected_style_node can be out of sync with the real ref_selected_style_node
    if (menu_for_style && !Object.keys(data.style_node).includes(ref_selected_style_node.current)) {
        ref_selected_style_node.current = (Object.keys(data.style_node)[0]);
    }
    const parameter_to_modify = (menu_for_style) ? data.style_node : data.nodes;
    const selected_parameter = (menu_for_style) ? [data.style_node[ref_selected_style_node.current]] : multi_selected_nodes.current;
    const list_of_key = ['shape_visible', 'not_to_scale', 'not_to_scale_direction'];
    const list_value = IsAllNodeAttrSameValue(data, selected_parameter, list_of_key, menu_for_style);
    const form_elements = [
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', iconColor: list_value['not_to_scale'][1] ? '#78C2AD' : 'white', isIndeterminate: list_value['not_to_scale'][1], isDisabled: !is_activated, isChecked: list_value['not_to_scale'][0], onChange: (evt) => {
                Object.values(parameter_to_modify)
                    .filter(f => selected_parameter.map(d => d.idNode).includes(f.idNode))
                    .forEach(d => {
                    AssignNodeValueToCorrectVar(d, 'not_to_scale', evt.target.checked, menu_for_style);
                    if (list_value['not_to_scale_direction'][0] === undefined) {
                        AssignNodeValueToCorrectVar(d, 'not_to_scale_direction', 'right', menu_for_style);
                    }
                });
                node_function.reDrawNodeNotToScale(multi_selected_nodes.current);
                setForceUpdate(!forceUpdate);
            } },
            t('Noeud.apparence.toScale'),
            React.createElement(Tag, { colorScheme: 'teal', style: { marginLeft: 'auto' } }, "Dev")),
        React.createElement(React.Fragment, null, list_value['not_to_scale'][0] ? React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                t('Noeud.apparence.Orientation'),
                React.createElement(Tag, { colorScheme: 'teal', style: { marginLeft: 'auto' } }, "Dev")),
            React.createElement(Box, { layerStyle: 'options_4cols' },
                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', disabled: (!is_activated) ? true : !list_value['not_to_scale'][0], variant: list_value['not_to_scale_direction'][0] === ('left') ? 'menuconfigpanel_option_button_activated_left' : 'menuconfigpanel_option_button_left', onClick: () => {
                        Object.values(parameter_to_modify)
                            .filter(f => selected_parameter.map(d => d.idNode).includes(f.idNode))
                            .forEach(d => AssignNodeValueToCorrectVar(d, 'not_to_scale_direction', 'left', menu_for_style));
                        node_function.reDrawNodeNotToScale(multi_selected_nodes.current);
                        setForceUpdate(!forceUpdate);
                    } },
                    React.createElement(FaArrowLeft, null)),
                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', disabled: (!is_activated) ? true : !list_value['not_to_scale'][0], variant: list_value['not_to_scale_direction'][0] === ('right') ? 'menuconfigpanel_option_button_activated_center' : 'menuconfigpanel_option_button_center', onClick: () => {
                        Object.values(parameter_to_modify)
                            .filter(f => selected_parameter.map(d => d.idNode).includes(f.idNode))
                            .forEach(d => AssignNodeValueToCorrectVar(d, 'not_to_scale_direction', 'right', menu_for_style));
                        node_function.reDrawNodeNotToScale(multi_selected_nodes.current);
                        setForceUpdate(!forceUpdate);
                    } },
                    React.createElement(FaArrowRight, null)),
                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', disabled: (!is_activated) ? true : !list_value['not_to_scale'][0], variant: list_value['not_to_scale_direction'][0] === ('top') ? 'menuconfigpanel_option_button_activated_center' : 'menuconfigpanel_option_button_center', onClick: () => {
                        Object.values(parameter_to_modify)
                            .filter(f => selected_parameter.map(d => d.idNode).includes(f.idNode))
                            .forEach(d => AssignNodeValueToCorrectVar(d, 'not_to_scale_direction', 'top', menu_for_style));
                        node_function.reDrawNodeNotToScale(multi_selected_nodes.current);
                        setForceUpdate(!forceUpdate);
                    } },
                    React.createElement(FaArrowUp, null)),
                React.createElement(Button, { paddingStart: '0', paddingEnd: '0', minWidth: '0', disabled: (!is_activated) ? true : !list_value['not_to_scale'][0], variant: list_value['not_to_scale_direction'][0] === ('bottom') ? 'menuconfigpanel_option_button_activated_right' : 'menuconfigpanel_option_button_right', onClick: () => {
                        Object.values(parameter_to_modify)
                            .filter(f => selected_parameter.map(d => d.idNode).includes(f.idNode))
                            .forEach(d => AssignNodeValueToCorrectVar(d, 'not_to_scale_direction', 'bottom', menu_for_style));
                        node_function.reDrawNodeNotToScale(multi_selected_nodes.current);
                        setForceUpdate(!forceUpdate);
                    } },
                    React.createElement(FaArrowDown, null))))
            : React.createElement(React.Fragment, null))
    ];
    if (is_activated) {
        return form_elements;
    }
    else {
        return form_elements.map((e, i) => {
            return React.createElement(React.Fragment, { key: i }, e);
        });
    }
};
/**
 * Function to draw nodes with a particular shape (multiple rect with increasing size)
 *
 *  */
export const SankeyDevDrawNodesNotToScale = (applicationData, nodes_to_draw) => {
    const { data } = applicationData;
    const ggg_nodes = d3.selectAll('.ggg_nodes')
        .filter(d => nodes_to_draw.length > 0 ? nodes_to_draw.includes(d) : true);
    ggg_nodes.selectAll('.node_not_to_scale').remove();
    nodes_to_draw.filter(n => !ReturnValueNode(data, n, 'not_to_scale') && ReturnValueNode(data, n, 'shape_visible')).forEach(n => {
        d3.select(' .opensankey #shape_' + n.idNode)
            .attr('fill-opacity', 1);
    });
    nodes_to_draw.filter(n => ReturnValueNode(data, n, 'not_to_scale')).forEach(n => {
        d3.select(' .opensankey #shape_' + n.idNode)
            .attr('fill-opacity', 0);
    });
    const nodes_not_to_scale = ggg_nodes.filter(n => ReturnValueNode(data, n, 'not_to_scale')).append('g');
    // 1
    nodes_not_to_scale.append('rect')
        .classed('node_not_to_scale', true)
        .classed('node_sub_shape', true)
        .attr('x', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return (ReturnValueNode(data, n, 'not_to_scale_direction') === 'left') ? width_node - (width_node / 50) : 0;
    })
        .attr('y', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return (ReturnValueNode(data, n, 'not_to_scale_direction') === 'top') ? (height_node - height_node / 50) : 0;
    })
        .attr('width', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? width_node : width_node / 50;
    })
        .attr('height', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? height_node / 50 : height_node;
    })
        .attr('fill', d => NodeColor(d, data));
    // 2
    nodes_not_to_scale.append('rect')
        .classed('node_not_to_scale', true)
        .classed('node_sub_shape', true)
        .attr('x', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'right') {
            return width_node / 25;
        }
        else if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'left') {
            return width_node - width_node / 10;
        }
        else {
            return 0;
        }
    })
        .attr('y', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'bottom') {
            return height_node / 25;
        }
        else if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'top') {
            return height_node - height_node / 10;
        }
        else {
            return 0;
        }
    })
        .attr('height', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? height_node / 20 : height_node;
    })
        .attr('width', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? width_node : width_node / 20;
    })
        .attr('fill', d => NodeColor(d, data));
    // 3
    nodes_not_to_scale.append('rect')
        .classed('node_not_to_scale', true)
        .classed('node_sub_shape', true)
        .attr('x', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'right') {
            return width_node / 8.5;
        }
        else if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'left') {
            return width_node - width_node / 4.3;
        }
        else {
            return 0;
        }
    })
        .attr('y', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'bottom') {
            return height_node / 8.5;
        }
        else if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'top') {
            return height_node - height_node / 4.3;
        }
        else {
            return 0;
        }
    })
        .attr('height', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? height_node / 9 : height_node;
    })
        .attr('width', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? width_node : width_node / 9;
    })
        .attr('fill', d => NodeColor(d, data));
    // 4
    nodes_not_to_scale.append('rect')
        .classed('node_not_to_scale', true)
        .classed('node_sub_shape', true)
        .attr('x', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'right') {
            return width_node / 4;
        }
        else if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'left') {
            return width_node - width_node / 2.1;
        }
        else {
            return 0;
        }
    })
        .attr('y', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'bottom') {
            return height_node / 4;
        }
        else if (ReturnValueNode(data, n, 'not_to_scale_direction') === 'top') {
            return height_node - height_node / 2.1;
        }
        else {
            return 0;
        }
    })
        .attr('width', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? width_node : width_node / 4.5;
    })
        .attr('height', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? height_node / 4.5 : height_node;
    })
        .attr('fill', d => NodeColor(d, data));
    // 5
    nodes_not_to_scale.append('rect')
        .classed('node_not_to_scale', true)
        .classed('node_sub_shape', true)
        .attr('x', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        return (ReturnValueNode(data, n, 'not_to_scale_direction') === 'right') ? (width_node / 2) : 0;
    })
        .attr('y', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return (ReturnValueNode(data, n, 'not_to_scale_direction') === 'bottom') ? (height_node / 2) : 0;
    })
        .attr('width', n => {
        const width_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('width');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? width_node : width_node / 2;
    })
        .attr('height', n => {
        const height_node = +d3.select(' .opensankey #shape_' + n.idNode).attr('height');
        return ['top', 'bottom'].includes(ReturnValueNode(data, n, 'not_to_scale_direction')) ? height_node / 2 : height_node;
    })
        .attr('fill', d => NodeColor(d, data));
};
