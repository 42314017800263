// External libs
import React from 'react';
import { Box, Checkbox, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tag } from '@chakra-ui/react';
import { ValueSelectedParameter, NodeDisplayed } from './FunctionOSTyped';
/**
 * Return link value, determined by selected dataTag (if there is)
 * Sometime the link can be duplicate when we choose to select multiple dataTag
 * therefore to access the right value of the link we search in the id the right value
 */
export const devGetLinkValue = (data, idLink, up = false) => {
    const { links, dataTags } = data;
    // Split the id and search for value after the original link id
    //  each value represent wich dataTag to choose among those where selected is at true in link.value
    // If there no dataTag (or no multiple dataTag selected then it take the first selected)
    const idDt = idLink.split('_');
    idDt.splice(0, 1);
    const defaultInd = Object.values(data.dataTags)
        .map(d => {
        return Object.values(d.tags).filter(t => t.selected).map((dd, i) => i)[0];
    });
    const index_dataTag = (idDt.length === 0) ? defaultInd : idDt.map(d => Number(d));
    if (!(idLink in links)) {
        return {
            value: 0,
            display_value: '',
            is_percent: false,
            percent: 0,
            tags: {},
            extension: {}
        };
    }
    let val = links[idLink].value;
    const listKey = [];
    let missing_key = false;
    Object.values(dataTags).filter(dataTag => { return (Object.keys(dataTag.tags).length !== 0) ? true : false; }).forEach((dataTag, i) => {
        const selected_tags = Object.entries(dataTag.tags).filter(([, tag]) => { return tag.selected; });
        if (selected_tags.length === 0 || missing_key) {
            missing_key = true;
            return;
        }
        listKey.push(Object.entries(dataTag.tags).filter(([, tag]) => { return tag.selected; })[index_dataTag[i]][0]);
    });
    if (missing_key) {
        return {
            value: 0,
            display_value: '',
            tags: {},
            is_percent: false,
            percent: 0,
            extension: {}
        };
    }
    for (const i in listKey) {
        if (up && +i === (listKey.length - 1)) {
            break;
        }
        val = val[listKey[i]];
        if (val === undefined) {
            return {
                value: 0,
                display_value: '',
                tags: {},
                is_percent: false,
                percent: 0,
                extension: {}
            };
        }
    }
    return val;
};
export const SankeyDevMenuDataPercent = ({ data, set_data, multi_selected_links }) => {
    const newEntries = new Map(Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
        return (Object.keys(dataTag.tags).length > 0) ? [
            dataTagKey,
            Object.entries(dataTag.tags).filter(tag => tag[1].selected).length > 0 ? Object.entries(dataTag.tags).filter(tag => tag[1].selected)[0][0] : Object.keys(dataTag.tags)[0]
        ] : ['n', 'n'];
    }));
    const tags_selected = Object.fromEntries(newEntries);
    const half_dict_app_data = { data: data, set_data: set_data };
    const value_of_links_selected = ValueSelectedParameter(half_dict_app_data, multi_selected_links, tags_selected);
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', iconColor: value_of_links_selected.is_percent ? '#78C2AD' : 'white', isChecked: value_of_links_selected.is_percent, onChange: () => {
                let val = Object(multi_selected_links.current[0].value);
                multi_selected_links.current.forEach(d => {
                    val = d.value;
                    Object.values(tags_selected).forEach(tag => {
                        if (val[tag] === undefined) {
                            val[tag] = {};
                        }
                        val = val[tag];
                    });
                    val.is_percent = !val.is_percent;
                });
                set_data(Object.assign({}, data));
            } },
            "Valeur proport. \u00E0 celle du noeud source",
            React.createElement(Tag, { colorScheme: 'teal', style: { marginLeft: 'auto' } }, "Dev")),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' },
                "Pourcent",
                React.createElement(Tag, { colorScheme: 'teal', style: { marginLeft: 'auto' } }, "Dev")),
            React.createElement(Box, null,
                React.createElement(Slider, { style: { width: '50%' }, isDisabled: !value_of_links_selected.is_percent, value: value_of_links_selected.percent, onChange: evt => {
                        let val = Object(multi_selected_links.current[0].value);
                        multi_selected_links.current.forEach(d => {
                            const src_value = getTotalInputLink(data, data.nodes[d.idSource], devGetLinkValue);
                            val = d.value;
                            Object.values(tags_selected).forEach(tag => {
                                if (val[tag] === undefined) {
                                    val[tag] = {};
                                }
                                val = val[tag];
                            });
                            val.percent = +evt;
                            val.value = src_value * (val.percent / 100);
                        });
                        set_data(Object.assign({}, data));
                    } },
                    React.createElement(SliderMark, { value: value_of_links_selected.percent, textAlign: 'center', bg: 'openSankey.200', color: 'white', mt: '-10', ml: '-5', w: '12' }, value_of_links_selected.percent),
                    React.createElement(SliderTrack, null,
                        React.createElement(SliderFilledTrack, null)),
                    React.createElement(SliderThumb, null))))));
};
/**
 * Return the sum of links value visible incoming to the node
 *
 * @param {SankeyData} data
 * @param {SankeyNode} node
 * @returns {number}
 */
const getTotalInputLink = (data, node, GetLinkValue) => {
    let total = 0;
    node.inputLinksId.forEach(element => {
        // On vérifie que le lien est affiché, cad que le noeud source et le noeud target sont
        if (NodeDisplayed(data, data.nodes[data.links[element].idSource]) && NodeDisplayed(data, data.nodes[data.links[element].idTarget])) {
            const tmp = GetLinkValue(data, element).value;
            total += (tmp) ? tmp : 0;
        }
    });
    return total;
};
