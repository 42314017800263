// External libs
import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Box, Checkbox, Tag } from '@chakra-ui/react';
import { ValueSelectedParameter } from '../import/OpenSankey';
export const FreeVariables = ({ t, applicationData, applicationState, node_function, link_function }) => {
    const { data } = applicationData;
    const [forceUpdate, setForceUpdate] = useState(true);
    const { multi_selected_links } = applicationState;
    const newEntries = new Map(Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
        return (Object.keys(dataTag.tags).length > 0) ? [
            dataTagKey,
            Object.entries(dataTag.tags).filter(tag => tag[1].selected).length > 0 ? Object.entries(dataTag.tags).filter(tag => tag[1].selected)[0][0] : Object.keys(dataTag.tags)[0]
        ] : ['n', 'n'];
    }));
    const tags_selected = Object.fromEntries(newEntries);
    const value_selected = ValueSelectedParameter(applicationData, multi_selected_links, tags_selected);
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: (value_selected && value_selected.extension && value_selected.extension.display_thin), onChange: (evt) => {
                let val = Object(multi_selected_links.current[0].value);
                const node_to_update = [];
                multi_selected_links.current.map(d => {
                    node_to_update.push(data.nodes[d.idSource]);
                    node_to_update.push(data.nodes[d.idTarget]);
                    val = d.value;
                    Object.values(tags_selected).forEach(tag => {
                        if (val[tag] === undefined) {
                            val[tag] = {};
                        }
                        val = val[tag];
                    });
                    val.extension.display_thin = evt.target.checked;
                });
                node_function.RedrawNodes(node_to_update);
                link_function.RedrawLinks(multi_selected_links.current);
                setForceUpdate(!forceUpdate);
            } }, t('Flux.data.astr'))));
};
export const checkboxFreeNullLink = ({ applicationData, t, node_function, link_function }) => {
    const { data, display_nodes, display_links } = applicationData;
    const [forceUpdate, setForceUpdate] = useState(false);
    return React.createElement(Box, null,
        React.createElement(Checkbox, { icon: data.free_null_link_visible ? React.createElement(FaEye, { style: { fill: 'white' } }) : React.createElement(FaEyeSlash, null), isChecked: data.free_null_link_visible, onChange: (evt) => {
                data.free_null_link_visible = evt.target.checked;
                node_function.recomputeDisplayedElement();
                node_function.RedrawNodes(Object.values(display_nodes));
                link_function.RedrawLinks(Object.values(display_links));
                setForceUpdate(!forceUpdate);
            } },
            t('Flux.data.fla'),
            React.createElement(Tag, { colorScheme: "teal", style: { marginLeft: 'auto' } }, t('Menu.featureBeta'))));
};
