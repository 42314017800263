import * as d3 from 'd3';
import { ArrangeTrade, ComputeAutoSankey } from '../import/OpenSankey';
import { GetLinkValue, ReturnValueNode, AssignNodeValueToCorrectVar, ComputeTotalOffsets, TestLinkValue, AssignLinkLocalAttribute, AssignNodeLocalAttribute, NodeDisplayed, LinkVisibleOnSvg, GetDataFromView } from '../FunctionOSTyped';
// OpenSankey js-code
import { GetRandomInt, } from 'open-sankey/dist/configmenus/SankeyUtils';
// Sankey Icons js-code
import ListIcons from 'sankeyanimation/dist/src/icons/lib_of_icons.json';
// Return list of all parents and childrens of node 'n'
const return_aggregation_tree_of_node = (n, nodes) => {
    const siblings = [];
    return_aggregation_parents_of_node(n, nodes, siblings);
    return_aggregation_sons_of_node(n, nodes, siblings);
    return siblings;
};
const return_aggregation_parents_of_node = (n, nodes, found_fathers) => {
    if (n.dimensions) {
        Object.entries(n.dimensions).forEach(nd => {
            if (nd[1].parent_name !== undefined && nd[1].parent_name !== null && nd[0] !== 'Primaire') {
                if (!nodes[nd[1].parent_name]) {
                    // Sanity check. Not sure it should happen
                    return;
                }
                found_fathers.push(nd[1].parent_name);
                return_aggregation_parents_of_node(nodes[nd[1].parent_name], nodes, found_fathers);
            }
        });
    }
};
const return_aggregation_sons_of_node = ((n, nodes, found_sons) => {
    // Parcours tous les noeuds à la recherche des enfants de n (ne cherche pas les enfants de type import/export)
    Object.values(nodes).filter(nn => {
        return (nn.tags['Type de noeud'] !== undefined && nn.tags['Type de noeud'].length > 0) ? (!nn.tags['Type de noeud'].includes('echange')) : true;
    }).forEach(nn => {
        if (nn.dimensions) {
            // Si le noeud (nn) a pour parents le noeud que l'on recherche (n) alors on l'ajoute à found_sons puis recherche les enfants de ce noeud là (nn)
            Object.entries(nn.dimensions).forEach(nd => {
                var _a;
                if (((_a = nd[1]) === null || _a === void 0 ? void 0 : _a.parent_name) === n.idNode && nd[0] !== 'Primaire') {
                    found_sons.push(nn.idNode);
                    return_aggregation_sons_of_node(nn, nodes, found_sons);
                }
            });
        }
    });
});
const is_leaf = (data, kn) => {
    // Check if some nodes has kn for parent if yes then kn is not a leaf otherwise it is a leaf
    return Object.values(data.nodes).filter(n => {
        if (n.dimensions === undefined || Object.keys(n.dimensions).length === 0) {
            return false;
        }
        else {
            return (Object.entries(n.dimensions).filter(en => en[0] !== 'Primaire' && en[1].parent_name === kn).length > 0);
        }
    }).length === 0;
};
export const CreateViewNodeUnitary = (t, applicationData, unitary_node, from_zdd, view_name = '') => {
    const { set_view, master_data, set_master_data, set_data } = applicationData;
    if (master_data === undefined) {
        return;
    }
    const new_unitary_sankey = JSON.parse(JSON.stringify(master_data));
    new_unitary_sankey.view = [];
    // applicationData.data = new_unitary_sankey
    const link_visible_onsankey = from_zdd ? LinkVisibleOnSvg() : Object.values(new_unitary_sankey.links).map(l => l.idLink);
    const n_link_s = Object.values(new_unitary_sankey.links).filter(l => unitary_node.inputLinksId.includes(l.idLink) && link_visible_onsankey.includes(l.idLink)).map(l => l.idSource);
    const n_link_t = Object.values(new_unitary_sankey.links).filter(l => unitary_node.outputLinksId.includes(l.idLink) && link_visible_onsankey.includes(l.idLink)).map(l => l.idTarget);
    const nodes_visible_to_keep = Object.fromEntries(Object.entries(new_unitary_sankey.nodes).filter(ne => {
        var _a, _b;
        if (ne[1].local && ((_a = ne[1].local) === null || _a === void 0 ? void 0 : _a.local_aggregation) !== undefined) {
            (_b = ne[1].local) === null || _b === void 0 ? true : delete _b.local_aggregation;
        }
        // Keep only the node contextualised,
        // or the node linked to it
        return ne[1].idNode === unitary_node.idNode || ((n_link_s.includes(ne[1].idNode) || n_link_t.includes(ne[1].idNode)));
    }).map(n => n));
    if (Object.keys(nodes_visible_to_keep).length == 0) {
        return;
    }
    // If we create a unitary sankey from the menu 'Unit.' then we have to set the level tags to allow the contextualied node to be displayed
    // (In contrary to when we create a unitary sankey from the drawing area, because the levelTags are automatically correct since we selected a visible node)
    if (!from_zdd && unitary_node.dimensions) {
        // Set the level tags to aggreagation level of the contextualised node (only if we select the node from the menu Unit.)
        const key_level_tag = Object.keys(new_unitary_sankey.levelTags).filter(klt => klt !== 'Primaire').length > 0 ? Object.keys(new_unitary_sankey.levelTags).filter(klt => klt !== 'Primaire') : Object.keys(new_unitary_sankey.levelTags);
        // Then keep only the level tags that the contextualised node has
        const key_level_tag_node = key_level_tag.filter(k => unitary_node.tags[k] ? unitary_node.tags[k].length > 0 : false);
        // So now we run through all levetTags that the contextualised node has and set them so we display the node selected at the creation of the unitary sankey
        key_level_tag_node.forEach(klt => Object.values(new_unitary_sankey.levelTags[klt].tags).forEach(kltt => kltt.selected = false));
        Object.entries(unitary_node.tags).filter(en => key_level_tag_node.includes(en[0]) && en[1].length > 0).forEach(en => Object.entries(new_unitary_sankey.levelTags[en[0]].tags).forEach(kltt => {
            kltt[1].selected = (kltt[0] === en[1][en[1].length - 1]);
        }));
    }
    // Get type of the unitary node (it can be 'secteur','produit','échange' or undefined)
    const type_node = (unitary_node.tags['Type de noeud'] !== undefined && unitary_node.tags['Type de noeud'].length > 0) ? (unitary_node.tags['Type de noeud'][0]) : undefined;
    // Get all the parents & sons (aggregation speaking) of all_nodes_in_unitary_sankey
    const center_sankey_node_unitary = [unitary_node.idNode];
    let aggregate_nodes_to_keep = [];
    Object.entries(nodes_visible_to_keep).filter(ne => ne[1].idNode !== unitary_node.idNode).forEach(ne => {
        // If the unitary node is a product then import/export linked to it are put to trade_close:false (import/export are visible nodes)
        const type_node_ne = (ne[1].tags['Type de noeud'] !== undefined && ne[1].tags['Type de noeud'].length > 0) ? (ne[1].tags['Type de noeud'][0]) : undefined;
        // If the node is an import/export, then we change it variable trade_close to false
        if (type_node === 'produit' && type_node_ne === 'echange') {
            const n_conv = ne[1];
            n_conv.trade_close = false;
            ne[1].name = ne[1].name.includes('Importations') ? 'Importations' : 'Exportations';
        }
        const parent = return_aggregation_tree_of_node(ne[1], new_unitary_sankey.nodes);
        aggregate_nodes_to_keep = [...aggregate_nodes_to_keep, ...JSON.parse(JSON.stringify(parent))];
    });
    aggregate_nodes_to_keep = [...new Set(aggregate_nodes_to_keep)];
    if (!from_zdd) {
        // If we select the contextualised node from the menu then we Desaggregate all nodes linked to it
        // We desagregate them by setting their local_aggreagation to true if they're leaf else it's set at false (except the unitary node)
        aggregate_nodes_to_keep
            .filter(kn => !center_sankey_node_unitary.includes(kn))
            .map(kn => {
            // First put all nodes has not visible (local_aggragation to false put them invisible)
            const loc = new_unitary_sankey.nodes[kn].local;
            loc ? loc.local_aggregation = false : new_unitary_sankey.nodes[kn].local = { local_aggregation: false };
            return kn;
        })
            .filter(kn => {
            // Then filter to keep only the leaf
            return is_leaf(new_unitary_sankey, kn);
        }).forEach(kn => {
            // Finally put local_aggragation value of all nodes that are leaf and linked to the unitary node to true
            const loc = new_unitary_sankey.nodes[kn].local;
            loc ? loc.local_aggregation = true : new_unitary_sankey.nodes[kn].local = { local_aggregation: true };
        });
    }
    // Add nodes not visible but that have an aggregation link to one visible
    const all_nodes_in_unitary_sankey = JSON.parse(JSON.stringify(nodes_visible_to_keep));
    aggregate_nodes_to_keep.forEach(kn => {
        all_nodes_in_unitary_sankey[kn] = new_unitary_sankey.nodes[kn];
    });
    const key_of_nodes_in_unitary_sankey = Object.values(all_nodes_in_unitary_sankey).map(n => n.idNode);
    // Get key of link that are connected to 2 nodes of the nodes to keep
    const n_link = [];
    Object.values(new_unitary_sankey.links).filter(l => key_of_nodes_in_unitary_sankey.includes(l.idSource) && key_of_nodes_in_unitary_sankey.includes(l.idTarget)).forEach(l => {
        n_link.push(l.idSource);
        n_link.push(l.idTarget);
        if (center_sankey_node_unitary.includes(l.idTarget)) {
            AssignNodeValueToCorrectVar(all_nodes_in_unitary_sankey[l.idSource], 'label_horiz', 'left', false);
        }
        else if (center_sankey_node_unitary.includes(l.idSource)) {
            AssignNodeValueToCorrectVar(all_nodes_in_unitary_sankey[l.idTarget], 'label_horiz', 'right', false);
        }
    });
    const links_to_keep = Object.fromEntries(Object.entries(new_unitary_sankey.links).filter(l => n_link.includes(l[1].idSource) && n_link.includes(l[1].idTarget)).map(l => {
        l[1].value = GetLinkValue(new_unitary_sankey, l[1].idLink);
        l[1].colorTag = 'no_colormap';
        AssignLinkLocalAttribute(l[1], 'left_horiz_shift', 0.04999999999999999);
        AssignLinkLocalAttribute(l[1], 'right_horiz_shift', 0.95);
        AssignLinkLocalAttribute(l[1], 'recycling', false);
        return l;
    }));
    const k_l_t_k = Object.keys(links_to_keep);
    // Key levelTag
    const k_level_tag = Object.keys(new_unitary_sankey.levelTags);
    Object.entries(all_nodes_in_unitary_sankey).forEach(n => {
        // Filter output/input link id by removing link no longer present in data
        n[1].outputLinksId = n[1].outputLinksId.filter(ol => k_l_t_k.includes(ol));
        n[1].inputLinksId = n[1].inputLinksId.filter(il => k_l_t_k.includes(il));
        // Keep tag that refernece levelTag && tag of group tag 'Type de noeud'
        n[1].tags = Object.fromEntries(Object.entries(n[1].tags).filter(nt => nt[0] === 'Type de noeud' || k_level_tag.includes(nt[0])));
        n[1].colorTag = 'no_colormap';
        n[1].colorParameter = 'local';
    });
    // Run throught all node to search if it is at the same time a source and a target for unitary_node
    // If that the case we duplicate the node and add a prefix (1 for the source and 1 for the target)
    Object.entries(all_nodes_in_unitary_sankey).filter(n => {
        const is_src = Object.values(links_to_keep).filter(l => l.idTarget === unitary_node.idNode && l.idSource === n[1].idNode).length > 0;
        const is_trgt = Object.values(links_to_keep).filter(l => l.idSource === unitary_node.idNode && l.idTarget === n[1].idNode).length > 0;
        return is_trgt && is_src;
    }).forEach(n => {
        let k_clone_of_node = JSON.parse(JSON.stringify(n[0]));
        const clone_of_node = JSON.parse(JSON.stringify(n[1]));
        k_clone_of_node += '_Amont';
        clone_of_node.idNode = k_clone_of_node;
        clone_of_node.name = clone_of_node.name + ' - Amont';
        // Change link in amont to link it to the clone
        const original_link_src = Object.values(links_to_keep).filter(l => l.idTarget === unitary_node.idNode && l.idSource === n[1].idNode)[0];
        original_link_src.idSource = k_clone_of_node;
        // Change original node name
        n[1].name += ' - Aval';
        // Add the clone
        all_nodes_in_unitary_sankey[k_clone_of_node] = clone_of_node;
    });
    const nodes_to_keep = all_nodes_in_unitary_sankey;
    // Create string containing datatag info for zdt
    let DT_info = '';
    // Add additional info concerning the dataTag at the moment of the creation of the unitary sankey
    // because once the unitary sankey is created, we delete the datatags
    const data_tags = Object.assign({}, new_unitary_sankey.dataTags);
    Object.entries(data_tags).forEach(tag_group => {
        const intro_group_data_tags = ' : ' + Object.values(tag_group[1].tags).filter(t => t.selected).map(t => t.name).join(', ');
        DT_info += '<p>' + tag_group[1].group_name + intro_group_data_tags + '</p>';
    });
    // Normalize data
    new_unitary_sankey.nodeTags = Object.fromEntries(Object.entries(new_unitary_sankey.nodeTags).filter(nt => nt[0] === 'Type de noeud').map(nt => nt));
    new_unitary_sankey.fluxTags = {};
    new_unitary_sankey.dataTags = {};
    new_unitary_sankey.labels = {};
    new_unitary_sankey.style_link['default'].dashed = false;
    new_unitary_sankey.colorMap = 'no_colormap';
    new_unitary_sankey.linkZIndex = new_unitary_sankey.linkZIndex.filter(lz => k_l_t_k.includes(lz)).map(l => l);
    new_unitary_sankey.nodes = nodes_to_keep;
    //new_unitary_sankey.h_space = 300
    new_unitary_sankey.links = links_to_keep;
    // If the data from which we create a unitary view from doesn't have 'unit_link_value_display' then we add it default value
    if (new_unitary_sankey.unit_link_value_display === undefined) {
        new_unitary_sankey.unit_link_value_display = 'percent';
    }
    // attributes are standardized
    // Also delete node that doesn't have link attached to it
    const to_delete = Object.entries(new_unitary_sankey.nodes).filter(n => n[1].outputLinksId.length === 0 && n[1].inputLinksId.length === 0).map(n => n[0]);
    to_delete.forEach(nid => delete new_unitary_sankey.nodes[nid]);
    let scale = d3.scaleLinear()
        .domain([0, +new_unitary_sankey.user_scale])
        .range([0, 100]);
    let inv_scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, new_unitary_sankey.user_scale]);
    // // Get a formated version unitary_node,
    // // in this variable output/input links id of the unitary_node are filtered according to the new unitary sankey
    // // Notably usefull for compute_auto_unitary_sankey that run throught the variables input/output links id of the contextualised node,
    // // so it could have accessed link id in inputLinksId of unitary_node that are no longer present in new_unitary_sankey
    // const formated_unitary_node = new_unitary_sankey.nodes[unitary_node.idNode]
    // links attributes are standardized
    Object.values(new_unitary_sankey.links).forEach(link => {
        link.style = 'default';
        link.local = { curved: true };
    });
    // Some preparation of nodes attributes
    // If it's not a unitary node we don't show the shape
    // else we format it text and add an icon according to it node type
    if (new_unitary_sankey.icon_catalog === undefined) {
        new_unitary_sankey.icon_catalog = {};
    }
    Object.values(new_unitary_sankey.nodes).forEach(n => {
        delete n.x_label;
        delete n.y_label;
        n.y += 200;
        AssignNodeLocalAttribute(n, 'label_background', false);
        AssignNodeLocalAttribute(n, 'font_size', 20);
        if (n.idNode !== unitary_node.idNode) {
            AssignNodeLocalAttribute(n, 'shape_visible', false);
            AssignNodeLocalAttribute(n, 'bold', false);
            AssignNodeLocalAttribute(n, 'uppercase', false);
            AssignNodeLocalAttribute(n, 'node_width', 1);
            AssignNodeLocalAttribute(n, 'label_vert', 'middle');
            if (ReturnValueNode(new_unitary_sankey, n, 'label_box_width') <= 150) {
                AssignNodeLocalAttribute(n, 'label_box_width', 150);
            }
            // Change the label_box_width of import/export nodes after ArrangeTrade because it reset it width at 300px ( and we changed their names to be shorter)
            const type_node_ne = (n.tags['Type de noeud'] !== undefined && n.tags['Type de noeud'].length > 0) ? (n.tags['Type de noeud'][0]) : undefined;
            if (type_node === 'produit' && type_node_ne === 'echange') {
                // Since originally the import/export nodes label is like : 'name of the node' - 'the group name of the exchange' - 'import or export',
                // the label_box_width is very large to contain it in 1 line and now we just set the name Import or Export to those node linked to the unitary node,
                // We re adjust the label_box_width so when we create the ZDT it take the correct value of node label width so the ZDT is not too large
                AssignNodeLocalAttribute(n, 'label_box_width', 150);
            }
        }
        else {
            AssignNodeLocalAttribute(n, 'bold', true);
            AssignNodeLocalAttribute(n, 'uppercase', true);
            AssignNodeLocalAttribute(n, 'label_vert', 'bottom');
            AssignNodeLocalAttribute(n, 'label_horiz', 'middle');
            AssignNodeLocalAttribute(n, 'node_width', 20);
            AssignNodeLocalAttribute(n, 'label_box_width', 200);
            // Add an icon to the unitary node depending of it node type
            if (type_node === 'secteur') {
                AssignNodeLocalAttribute(n, 'shape_visible', false);
                n.iconName = 'node_industrie';
                n.iconColor = 'black';
                n.iconVisible = true;
                n.is_image = false;
                n.image_src = '';
                delete n.iconViewBox;
                new_unitary_sankey.icon_catalog['node_industrie'] = ListIcons.waste.industry;
            }
            else if (type_node === 'produit') {
                AssignNodeLocalAttribute(n, 'shape_visible', false);
                n.iconName = 'node_market_process';
                n.iconColor = 'black';
                n.iconVisible = true;
                n.is_image = false;
                n.image_src = '';
                new_unitary_sankey.icon_catalog['node_market_process'] = ListIcons.waste.atom;
            }
        }
    });
    // Reposition visible node
    ArrangeTrade(applicationData, true);
    const appUnitaryData = JSON.parse(JSON.stringify(applicationData));
    appUnitaryData.display_nodes = new_unitary_sankey.nodes;
    appUnitaryData.display_links = new_unitary_sankey.links;
    appUnitaryData.nodes = new_unitary_sankey.nodes;
    appUnitaryData.nodes = new_unitary_sankey.links;
    ComputeAutoSankey(appUnitaryData, false);
    // Recompute scale after the compute auto_sankey that reset user_scale
    scale = d3.scaleLinear()
        .domain([0, +new_unitary_sankey.user_scale])
        .range([0, 100]);
    inv_scale = d3.scaleLinear()
        .domain([0, 100])
        .range([0, new_unitary_sankey.user_scale]);
    // ======Add ZDT======
    // Get dimensions for labels
    // Get the node the most at left
    const min_x_node = Object.values(nodes_to_keep).filter(n => ReturnValueNode(applicationData.data, n, 'position') !== 'relative' && NodeDisplayed(new_unitary_sankey, n)).sort((a, b) => {
        return (a.x) - (b.x);
    })[0];
    // Get widest label_box from nodes on the left side of the contexualised node
    let widest_left_label_box = 0;
    Object.values(nodes_to_keep)
        .filter(n => ReturnValueNode(applicationData.data, n, 'position') !== 'relative' && NodeDisplayed(new_unitary_sankey, n) && Object.values(new_unitary_sankey.links).filter(l => l.idSource === n.idNode && l.idTarget === unitary_node.idNode).length > 0)
        .forEach((n) => {
        const tmp = ReturnValueNode(new_unitary_sankey, n, 'label_box_width');
        widest_left_label_box = tmp > widest_left_label_box ? tmp : widest_left_label_box;
    });
    // Get widest label_box from nodes on the right side of the contexualised node
    let widest_right_label_box = 0;
    Object.values(nodes_to_keep)
        .filter(n => {
        return ReturnValueNode(applicationData.data, n, 'position') !== 'relative' && NodeDisplayed(new_unitary_sankey, n) && Object.values(new_unitary_sankey.links).filter(l => l.idTarget === n.idNode && l.idSource === unitary_node.idNode).length > 0;
    })
        .forEach((n) => {
        const tmp = ReturnValueNode(new_unitary_sankey, n, 'label_box_width');
        widest_right_label_box = tmp > widest_right_label_box ? tmp : widest_right_label_box;
    });
    // Substract widest_left_label_box to the min x so when we create the ZDT it take into account the label
    const min_x = min_x_node.x - widest_left_label_box;
    // Get the node the most at top
    const min_y_node = Object.values(nodes_to_keep).filter(n => ReturnValueNode(applicationData.data, n, 'position') !== 'relative' && NodeDisplayed(new_unitary_sankey, n)).sort((a, b) => {
        return (a.y) - (b.y);
    })[0];
    const min_y = min_y_node.y;
    let max_x = min_x;
    let max_y = min_y;
    Object.values(nodes_to_keep).filter(n => ReturnValueNode(applicationData.data, n, 'position') !== 'relative' && NodeDisplayed(new_unitary_sankey, n)).forEach(n => {
        const boxX = n.x;
        const boxY = n.y;
        const res = ComputeTotalOffsets(inv_scale, n, applicationData, TestLinkValue, undefined, GetLinkValue);
        const [total_offset_height_left, total_offset_height_right, total_offset_width_top, total_offset_width_bottom] = res;
        let node_size_s_height = Math.max(inv_scale(ReturnValueNode(new_unitary_sankey, n, 'node_height')), total_offset_height_left, total_offset_height_right);
        let node_size_s_width = Math.max(inv_scale(ReturnValueNode(new_unitary_sankey, n, 'node_width')), total_offset_width_top, total_offset_width_bottom);
        //Hauteur des noeuds
        if ((res[0] === 0 && res[1] === 0 && res[2] === 0 && res[3] === 0) || (new_unitary_sankey.show_structure === 'structure')) {
            node_size_s_height = inv_scale(ReturnValueNode(new_unitary_sankey, n, 'node_height'));
            node_size_s_width = inv_scale(ReturnValueNode(new_unitary_sankey, n, 'node_width'));
        }
        const boxW = scale(node_size_s_width);
        const boxH = scale(node_size_s_height);
        max_x = ((boxX + boxW) > max_x) ? (boxX + boxW) : max_x;
        max_y = ((boxY + boxH) > max_y) ? (boxY + boxH) : max_y;
    });
    // Add widest_right_label_box to the max x so when we create the ZDT it take into account the label of right nodes
    max_x += widest_right_label_box;
    // Info from data source in ZDT
    // customize the info to add in the zdt
    let content_zdt = '';
    let type_process = '';
    if (type_node === 'produit' || type_node === 'secteur') {
        if (type_node === 'produit') {
            type_process = 'Process de marché';
        }
        else {
            type_process = 'Process de transformation';
        }
    }
    else {
        type_process = t('view.template_unitary_zdt_content_of_node');
    }
    // Add the info to the zdt
    const name_view = (master_data.view && master_data.current_view && master_data.current_view !== 'none') ?
        master_data.view.filter(v => v.id === master_data.current_view)[0].nom :
        t('Menu.home');
    content_zdt += '<p class="ql-align-center" style="font-size:20px"><u>' + type_process + ' : <strong>' + unitary_node.name + '</strong></u></p>';
    content_zdt += '<p>' + t('view.template_unitary_zdt_content') + ' <strong>' + name_view + ' </strong></p>';
    content_zdt += DT_info;
    // Create the zdt
    const n_label = {
        idLabel: 'label_' + String(new Date().getTime()),
        title: 'Description',
        content: content_zdt,
        label_width: ((max_x - min_x)),
        label_height: ((max_y - min_y) + 150),
        color: 'white',
        color_border: 'black',
        opacity: 100,
        transparent_border: false,
        x: min_x,
        y: min_y - 100,
        is_image: false,
        image_src: ''
    };
    // Perform some configuration if the unitary go to a new view or an existing view
    if (view_name === '') {
        new_unitary_sankey.labels[n_label.idLabel] = n_label;
        // Add the contextualised node to list of explored nodes (to use in the process of LinkText)
        new_unitary_sankey.unitary_node = [...new_unitary_sankey.unitary_node, ...center_sankey_node_unitary];
        const new_id = 'view_' + String(new Date().getTime()) + String(GetRandomInt(100));
        master_data.view.push({
            id: new_id,
            view_data: new_unitary_sankey,
            nom: t('view.default_unit_view_name') + unitary_node.name,
            details: '',
            heredited_attr_from_master: []
        });
        master_data.current_view = new_id;
        set_view(new_id);
        set_data(Object.assign({}, new_unitary_sankey));
    }
    else {
        // If the unitary sankey go to an existing view then we perform some ajustement :
        // - We change the id of links and nodes to avoid corruption of the other unitary sankey in the view
        // - We shift the explainatory ZDT  and new unitary sankey under the existing one
        // Search for the view to add the new exploration node
        let ind = -1;
        master_data.view.forEach((v, i) => {
            ind = (v.id === view_name) ? i : ind;
        });
        const data_view = GetDataFromView(master_data, master_data.view[ind].id);
        // =====Update data icon catalog=====
        Object.entries(new_unitary_sankey.icon_catalog).forEach(i => {
            data_view.icon_catalog[i[0]] = i[1];
        });
        // =====Update the ZDT to verticaly align it with the last one in the view=====
        const last_label_pos_in_view = Object.values(data_view.labels)[Object.values(data_view.labels).length - 1];
        n_label.x = last_label_pos_in_view.x;
        n_label.y = last_label_pos_in_view.y + last_label_pos_in_view.label_height + 5;
        // =====Update nodes & links=====
        // Create unique key to use in the suffix
        const unique_key = String(new Date().getTime());
        // Add a unique suffix to nodes & links in case we add node/link who have the same id of some in the view
        Object.entries(new_unitary_sankey.nodes).map(n => {
            n[0] = n[0] + '_' + unique_key;
            n[1].idNode = n[0];
            n[1].inputLinksId = n[1].inputLinksId.map(l => {
                const idSrc = new_unitary_sankey.links[l].idSource + '_' + unique_key;
                return idSrc + '---' + n[0];
            });
            n[1].outputLinksId = n[1].outputLinksId.map(l => {
                const idTrgt = new_unitary_sankey.links[l].idTarget + '_' + unique_key;
                return n[0] + '---' + idTrgt;
            });
            n[1].x -= (min_x - n_label.x);
            n[1].y -= (min_y - n_label.y) - 100;
            n[1].style = n[1].style + '_' + unique_key;
            Object.keys(n[1].tags).forEach(nkt => nkt = nkt + '_' + unique_key);
            if (n[1].dimensions) {
                Object.keys(n[1].dimensions).forEach(nd => nd = nd + '_' + unique_key);
            }
            return n;
        }).forEach(n => {
            data_view.nodes[n[0]] = n[1];
        });
        // Change idLink,idSource and idTarget of links to avoid corruption of existing links
        Object.entries(new_unitary_sankey.links).map(l => {
            l[1].idSource = l[1].idSource + '_' + unique_key;
            l[1].idTarget = l[1].idTarget + '_' + unique_key;
            l[0] = l[1].idSource + '---' + l[1].idTarget;
            l[1].idLink = l[0];
            l[1].style = l[1].style + '_' + unique_key;
            return l;
        }).forEach(l => {
            data_view.linkZIndex.push(l[1].idLink);
            data_view.links[l[0]] = l[1];
        });
        // Update style ID in case it has different value with the samez id
        Object.entries(new_unitary_sankey.style_node).forEach(ns => {
            data_view.style_node[ns[0] + '_' + unique_key] = ns[1];
        });
        Object.entries(new_unitary_sankey.style_link).forEach(ls => {
            data_view.style_link[ls[0] + '_' + unique_key] = ls[1];
        });
        // Update levelTags key so it doesn't interfer with other unitary node levelTags
        Object.entries(new_unitary_sankey.levelTags).forEach(lt => {
            data_view.levelTags[lt[0] + '_' + unique_key] = lt[1];
        });
        // =====Add the new ZDT=====
        data_view.labels[n_label.idLabel] = n_label;
        // =====Add the contextualised node to list of explored nodes (to use in the process of LinkText)=====
        const process_name_unitary_node = center_sankey_node_unitary.map(k => k + '_' + unique_key);
        data_view.unitary_node = [...data_view.unitary_node, ...process_name_unitary_node];
        if (data_view.unit_link_value_display === undefined) {
            data_view.unit_link_value_display = 'percent';
        }
        // ------------------------------------------------------------
        // =====Update the view=====
        // master_data.view[ind].view_data=new_unitary_sankey
        master_data.current_view = view_name;
        set_view(view_name);
        set_data(Object.assign({}, data_view));
    }
    // Save master data with the view we are currently working on updated
    set_master_data(Object.assign({}, master_data));
};
