import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Badge, Box, Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react';
import { OSTooltip } from './import/OpenSankey';
export const SankeyDevMenuPublishItem = ({ t, dict_hook_ref_setter_show_dialog_components }) => {
    return React.createElement(OSTooltip, { placement: 'bottom', label: t('Menu.tooltips.publish') },
        React.createElement(Box, null,
            React.createElement(Button, { variant: 'menutop_button', onClick: () => dict_hook_ref_setter_show_dialog_components.ref_setter_show_publish_dialog.current(true) },
                React.createElement(Box, { layerStyle: 'menutop_button_style' },
                    React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                        React.createElement(FontAwesomeIcon, { style: { 'height': '2rem', 'width': '3rem' }, icon: faShareFromSquare }),
                        React.createElement(Box, { position: 'absolute', zIndex: '1', top: '0', right: '0' },
                            React.createElement(Badge, null, "Dev"))),
                    React.createElement(Box, { gridRow: "2" }, t('Menu.pub'))))));
};
/**
   * Description placeholder
   *
   * @param {PublishModalTypes} { publishImpl,set_ref_setter_show_publish_dialog,file_path_initial }
   * @returns
   */
export const PublishModal = ({ t, publishImpl, dict_hook_ref_setter_show_dialog_components, data }) => {
    const [show_modal_publish, set_show_modal_publish] = useState(false);
    dict_hook_ref_setter_show_dialog_components.ref_setter_show_publish_dialog.current = set_show_modal_publish;
    return (React.createElement(Modal, { isOpen: show_modal_publish, onClose: () => set_show_modal_publish(false) },
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalHeader, null, t('Menu.pdd')),
            React.createElement(ModalBody, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.ca')),
                React.createElement(Input, { placeholder: data.file_name ? data.file_name : '', onChange: (evt) => data.file_name = evt.target.value })),
            React.createElement(ModalFooter, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Button, { variant: 'menuconfigpanel_add_button', onClick: () => publishImpl(data.file_name) }, t('Menu.pub')),
                    React.createElement(Button, { variant: 'menuconfigpanel_del_button', onClick: () => set_show_modal_publish(false) }, t('Menu.close')))))));
};
