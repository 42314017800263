import { useRef, useState } from 'react';
import React from 'react';
import * as d3 from 'd3';
import { MenuDraggable, closeAllMenu, initializeContextMenu, updateLayoutOSTyped, NodeTooltipsContent, convert_data, EventOnZoneMouseDown, EventOnZoneMouseUp, initializeCloseAllMenuContext, setDiagram, updateDrawNodeShape, RedrawNodesLabel, DrawAllNodes, AddAllDropDownNode, AdjustSankeyZone } from './import/OpenSankey';
import { os_all_element_to_transform } from 'open-sankey/dist/dialogs/SankeyMenuDialogs';
import { OSPDrawNodesFO, OSPNodeFO } from './SankeyPlusForeignObject';
import { OSPDrawArrows, OSPLinkStroke, MenuConfLinkApparenceGradient } from './SankeyPlusGradient';
import { OSPDrawLabels, sankey_plus_min_width_and_height, zone_selection_label } from './SankeyPlusLabels';
import { OSPMenuPreferenceLabels, ZDTMenuAsAccordeonItem, OSPMenuConfigurationFreeLabels, ContextZDT, blur_ZDT_wysiwyg } from './SankeyPlusMenuConfigurationLabels';
import { OSPDrawNodesIllustration, OSPNodeClickEvent, OSPNodeIcon, OSPHyperLink, OpposingDragElementsPlus } from './SankeyPlusNodes';
import { DefaultOSPStyleLink, ImportImageAsSvgBg, OSPItemExport, OSPLinkSabotColor, SetSvgBg } from './SankeyPlusUtils';
import { plus_convert_data, plus_sankey_layout, plus_all_element_to_transform, OSPTransformationElements, } from './SankeyPlusConvert';
import { GetDataFromView, MenuEnregistrerView, OSPKeyHandler, OSPBannerView, SelecteurView, getSetDiagramFunc, modal_transparent_view_attr, modal_view_not_saved, viewsAccordion, OSPMenuPreferenceView } from './SankeyPlusViews';
import ModalSelectionIcon from './SankeyPlusCatalogIcon';
import { t } from 'i18next';
import { Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Button, PopoverArrow, PopoverCloseButton, Input } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderTree } from '@fortawesome/free-solid-svg-icons';
export const OSPDefaultData = () => {
    return {
        is_catalog: false,
        view: [],
        current_view: 'none',
        labels: {},
        icon_catalog: {},
        style_link: { 'default': DefaultOSPStyleLink() },
        // unitary_node:[],
        // unit_link_value_display:'percent',
        background_image: ''
    };
};
export const OSPInitializeApplicationContext = () => {
    let logo_OSP = '';
    try {
        /* eslint-disable */
        // @ts-ignore
        logo_OSP = require('./css/OSP.png');
        /* eslint-enable */
        const path = window.location.origin;
        if (!path.includes('localhost')) {
            logo_OSP = logo_OSP.replace('static/', 'static/sankeysuite/');
        }
    }
    catch (expt) {
        console.log('logo_OSP not found');
    }
    let logo_terriflux = '';
    try {
        /* eslint-disable */
        // @ts-ignore
        logo_terriflux = require('./css/terriflux.png');
        /* eslint-enable */
        const path = window.location.origin;
        if (!path.includes('localhost')) {
            logo_terriflux = logo_terriflux.replace('static/', 'static/opensankey/');
        }
    }
    catch (expt) {
        console.log('terriflux.png not found');
    }
    return {
        has_open_sankey_plus: true,
        logo: logo_OSP,
        logo_terriflux: logo_terriflux
    };
};
export const OSPInitializeApplicationData = (data, set_data, get_default_data, display_nodes, display_links) => {
    const data_plus = data;
    const [master_data, set_master_data] = useState(); // useState OK
    const [view, pre_set_view] = useState(data_plus.current_view); // useState OK
    const set_view = (s) => {
        data_plus.current_view = s;
        if (master_data) {
            master_data.current_view = s;
        }
        pre_set_view(s);
    };
    const [view_not_saved, set_view_not_saved] = useState('');
    const plus_display_nodes = display_nodes;
    const plus_display_links = display_links;
    const set_data_plus = set_data;
    const plus_get_defaut_data = get_default_data;
    const useOpenSankeySetDiagram = (master_data && master_data.view.length > 0) || window.SankeyToolsStatic;
    // If initial data has views & has a current view then update current data to the view (and initial data become master data)
    if (data_plus.view && data_plus.view.length > 0 && !master_data) {
        set_master_data(Object.assign({}, JSON.parse(JSON.stringify(data))));
        if (data_plus.current_view && data_plus.current_view !== 'none') {
            const view_to_display = GetDataFromView(data_plus, data_plus.current_view);
            set_view(data_plus.current_view);
            set_data(view_to_display);
        }
    }
    return {
        data: data_plus,
        set_data: set_data_plus,
        display_nodes: plus_display_nodes,
        display_links: plus_display_links,
        get_default_data: plus_get_defaut_data,
        convert_data: (data, DefaultSankeyData) => {
            plus_convert_data(data, DefaultSankeyData);
            convert_data(data, DefaultSankeyData);
        },
        master_data,
        set_master_data,
        view,
        set_view,
        view_not_saved: view_not_saved,
        set_view_not_saved: set_view_not_saved,
        setDiagram: useOpenSankeySetDiagram ? getSetDiagramFunc(set_master_data, set_view, plus_get_defaut_data) : setDiagram,
        is_catalog: false
    };
};
export const OSPInitializeElementSelected = () => {
    return {
        multi_selected_nodes: useRef([]),
        multi_selected_links: useRef([]),
        multi_selected_label: useRef([]),
        r_editor_ZDT: useRef(),
        //r_setter_editor_content_fo_zdt : useRef<Dispatch<SetStateAction<string>>[]>([]),
        r_setter_editor_content_fo_node: useRef(),
        r_setter_value_editor_name_view: useRef(),
        saveViewGetter: useRef(false)
    };
};
export const OSPInitializeShowDialog = () => {
    return {
        ref_setter_show_menu_node_icon: useRef(() => null),
        ref_setter_show_modal_import_icons: useRef(() => null),
        ref_setter_show_menu_zdt: useRef(() => null),
        ref_setter_show_modal_transparent_view_attr: useRef(() => null),
    };
};
export const OSPcloseAllMenu = closeAllMenu;
// Modify Application Draw
export const OSPInitializeApplicationDraw = (applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater, uiElementsRef, node_function, link_function, start_point, resizeCanvas) => {
    const _ = {
        reDrawOSPLabels: (object_to_update) => {
            OSPDrawLabels(applicationData, applicationState, uiElementsRef, contextMenu, applicationContext, sankey_plus_min_width_and_height, contextMenu.closeAllMenuContext, _, ComponentUpdater, object_to_update, link_function, start_point, resizeCanvas);
            ComponentUpdater.updateComponenSaveInCache.current(false);
        },
        GetSankeyMinWidthAndHeight: sankey_plus_min_width_and_height,
        updateLayout: (data, new_layout, mode, synchronize) => {
            updateLayoutOSTyped(data, new_layout, mode, synchronize);
            plus_sankey_layout(data, new_layout, mode);
        },
        all_element_UpdateLayout: [...os_all_element_to_transform, ...plus_all_element_to_transform],
        reAdjustSankey: () => (() => {
            AdjustSankeyZone(applicationData, sankey_plus_min_width_and_height, false, true);
        })()
    };
    return _;
};
export const OSPInitializeComponentUpdater = () => {
    const _ = {
        updateComponentMenuConfigZdt: useRef([]),
    };
    _.updateComponentMenuConfigZdt.current = [];
    return _;
};
export const OSPInitializeReinitialization = (applicationData, applicationState) => () => {
    const recast_selected_dict = applicationState;
    recast_selected_dict.multi_selected_label.current = [];
    localStorage.removeItem('icon_imported');
    sessionStorage.setItem('dismiss_warning_sankey_plus', '0');
    applicationData.set_master_data(undefined);
    applicationData.set_view('none');
};
// Modify context menu
export const OSPInitializeContextMenu = () => {
    const context_menu = initializeContextMenu();
    const osp_context_menu = context_menu;
    osp_context_menu.contextualised_zdt = useRef();
    osp_context_menu.closeAllMenuContext = () => {
        initializeCloseAllMenuContext(context_menu.ref_setter_contextualised_node, context_menu.ref_setter_contextualised_link, context_menu.tagContext, context_menu.showContextZDDRef)();
        osp_context_menu.contextualised_zdt.current(undefined);
    };
    return context_menu;
};
// Modify Ref used to open accordion item
export const OSPInitializeUIElementsRef = () => {
    return {
        zdt_accordion_ref: useRef(null),
        ViewSelector: useRef(null)
    };
};
// Only override
export const OSPInitializeLinkFunctions = () => {
    return {
        DrawArrows: OSPDrawArrows,
        LinkStroke: OSPLinkStroke,
        LinkSabotColor: OSPLinkSabotColor
    };
};
export const OSPInitializeNodeFunctions = (applicationData, applicationState, contextMenu, applicationContext, ComponentUpdater, uiElementsRef, resizeCanvas, dict_hook_ref_setter_show_dialog_components, ref_alt_key_pressed, accept_simple_click, recomputeDisplayedElement, link_function) => {
    const animating = useRef(false); //TODO
    const reDrawIllustration = (nodes_to_update) => {
        OSPDrawNodesIllustration(applicationData.data, nodes_to_update, applicationState, NodeTooltipsContent, link_function.GetLinkValue, applicationContext.t);
    };
    const _ = {
        reDrawIllustration: reDrawIllustration,
        reDrawOSPNodeEvent: (nodes_to_update) => {
            OSPNodeClickEvent(applicationData, applicationState, uiElementsRef, animating, accept_simple_click, link_function.GetLinkValue, ComponentUpdater, nodes_to_update);
        }
    };
    _.OpposingDragElements = OpposingDragElementsPlus;
    _.RedrawNodes = (nodes_to_update) => {
        const osp_nodes_to_update = nodes_to_update;
        updateDrawNodeShape(applicationData, link_function, applicationState.multi_selected_nodes, nodes_to_update);
        RedrawNodesLabel(applicationData, nodes_to_update, link_function.GetLinkValue, applicationContext.t, _);
        reDrawIllustration(nodes_to_update);
        OSPNodeClickEvent(applicationData, applicationState, uiElementsRef, animating, accept_simple_click, link_function.GetLinkValue, ComponentUpdater, osp_nodes_to_update);
        OSPDrawNodesFO(applicationData.data, applicationData.display_nodes, applicationState, NodeTooltipsContent, link_function.GetLinkValue, applicationContext.t);
        return null;
    };
    _.DrawAllNodes = (contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, GetSankeyMinWidthAndHeight, resizeCanvas) => {
        const osp_nodes_to_update = Object.values(applicationData.display_nodes);
        DrawAllNodes(contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, GetSankeyMinWidthAndHeight, resizeCanvas);
        reDrawIllustration(osp_nodes_to_update);
        OSPNodeClickEvent(applicationData, applicationState, uiElementsRef, animating, accept_simple_click, link_function.GetLinkValue, ComponentUpdater, osp_nodes_to_update);
        OSPDrawNodesFO(applicationData.data, applicationData.display_nodes, applicationState, NodeTooltipsContent, link_function.GetLinkValue, applicationContext.t);
    };
    return _;
};
// Since AdditionalMenus is an OS var specially created to add external element in menus
// we don't have to recast initializeAdditionalMenusType for more var or overwritting parameter types
export const OSPInitializeAdditionalMenus = (additionalMenus, applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState, uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function) => {
    const OSPApplicationContext = applicationContext;
    const plus_dict_app_data = applicationData;
    uiElementsRef.ViewSelector.current = React.createElement(SelecteurView, { applicationData: plus_dict_app_data, applicationState: applicationState, t: applicationContext.t, set_view_not_saved: plus_dict_app_data.set_view_not_saved, connected: OSPApplicationContext.has_open_sankey_plus });
    // Top Menus
    additionalMenus.external_file_export_item.push(React.createElement(OSPItemExport, null));
    // Page settings
    additionalMenus.extra_background_element = React.createElement(ImportImageAsSvgBg, { t: applicationContext.t, data: applicationData.data, set_data: applicationData.set_data, has_open_sankey_plus: true });
    const OSPApplicationData = applicationData;
    const has_views = OSPApplicationData.master_data && OSPApplicationData.master_data.view.length > 0;
    if (!window.SankeyToolsStatic || has_views) {
        additionalMenus.externale_navbar_item['view'] = React.createElement(OSPBannerView, { applicationData: applicationData, applicationContext: OSPApplicationContext, dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_components, convert_data: applicationData.convert_data, view_selector: uiElementsRef.ViewSelector.current });
    }
    // Menu conf nodes
    additionalMenus.additional_menu_configuration_nodes['Noeud.tabs.icon'] = React.createElement(OSPNodeIcon, { t: applicationContext.t, data: applicationData.data, multi_selected_nodes: applicationState.multi_selected_nodes, is_activated: true, menu_for_modal: false, dict_hook_ref_setter_show_dialog_components: dict_hook_ref_setter_show_dialog_components, node_function: node_function, ComponentUpdater: ComponentUpdater });
    additionalMenus.additional_menu_configuration_nodes['Noeud.tabs.fo'] = React.createElement(OSPNodeFO, { t: applicationContext.t, data: applicationData.data, multi_selected_nodes: applicationState.multi_selected_nodes, is_activated: true, applicationState: applicationState, node_function: node_function });
    additionalMenus.additional_menu_configuration_nodes['Noeud.tabs.hl'] = React.createElement(OSPHyperLink, { t: applicationContext.t, data: applicationData.data, multi_selected_nodes: applicationState.multi_selected_nodes, is_activated: true, node_function: node_function });
    //Links
    additionalMenus.additional_link_appearence_items.push(React.createElement(MenuConfLinkApparenceGradient, { applicationContext: applicationContext, ComponentUpdater: ComponentUpdater, multi_selected_links: applicationState.multi_selected_links, data: applicationData.data, link_function: link_function, is_activated: true, menu_for_style: false, selected_style_link: applicationState.ref_selected_style_link }));
    //Preferences
    additionalMenus.additional_preferences.push(React.createElement(OSPMenuPreferenceLabels, { t: applicationContext.t, data: applicationData.data, updateMenus: ComponentUpdater.updateMenus }));
    additionalMenus.additional_preferences.push(OSPMenuPreferenceView(applicationContext.t, applicationData.data, applicationData.set_data));
    //- Builds Configuration Menus FreeLabel
    additionalMenus.additional_configuration_menus.push(React.createElement(ZDTMenuAsAccordeonItem, { data: applicationData.data, uiElementsRef: uiElementsRef, applicationContext: applicationContext, content_menu_zdt: React.createElement(OSPMenuConfigurationFreeLabels, { applicationData: applicationData, applicationContext: applicationContext, applicationState: applicationState, reDrawOSPLabels: applicationDraw.reDrawOSPLabels, ComponentUpdater: ComponentUpdater }) }));
    const plusData = applicationData;
    if (plusData.master_data && plusData.master_data.current_view && plusData.master_data.current_view !== 'none') {
        additionalMenus.additional_file_save_json_option.push(React.createElement(MenuEnregistrerView, { t: t, elementsSelected: applicationState }));
    }
    // add option for updateLayout (OSP var to update)
    // (Only add these options if connected with OSP)
    const component_apply_transfor_OSP = React.createElement(OSPTransformationElements, { applicationData: plusData, applicationContext: applicationContext, ComponentUpdater: ComponentUpdater });
    // Add buttons in the menu transformation for adding ZDT and views as variable transferable in SuiteUpdateLayout
    additionalMenus.apply_transformation_additional_elements.push(component_apply_transfor_OSP);
};
// module_dialogsType return a JSX.Element array wich is a react type
// we don't need to recast it ( and don't need additionnal parameters for OSP dialogs)
export const OSPModuleDialogs = (applicationContext, applicationData, applicationState, contextMenu, applicationDraw, uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, ComponentUpdater) => {
    const OSP_elements_selected = applicationState;
    const OSP_dict_hook_ref = dict_hook_ref_setter_show_dialog_components;
    const OSP_dict_app_data = applicationData;
    const OSP_node_function = node_function;
    return [
        MenuDraggable(OSP_dict_hook_ref, 'ref_setter_show_menu_zdt', React.createElement(OSPMenuConfigurationFreeLabels, { applicationData: OSP_dict_app_data, reDrawOSPLabels: applicationDraw.reDrawOSPLabels, applicationContext: applicationContext, ComponentUpdater: ComponentUpdater, applicationState: OSP_elements_selected }), contextMenu.pointer_pos, applicationContext.t('Menu.LL')),
        React.createElement(ContextZDT, { contextMenu: contextMenu, t: applicationContext.t, applicationData: OSP_dict_app_data, dict_hook_ref_setter_show_dialog_components: OSP_dict_hook_ref, applicationState: applicationState, ComponentUpdater: ComponentUpdater, reDrawOSPLabels: applicationDraw.reDrawOSPLabels }),
        modal_transparent_view_attr(OSP_dict_hook_ref, OSP_dict_app_data, applicationContext.t),
        modal_view_not_saved(OSP_dict_app_data.view_not_saved, OSP_dict_app_data.set_view_not_saved, applicationContext.t, applicationData),
        React.createElement(ModalSelectionIcon, { t: applicationContext.t, applicationData: OSP_dict_app_data, applicationState: OSP_elements_selected, dict_hook_ref_setter_show_dialog_components: OSP_dict_hook_ref, node_function: OSP_node_function })
    ];
};
// Function to draw element on svg area it return nothing
// we don't need to recast it ( and don't need additionnal parameters for OSP draw elements functions)
export const OSPDrawAll = (contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, GetSankeyMinWidthAndHeight, applicationDraw) => {
    d3.selectAll(' .opensankey #svg #g_label').remove();
    d3.selectAll(' .opensankey #svg #g_label_handles').remove();
    // Insert la balise qui contient tous les labels libres avant la balise de la légende
    d3.select('.opensankey #svg').insert('g', '#g_links').attr('class', 'g_label').attr('id', 'g_label');
    d3.select('.opensankey #svg').append('g').attr('class', 'g_label_handles').attr('id', 'g_label_handles');
    // // Call the function that add links to the sankey
    // d3.selectAll(' .opensankey #svg #sankey_def').remove()
    // d3.select(' .opensankey #svg').append('defs').attr('id', 'sankey_def')
    // Free Labels
    OSPDrawLabels(applicationData, applicationState, uiElementsRef, contextMenu, applicationContext, applicationDraw.GetSankeyMinWidthAndHeight, contextMenu.closeAllMenuContext, applicationDraw, ComponentUpdater, Object.values(applicationData.data.labels), link_function, applicationDraw.start_point, applicationDraw.resizeCanvas);
    SetSvgBg(applicationData.data);
};
// Function to add event on elements on svg area it return nothing
// we don't need to recast it ( and don't need additionnal parameters for OSP add event functions)
export const OSPInstallEventsOnSVG = (contextMenu, applicationContext, applicationData, uiElementsRef, applicationState, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, applicationDraw) => {
    const svgSankey = d3.select('.opensankey #svg');
    svgSankey.on('mousedown', evt => {
        blur_ZDT_wysiwyg(applicationState.r_editor_ZDT);
        EventOnZoneMouseDown(applicationData, applicationState, dict_hook_ref_setter_show_dialog_components, applicationContext.has_free_account, evt, applicationDraw.start_point, contextMenu.closeAllMenuContext, node_function);
    });
    svgSankey.on('mouseup', evt => {
        zone_selection_label(applicationData.data, applicationState.multi_selected_label, evt, ComponentUpdater);
        EventOnZoneMouseUp(applicationData, uiElementsRef, applicationState, dict_hook_ref_setter_show_dialog_components, true, evt, applicationDraw.start_point, applicationState.legend_clicked, link_function, ComponentUpdater, node_function, applicationDraw.reDrawLegend, applicationDraw.resizeCanvas);
    });
};
export const OSPUpdateMenuConf = (menu_conf, applicationData, applicationContext, uiElementsRef) => {
    var _a, _b;
    const OSPApplicationData = applicationData;
    const OSPUiElementsRef = uiElementsRef;
    const OSPApplicationContext = applicationContext;
    const menu_conf_view = viewsAccordion(OSPApplicationData, OSPApplicationContext.t, OSPApplicationContext.has_open_sankey_plus, OSPApplicationData.convert_data, OSPApplicationData.get_default_data, (_b = (_a = OSPUiElementsRef.ViewSelector) === null || _a === void 0 ? void 0 : _a.current) !== null && _b !== void 0 ? _b : React.createElement(React.Fragment, null));
    menu_conf.push(menu_conf_view);
    return menu_conf;
};
export const OSPInitializeKeyHandler = (applicationContext, e, applicationData, applicationState, dict_hook_ref_setter_show_dialog_components, reDrawOSPLabels, ComponentUpdater) => {
    OSPKeyHandler(applicationContext, e, applicationData, applicationState, dict_hook_ref_setter_show_dialog_components, reDrawOSPLabels, ComponentUpdater);
};
export const OSPInitalizeSelectorDetailNodes = (applicationContext, applicationData, applicationDraw, node_function, link_function, ComponentUpdater) => {
    const mutiple_level_tag_filter = React.createElement(AddAllDropDownNode, { applicationContext: applicationContext, ComponentUpdater: ComponentUpdater, applicationData: applicationData, level: true, node_function: node_function, link_function: link_function, applicationDraw: applicationDraw });
    return React.createElement(Popover, { placement: 'left', id: 'popover_details_level' },
        React.createElement(PopoverTrigger, null,
            React.createElement(Button, { variant: 'toolbar_button_2', id: 'btn_open_popover_details_level' },
                React.createElement(FontAwesomeIcon, { icon: faFolderTree }))),
        React.createElement(PopoverContent, null,
            React.createElement(PopoverArrow, null),
            React.createElement(PopoverCloseButton, null),
            React.createElement(PopoverHeader, null, applicationContext.t('Banner.ndd')),
            React.createElement(PopoverBody, { style: { maxHeight: '600px', overflowY: 'auto' } },
                React.createElement(React.Fragment, null, (Object.entries(applicationData.data.levelTags).length > 0) ? (React.createElement(React.Fragment, null, mutiple_level_tag_filter)) : (React.createElement(React.Fragment, null,
                    React.createElement(Input, { placeholder: "Pas de filtrage", isDisabled: true })))))));
};
