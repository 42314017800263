// External imports
import React, { useState } from 'react';
import { Box, Button, Input, Select } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { applyChange, diff as getDiff, } from 'deep-diff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// Local imports
import { GetDataFromView, RecomputeViews, FilterView } from './SankeyPlusViews';
import { DefaultLink, DefaultNode, synchronizeNodesandLinksIdOSTyped, complete_sankey_data, convert_data, convert_nodes, convert_links, convert_tags, OSTooltip } from './import/OpenSankey';
export const plus_all_element_to_transform = [
    'Views', 'icon_catalog', 'freeLabels'
];
export const plus_convert_data = (data, DefaultSankeyData) => {
    data.background_image = (data.background_image === undefined) ? '' : data.background_image;
    data.show_background_image = (data.show_background_image === undefined) ? false : data.show_background_image;
    if (!data.labels) {
        data.labels = {};
    }
    if (!data.accordeonToShow.includes('LL') && Object.keys(data.labels).length > 0) {
        data.accordeonToShow.push('LL');
    }
    if (data.labels) {
        Object.values(data.labels).forEach((l) => {
            if (l.title === undefined) {
                let idZdt = Object.keys(data.labels).length;
                const tab_title = Object.values(data.labels).map(zdt => zdt.title);
                while (tab_title.includes('Zone de texte ' + idZdt)) {
                    idZdt = idZdt + 1;
                }
                l.title = 'Zone de texte ' + idZdt;
            }
            if (l.idLabel == undefined) {
                let idZdt = Object.keys(data.labels).length;
                const tab_title = Object.values(data.labels).map(zdt => zdt.idLabel);
                while (tab_title.includes('label_' + idZdt)) {
                    idZdt = idZdt + 1;
                }
                l.idLabel = 'label_' + idZdt;
            }
            // CONVERT TEXT ZONE TRANSPARENT -> OPACITY (0-100)
            if (l.transparent !== undefined) {
                l.opacity = l.transparent ? 0 : 100;
                delete l.transparent;
            }
            if (l.name !== undefined) {
                l.content = l.name;
                if (!l.content.includes('<p')) {
                    if (l.font_uppercase && !l.content.includes('ql-align-center')) {
                        l.content = l.content.toUpperCase();
                    }
                    // if (l.font_size === 40) {
                    //   l.content=l.content?'<h3>'+l.content+'</h3>':''
                    // } else if (l.font_size === 30) {
                    //   l.content=l.content?'<h4>'+l.content+'</h4>':''
                    // } else {
                    //   l.content=l.content?l.content:''
                    // }
                    if (l.font_weight) {
                        l.content = l.content ? '<strong>' + l.content + '</strong>' : '';
                    }
                    if (l.position_horiz === 'gauche') {
                        l.content = l.content ? '<p class="ql-align-left">' + l.content + '</p>' : '';
                    }
                    if (l.position_horiz === 'centre') {
                        l.content = l.content ? '<p class="ql-align-center">' + l.content + '</p>' : '';
                    }
                    if (l.position_horiz === 'droite') {
                        l.content = l.content ? '<p class="ql-align-right">' + l.content + '</p>' : '';
                    }
                }
                // if (l.position_vert === 'haut' ) {
                //  not possible to convert
                // }
                // if (l.position_vert === 'milieu' ) {
                //  not possible to convert
                // }
                // if (l.position_vert === 'bas' ) {
                //  not possible to convert
                // }
                delete l.name;
            }
            const keys = ['idLabel', 'title', 'content', 'opacity', 'color', 'color_border', 'transparent_border', 'label_width', 'label_height', 'x', 'y', 'x_label', 'y_label', 'is_image', 'image_src'];
            const keys_to_remove = [];
            Object.keys(l).forEach(key => {
                if (!keys.includes(key)) {
                    keys_to_remove.push(key);
                }
            });
            keys_to_remove.forEach(key => delete l[key]);
            if (l.is_image === undefined) {
                l.is_image = false;
                l.image_src = '';
            }
        });
    }
    if (data.current_view === undefined) {
        data.current_view = 'none';
    }
    if (!data.view) {
        return;
    }
    if (!data.accordeonToShow.includes('Vis')) {
        data.accordeonToShow.push('Vis');
    }
    const key_view = Object.values(data.view).map(v => v.id);
    if (data.current_view && data.current_view !== 'none' && !key_view.includes(data.current_view)) {
        data.current_view = 'none';
    }
    // Convert old view (when we copied the entire data)
    data.view.forEach((v) => {
        if (v.heredited_attr_from_master === undefined) {
            v.heredited_attr_from_master = [''];
        }
        if (v.view_data.version) {
            complete_sankey_data(v.view_data, DefaultSankeyData, DefaultNode, DefaultLink);
            v.view_data.view = [];
            convert_tags(v.view_data);
            convert_nodes(v.view_data);
            convert_links(v.view_data);
            convert_data(v.view_data, DefaultSankeyData);
            plus_convert_data(v.view_data, DefaultSankeyData);
            if (!v.view_data.accordeonToShow.includes('Vis')) {
                v.view_data.accordeonToShow.push('Vis');
            }
        }
        else if (v.view_data.diff !== undefined) {
            const d_view = GetDataFromView(data, v.id);
            convert_data(d_view, DefaultSankeyData);
            plus_convert_data(d_view, DefaultSankeyData);
            if (!d_view.accordeonToShow.includes('Vis')) {
                d_view.accordeonToShow.push('Vis');
            }
            const copy_data = Object.assign({}, data);
            copy_data.view = [];
            const converted_master = JSON.parse(JSON.stringify(copy_data));
            convert_data(converted_master, DefaultSankeyData);
            plus_convert_data(converted_master, DefaultSankeyData);
            let differences = getDiff(converted_master, d_view);
            differences = (differences !== undefined) ? differences : [];
            differences = FilterView(differences);
            v.view_data = { diff: differences };
        }
    });
    Object.values(data.links).forEach(l => {
        const convert_link = l;
        if (convert_link.gradient) {
            delete convert_link.gradient;
            if (!l.local) {
                l.local = {};
            }
            l.local.gradient = true;
        }
    });
};
export const OSPDiagramSelector = (applicationData) => {
    const { master_data, set_master_data, view, get_default_data } = applicationData;
    const [s_diagram_type, sDiagramType] = useState('File');
    const [view_selected, set_view_selected] = useState('none');
    const OSPDiagramSelectorInner = (t, convert_data, sankey_data, set_sankey_data, prev_sankey_data, set_prev_sankey_data, updateLayout, dataVarToUpdate) => {
        const [file_layout, set_file_layout] = useState(undefined);
        return (React.createElement(Box, null,
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.Transformation.fmep')),
            React.createElement(Box, { layerStyle: 'options_3cols' },
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Button, { variant: s_diagram_type === 'File' ? 'menuconfigpanel_option_button_secondary_activated' : 'menuconfigpanel_option_button_secondary', onClick: () => {
                            sDiagramType('File');
                        } }, t('Menu.other_file')),
                    React.createElement(Button, { variant: s_diagram_type === 'View' ? 'menuconfigpanel_option_button_secondary_activated' : 'menuconfigpanel_option_button_secondary', onClick: () => {
                            sDiagramType('View');
                        } }, t('Menu.view_actual_file'))),
                s_diagram_type === 'File' ? React.createElement(Input, { type: "file", onChange: (evt) => set_file_layout(evt.target.files) }) :
                    React.createElement(Select, { onChange: (evt) => {
                            set_view_selected(evt.target.value);
                        } },
                        React.createElement("option", { key: 'none', value: 'none' }, t('view.actual')),
                        master_data ? master_data.view.map(d => {
                            return React.createElement("option", { key: d.id, value: d.id }, d.nom);
                        }) : React.createElement(React.Fragment, null)),
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                            if (s_diagram_type === 'View') {
                                if (view_selected === 'none') {
                                    // View selected is master data
                                    if (view === 'none') {
                                        // No update of master data by master data
                                        return;
                                    }
                                    //- current view is updated by master data
                                    updateLayout(sankey_data, master_data, dataVarToUpdate.current);
                                    set_sankey_data(Object.assign({}, JSON.parse(JSON.stringify(sankey_data))));
                                }
                                else {
                                    // A view is selected to update either another view or the master data
                                    if (view === view_selected) {
                                        // No update of view by itself
                                        return;
                                    }
                                    const data_view = GetDataFromView(master_data, view_selected);
                                    updateLayout(sankey_data, data_view, dataVarToUpdate.current);
                                    const copy_data = JSON.parse(JSON.stringify(sankey_data));
                                    set_sankey_data(copy_data);
                                    if (view === 'none') {
                                        RecomputeViews(copy_data, master_data, set_master_data);
                                    }
                                }
                                return;
                            }
                            if (file_layout === undefined) {
                                return;
                            }
                            const reader = new FileReader();
                            reader.onload = (() => {
                                return ((e) => {
                                    let result = e.target.result;
                                    if (result) {
                                        result = String(result); //.split('<br>').join('\\\\n')
                                        const new_layout = JSON.parse(result);
                                        convert_data(new_layout, get_default_data);
                                        complete_sankey_data(new_layout, get_default_data, DefaultNode, DefaultLink);
                                        set_prev_sankey_data(JSON.parse(JSON.stringify(sankey_data)));
                                        updateLayout(sankey_data, new_layout, dataVarToUpdate.current, true);
                                        const copy_data = Object.assign({}, JSON.parse(JSON.stringify(sankey_data)));
                                        set_sankey_data(copy_data);
                                        if (view === 'none') {
                                            // if master is being updated we need to set it.
                                            set_master_data(copy_data);
                                        }
                                    }
                                });
                            })();
                            reader.readAsText(file_layout[0]);
                        } }, t('Menu.Transformation.ad')),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                            const copy_data = Object.assign({}, JSON.parse(JSON.stringify(prev_sankey_data)));
                            set_sankey_data(copy_data);
                            if (view === 'none') {
                                // if master is being updated we need to set it.
                                set_master_data(copy_data);
                            }
                        } }, t('Menu.Transformation.undo'))))));
    };
    return OSPDiagramSelectorInner;
};
export const OSPTransformationElements = ({ applicationData, applicationContext, ComponentUpdater }) => {
    const { dataVarToUpdate } = applicationData;
    // Variable used to check if we are in a view, if so we disabled the possibility to check Views in the menu transfromation
    const is_master = applicationData.view === 'none';
    const [forceUpdate, setForceUpdate] = useState(false);
    const { updateComponentBtnUpdateLayout } = ComponentUpdater;
    updateComponentBtnUpdateLayout.current = () => setForceUpdate(!forceUpdate);
    if (!applicationContext.has_open_sankey_plus) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, applicationContext.t('Menu.Transformation.freeLabels')),
            React.createElement(Box, { layerStyle: 'options_4cols' },
                React.createElement(Button, { variant: dataVarToUpdate.current.includes('freeLabels') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                        if (!dataVarToUpdate.current.includes('freeLabels')) {
                            dataVarToUpdate.current.push('freeLabels');
                            setForceUpdate(!forceUpdate);
                        }
                        else {
                            dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('freeLabels'), 1);
                            setForceUpdate(!forceUpdate);
                        }
                    } }, dataVarToUpdate.current.includes('freeLabels') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark })))),
        React.createElement(OSTooltip, { label: !is_master ? applicationContext.t('Menu.Transformation.disabled_view') : '' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, applicationContext.t('Menu.Transformation.Views')),
                React.createElement(Box, { layerStyle: 'options_4cols' },
                    React.createElement(Button, { isDisabled: !is_master, variant: is_master && dataVarToUpdate.current.includes('Views') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                            if (!dataVarToUpdate.current.includes('Views')) {
                                dataVarToUpdate.current.push('Views');
                                setForceUpdate(!forceUpdate);
                            }
                            else {
                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('Views'), 1);
                                setForceUpdate(!forceUpdate);
                            }
                        } }, is_master && dataVarToUpdate.current.includes('Views') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))),
        React.createElement(OSTooltip, { label: applicationContext.t('Menu.Transformation.list_icon_tooltip') },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, applicationContext.t('Menu.Transformation.list_icon')),
                React.createElement(Box, { layerStyle: 'options_4cols' },
                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('icon_catalog') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                            if (!dataVarToUpdate.current.includes('icon_catalog')) {
                                dataVarToUpdate.current.push('icon_catalog');
                                setForceUpdate(!forceUpdate);
                            }
                            else {
                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('icon_catalog'), 1);
                                setForceUpdate(!forceUpdate);
                            }
                        } }, dataVarToUpdate.current.includes('icon_catalog') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))));
};
export const plus_sankey_layout = (data, new_layout, mode) => {
    if (mode.includes('freeLabels') && new_layout.labels) {
        if (!data.labels) {
            data.labels = {};
        }
        const differences = getDiff(data.labels, new_layout.labels);
        if (differences) {
            differences.forEach((difference) => applyChange(data.labels, {}, difference));
        }
    }
    if (mode.includes('Views') && new_layout.view) {
        if (new_layout.view) {
            if (!(data.view)) {
                data.view = [];
            }
            new_layout.view.forEach((view_of_new_layout) => {
                const view_data = JSON.parse(JSON.stringify(new_layout));
                if (data.view.filter(d_view => d_view.nom === view_of_new_layout.nom).length === 0) {
                    if (view_of_new_layout.view_data.version) {
                        // Views are copied identical to what they were
                        view_of_new_layout.heredited_attr_from_master = [''];
                        // nodeId and linkId must be synchronized with new master
                        synchronizeNodesandLinksIdOSTyped(view_of_new_layout.view_data, data);
                        data.view.push(view_of_new_layout);
                    }
                    else if (view_of_new_layout.view_data.diff !== undefined) {
                        view_of_new_layout.view_data
                            .diff
                            .forEach((difference) => applyChange(view_data, {}, difference));
                        // nodeId and linkId must be synchronized with new master
                        synchronizeNodesandLinksIdOSTyped(view_data, data);
                        const data_view_diff = getDiff(data, view_data);
                        view_of_new_layout.view_data.diff = data_view_diff.filter((d) => !(d.path.includes('view')));
                        // Views are copied identical to what they were
                        view_of_new_layout.heredited_attr_from_master = [''];
                        data.view.push(view_of_new_layout);
                    }
                }
            });
        }
    }
    if (mode.includes('icon_catalog')) {
        // Import catalog of icon
        Object.entries(new_layout.icon_catalog).filter(icon => icon[0] && icon[1]).forEach(icon => {
            data.icon_catalog[icon[0]] = icon[1];
        });
    }
    if (mode.includes('attrNode')) {
        Object.entries(data.nodes).forEach(([key, node]) => {
            const layoutNode = new_layout.nodes[key];
            if (!layoutNode) {
                return;
            }
            // Add icon fromm imported layout if it has all the attribut
            if (layoutNode.iconVisible !== undefined && layoutNode.iconColor && layoutNode.iconName) {
                node.iconVisible = layoutNode.iconVisible;
                node.iconColor = layoutNode.iconColor;
                node.iconName = layoutNode.iconName;
            }
            // Add ForeignObject from imported layout if it has all the attribut
            if (layoutNode.has_FO !== undefined && layoutNode.is_FO_raw && layoutNode.FO_content) {
                node.has_FO = layoutNode.has_FO;
                node.is_FO_raw = layoutNode.is_FO_raw;
                node.FO_content = layoutNode.FO_content;
            }
            // Add ForeignObject from imported layout if it has all the attribut
            if (layoutNode.image_src !== undefined && layoutNode.is_image) {
                node.image_src = layoutNode.image_src;
                node.is_image = layoutNode.is_image;
            }
        });
    }
};
