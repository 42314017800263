import React from 'react';
// Imported libs
import { Box, Table, Tbody, Td, Th, Thead, Tr, } from '@chakra-ui/react';
export const OSPShortcut = (t) => {
    return (React.createElement(Box, { display: 'grid', width: '100%', justifySelf: 'center' },
        React.createElement(Table, { variant: 'table_welcome_buttons' },
            React.createElement(Thead, null,
                React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_OSP'))),
            React.createElement(Tbody, null,
                React.createElement(Tr, null,
                    React.createElement(Td, null, t('Menu.rcc_osp_cs_bold')),
                    React.createElement(Td, null, t('Menu.rcc_osp_cs'))),
                React.createElement(Tr, null,
                    React.createElement(Td, null, t('Menu.rcc_osp_ctrl_czdt_bold')),
                    React.createElement(Td, null, t('Menu.rcc_osp_ctrl_czdt'))),
                React.createElement(Tr, null,
                    React.createElement(Td, null, t('Menu.rcc_F7_bold')),
                    React.createElement(Td, null, t('Menu.rcc_F7'))),
                React.createElement(Tr, null,
                    React.createElement(Td, null, t('Menu.rcc_F8_bold')),
                    React.createElement(Td, null, t('Menu.rcc_F8'))),
                React.createElement(Tr, null,
                    React.createElement(Td, null, t('Menu.rcc_F9_bold')),
                    React.createElement(Td, null, t('Menu.rcc_F9')))))));
};
