// Standard libs
import React, { useRef, useState } from 'react';
import * as d3 from 'd3';
import LZString from 'lz-string';
import { diff as getDiff, applyChange } from 'deep-diff';
import { FaArrowDown, FaArrowUp, FaMinus, FaCheck } from 'react-icons/fa';
// Imported libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faListCheck, faXmark, faExclamation, faFloppyDisk, faHome, faCaretSquareLeft, faCaretSquareRight, faPlus, faCopy, faMinus } from '@fortawesome/free-solid-svg-icons';
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Checkbox, Select, Input, InputGroup, Table, Tbody, Td, Th, Thead, Tr, Button, Modal, ModalFooter, ModalHeader, ModalBody, ModalContent, Tag, ModalCloseButton } from '@chakra-ui/react';
import { OSTooltip, preferenceCheck, updateLayoutOSTyped } from './import/OpenSankey';
import { deleteGLabel } from './SankeyPlusLabels';
export const getSetDiagramFunc = (set_master_data, set_view, DefaultSankeyData) => {
    return (the_diagram, set_data, convert_data) => {
        const sous_filieres = window.sankey.sous_filieres;
        const new_data = JSON.parse(JSON.stringify(window.sankey[sous_filieres[the_diagram]]));
        convert_data(new_data, DefaultSankeyData);
        window.sankey.diagram = the_diagram;
        d3.select(' .opensankey #svg').on('.zoom', null);
        if (window.SankeyToolsStatic && new_data.view.length > 0) {
            set_master_data(new_data);
            set_view(new_data.view[0].id);
            set_data(GetDataFromView(new_data, new_data.view[0].id));
        }
        else {
            set_master_data(undefined);
            set_data(new_data);
            set_view('none');
        }
    };
};
export const setValue = (dataTags, v_target, v_source, depth) => {
    const dataTag = Object.values(dataTags)[depth];
    const listKey = Object.keys(dataTag.tags);
    for (const i in listKey) {
        if (depth === dataTags.length - 1) {
            v_target[listKey[i]] = v_source[listKey[i]];
        }
        else {
            if (v_target[listKey[i]] === undefined) {
                v_target[listKey[i]] = {};
            }
            setValue(dataTags, v_target[listKey[i]], v_source[listKey[i]], depth + 1);
        }
    }
};
export const GetDataFromView = (master_data, id_view_to_see) => {
    // Copy master data
    if (!master_data) {
        alert('sankey master undefined');
        return undefined;
    }
    const copy_master_data = JSON.parse(JSON.stringify(master_data));
    copy_master_data.view = [];
    let data_init = JSON.parse(JSON.stringify(copy_master_data));
    // Get the difference from the view
    if (master_data.view.filter(v => v.id === id_view_to_see).length === 0) {
        alert('view not found');
        return data_init;
    }
    const view_object = master_data.view.filter(v => v.id === id_view_to_see)[0];
    if (view_object.view_data.diff) {
        const diff_view = view_object.view_data.diff;
        if (!diff_view) {
            return data_init;
        }
        diff_view.forEach((d) => applyChange(data_init, {}, d));
    }
    else {
        data_init = view_object.view_data;
    }
    updateLayoutOSTyped(data_init, master_data, view_object.heredited_attr_from_master);
    // updateLayout(data_init,master_data,view_object.heredited_attr_from_master)
    return data_init;
};
export const FilterView = (pre_diff) => {
    return JSON
        .parse(JSON.stringify(pre_diff))
        .filter((d) => {
        return !d.path.includes('view');
    })
        .map((d) => {
        if (d.kind === 'D') {
            delete d.lhs;
        }
        if (d.kind === 'A' && d.item.kind === 'D') {
            delete d.item.lhs;
        }
        if (d.kind === 'E') {
            delete d.lhs;
        }
        return d;
    });
};
export const RecomputeViews = (new_master_data, prev_master_data, set_master_data) => {
    if (prev_master_data) {
        new_master_data.view.forEach(current_v => {
            if (prev_master_data.view.filter(v => v.id === current_v.id).length === 0) {
                return;
            }
            const data_view = GetDataFromView(prev_master_data, current_v.id);
            if (current_v.view_data.version) {
                current_v.view_data = data_view;
            }
            else {
                let difference = getDiff(new_master_data, data_view);
                difference = (difference !== undefined) ? difference : [];
                difference = FilterView(difference);
                if (difference.length > 0) {
                    current_v.view_data.diff = difference;
                }
            }
        });
    }
    // master data is now set
    set_master_data(Object.assign({}, JSON.parse(JSON.stringify(new_master_data))));
};
export const OSPKeyHandler = (applicationContext, e, applicationData, applicationState, dict_hook_ref_setter_show_dialog_components, reDrawOSPLabels, ComponentUpdater) => {
    var _a, _b, _c, _d;
    const { t, has_open_sankey_plus } = applicationContext;
    const { data, set_data, master_data, set_master_data, view, set_view, set_view_not_saved } = applicationData;
    const { multi_selected_label } = applicationState;
    const { updateMenus } = ComponentUpdater;
    const is_master = applicationData.view === 'none';
    if (e.key === 'a' && e.ctrlKey) {
        e.preventDefault();
        multi_selected_label.current = Object.values(data.labels);
        reDrawOSPLabels(multi_selected_label.current);
        ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
    }
    // Clone current data,if its a view clone the view
    if (has_open_sankey_plus && e.key === 'x' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (is_master) {
            // If we do a control+X while we are on is_master data, we create view empty
            // data is is_master data and master_data might not be  se
            const new_ind = 'view_' + String(new Date().getTime());
            // const copy_data = {diff:[]}
            const copy_data = JSON.parse(JSON.stringify(data));
            const new_master_data = data;
            new_master_data.view.push({
                id: new_ind,
                view_data: copy_data,
                nom: 'data_' + new_ind,
                details: '',
                heredited_attr_from_master: []
            });
            RecomputeViews(new_master_data, master_data, set_master_data);
            // is_master data is now set
            // at this stage data is a view and is equal with is_master data
            set_view(new_ind);
            new_master_data.current_view = new_ind;
            set_master_data(Object.assign({}, new_master_data));
            set_data(Object.assign({}, copy_data));
        }
        else {
            const new_ind = 'view_' + String(new Date().getTime());
            const current_view_object = master_data.view.filter(v => v.id === view)[0];
            const copy_data = JSON.parse(JSON.stringify(current_view_object.view_data));
            master_data.view.push({
                id: new_ind,
                view_data: copy_data,
                nom: t('view.prefix_copy') + ' ' + current_view_object.nom,
                details: '',
                heredited_attr_from_master: []
            });
            // is_master data is now set
            master_data.current_view = new_ind;
            set_view(new_ind);
            set_master_data(Object.assign({}, master_data));
            // get view data & set_data to avoid synchronisation problem
            const n_data = GetDataFromView(master_data, new_ind);
            if (n_data) {
                set_data(JSON.parse(JSON.stringify(n_data)));
            }
        }
    }
    if (e.key === 's' && e.ctrlKey && !e.shiftKey) {
        e.preventDefault();
        applicationData.function_on_wait.current = () => {
            ComponentUpdater.updateComponenSaveInCache.current(false);
            if (view !== 'none') {
                // If we do a control+S while we are on a view, we save the difference between the data we are handling
                // and the is_master data. These difference are the saved the view we are currently on
                // Get difference between master_data and the current data then save it in view
                let difference = getDiff(master_data, data);
                difference = (difference !== undefined) ? difference : [];
                difference = difference.filter((d) => !(d.path.includes('view')));
                difference = FilterView(difference);
                // Check wich format of the view is better optimized for memory storage
                const raw_is_smaller_than_diff = JSON.stringify(data).length < JSON.stringify(difference).length;
                master_data.view.filter(v => v.id === view)[0].view_data = raw_is_smaller_than_diff ? JSON.parse(JSON.stringify(data)) : { diff: difference };
                // Save is_master data with the view we are currently working on updated
                set_master_data(Object.assign({}, master_data));
                // Save master_data data in localStorage
                localStorage.setItem('data', LZString.compress(JSON.stringify(master_data)));
            }
            else {
                // Save current data (wich is master_data)
                localStorage.setItem('data', LZString.compress(JSON.stringify(data)));
                localStorage.setItem('last_save', 'true');
            }
            ComponentUpdater.updateComponenSaveInCache.current(true);
        };
        //dict_hook_ref_setter_show_dialog_components.ref_lauchToast.current()
    }
    // Changing view to is_master
    if (!is_master && e.key === 'F7') {
        // Check if there is unsaved change before we switch view
        // If there is, we open the modal to know if the user want to save the current unsaved changes befor eswitching view
        let saved = true;
        if (view !== 'none' && has_open_sankey_plus) {
            const diff = CheckCurrentViewSaved(master_data, data, view);
            if (diff.length > 0 && !window.SankeyToolsStatic) {
                saved = false;
                set_view_not_saved(view);
                set_view('none');
            }
        }
        if (saved) {
            set_view('none');
            set_data(JSON.parse(JSON.stringify(master_data)));
        }
    }
    // Changing view to next or previous
    if (['F8', 'F9'].includes(e.key)) {
        if (e.key === 'F8') {
            // going backward
            //Cherche la position de la vue sélectionné dans le tableau de vue
            let ind = -1;
            master_data.view.map((v, i) => {
                ind = (v.id === view) ? i : ind;
            });
            if (ind === -1) {
                ind = 1;
            }
            else if (ind === 0) {
                ind = Object.keys(master_data.view).length;
            }
            const data_view = GetDataFromView(master_data, master_data.view[ind - 1].id);
            // Check if there is unsaved change before we switch view
            // If there is, we open the modal to know if the user want to save the current unsaved changes befor eswitching view
            let saved = true;
            if (view !== 'none' && has_open_sankey_plus) {
                const diff = CheckCurrentViewSaved(master_data, data, view);
                if (diff.length > 0 && !window.SankeyToolsStatic) {
                    saved = false;
                    set_view_not_saved(view);
                    set_view(master_data.view[ind - 1].id);
                }
            }
            if (saved) {
                set_data(Object.assign({}, data_view));
                set_view(master_data.view[ind - 1].id);
            }
        }
        else if (e.key === 'F9') {
            let new_master_data;
            if (is_master) {
                new_master_data = data;
                RecomputeViews(new_master_data, master_data, set_master_data);
            }
            else {
                new_master_data = master_data;
            }
            //Cherche la position de la vue sélectionné dans le tableau de vue
            let ind = -1;
            new_master_data.view.map((v, i) => {
                ind = (v.id === view) ? i : ind;
            });
            //si la vue est trouvé alors on lance l'animation entre cette vue et la suivante
            if (ind === Object.keys(new_master_data.view).length - 1) {
                ind = -1;
            }
            else if (ind === -1) {
                ind = -1;
            }
            // Check if there is unsaved change before we switch view
            // If there is, we open the modal to know if the user want to save the current unsaved changes befor eswitching view
            if (view === 'none') {
                new_master_data.current_view = master_data.view[ind + 1].id;
                set_master_data(new_master_data);
            }
            let saved = true;
            if (view !== 'none' && has_open_sankey_plus) {
                const diff = CheckCurrentViewSaved(new_master_data, data, view);
                if (diff.length > 0 && !window.SankeyToolsStatic) {
                    saved = false;
                    set_view_not_saved(view);
                    set_view(master_data.view[ind + 1].id);
                }
            }
            const data_view = GetDataFromView(new_master_data, new_master_data.view[ind + 1].id);
            if (saved) {
                set_data(JSON.parse(JSON.stringify(data_view)));
                set_view(new_master_data.view[ind + 1].id);
            }
            //}
        }
    }
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key) && ((((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'INPUT') ? d3.select(document.activeElement).attr('value') === 'menuConfigButton' : true) && (!((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.className.includes('ql-editor')))) {
        // Deplace les zdt sélectionné avec les flèches du clavier, cependant ne ce déplace pas si jamais on utilise les flèches pour dépalcer le curseur dans un input
        // (exemples : le input de la largeur minimal d'un noeud)
        e.preventDefault();
        if (e.key === 'ArrowUp') {
            Object.values(data.labels).filter(f => multi_selected_label.current.map(d => {
                if (d !== undefined) {
                    return d.idLabel;
                }
            }).includes(f.idLabel)).map(d => {
                d.y = d.y - data.grid_square_size;
                let y_max = 0;
                Object.values(data.labels).map(d => {
                    y_max = (d.y > y_max) ? d.y : y_max;
                });
                //Diminue hauteur svg si le noeud est près du bord
                if (y_max < data.height - 100 && data.height - 100 >= window.innerHeight) {
                    data.height -= 90;
                }
            });
        }
        else if (e.key === 'ArrowDown') {
            Object.values(data.labels).filter(f => multi_selected_label.current.map(d => {
                if (d !== undefined) {
                    return d.idLabel;
                }
            }).includes(f.idLabel)).map(d => {
                d.y = d.y + data.grid_square_size;
                //Augumente hauteur svg si le noeud est près du bord
                if (d.y > data.height - 100) {
                    data.height += 100;
                }
            });
        }
        else if (e.key === 'ArrowLeft') {
            Object.values(data.labels).filter(f => multi_selected_label.current.map(d => {
                if (d !== undefined) {
                    return d.idLabel;
                }
            }).includes(f.idLabel)).map(d => {
                d.x = d.x - data.grid_square_size;
                //Diminue largeur svg si le noeud est près du bord
                if (d.x < data.width - 100 && data.width - 100 >= window.innerWidth - 40) {
                    data.width -= 50;
                }
            });
        }
        else if (e.key === 'ArrowRight') {
            Object.values(data.labels).filter(f => multi_selected_label.current.map(d => {
                if (d !== undefined) {
                    return d.idLabel;
                }
            }).includes(f.idLabel)).map(d => {
                d.x = d.x + data.grid_square_size;
                //Augumente largeur svg si le noeud est près du bord
                if (d.x > data.width - 100) {
                    data.width += 100;
                }
            });
        }
        reDrawOSPLabels(multi_selected_label.current);
    }
    // Add deselection of all selected zdt
    if (e.key === 'Escape') {
        multi_selected_label.current.forEach(l => {
            d3.select('#' + l.idLabel + ' rect').attr('stroke-width', 1);
        });
        multi_selected_label.current = [];
    }
    if (e.key === 'Delete' && (!((_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.className.includes('ql-editor')))) {
        if (((_d = document.activeElement) === null || _d === void 0 ? void 0 : _d.tagName) !== 'INPUT' || d3.select(document.activeElement).attr('value') === 'menuConfigButton') {
            deleteGLabel(updateMenus, multi_selected_label.current);
            data.labels = Object.fromEntries(Object.entries(data.labels).filter(d => !multi_selected_label.current.map(l => l.idLabel).includes(d[0])));
            multi_selected_label.current = [];
            ComponentUpdater.updateComponentMenuConfigZdt.current.forEach(f => f());
        }
    }
};
export const SelecteurView = ({ applicationData, applicationState, t, set_view_not_saved, connected }) => {
    const { data, set_data, master_data, set_master_data, view, set_view } = applicationData;
    const { multi_selected_nodes, multi_selected_links, multi_selected_label } = applicationState;
    let vname = '';
    if ((master_data && master_data.current_view && master_data.current_view !== 'none' && master_data.view.length > 0)) {
        if (master_data.view.filter(v => v.id === master_data.current_view).length > 0) {
            vname = master_data.view.filter(v => v.id === master_data.current_view)[0].nom;
        }
        else {
            vname = '';
        }
    }
    const [s_value_editor_name_view, sValueEditorNameView] = useState(vname);
    if (s_value_editor_name_view == '' && vname != '') {
        sValueEditorNameView(vname);
    }
    const [s_select_or_edit, sSelectOrEdit] = useState('select');
    applicationState.r_setter_value_editor_name_view.current = sValueEditorNameView;
    const selecteur = React.createElement(Select, { variant: 'menuconfigpanel_option_select', onDoubleClick: () => {
            if (connected && master_data && master_data.current_view && master_data.current_view !== 'none') {
                sSelectOrEdit('edit');
                sValueEditorNameView(master_data.view.filter(v => v.id === master_data.current_view)[0].nom);
            }
        }, onChange: (evt) => {
            multi_selected_nodes.current = [];
            multi_selected_links.current = [];
            multi_selected_label.current = [];
            // Depending on the value selected we :
            //  - If we select a view :Get a modified version of master data according to the modifications saved in the view selected
            //      and save original master data in a variable that the view can't modify
            //  - If we select master('none'): Get the data we are workinkg on to be the master data
            // Verify if we saved the view before changing the view
            // If not, we display a modal that will warn the user with the possibility to save before exit
            let saved = true;
            if (view !== 'none') {
                const difference = CheckCurrentViewSaved(master_data, data, view);
                if (difference.length !== 0 && !window.SankeyToolsStatic) {
                    saved = false;
                    set_view_not_saved(view);
                    set_view(evt.target.value);
                }
            }
            if (saved) {
                if (evt.target.value === '') {
                    return;
                }
                else if (evt.target.value !== 'none') {
                    let new_master_data;
                    if (view === 'none') {
                        new_master_data = JSON.parse(JSON.stringify(data));
                        RecomputeViews(new_master_data, master_data, set_master_data);
                    }
                    else {
                        new_master_data = JSON.parse(JSON.stringify(master_data));
                    }
                    const data_view = GetDataFromView(new_master_data, evt.target.value);
                    set_data(JSON.parse(JSON.stringify(data_view)));
                    new_master_data.current_view = evt.target.value;
                    set_master_data(JSON.parse(JSON.stringify(new_master_data)));
                    set_view(evt.target.value);
                }
                else if (evt.target.value === 'none') {
                    set_view(evt.target.value);
                    set_data(JSON.parse(JSON.stringify(master_data)));
                }
            }
            sValueEditorNameView(master_data.view.filter(v => v.id === master_data.current_view)[0].nom);
        }, value: view },
        React.createElement("option", { hidden: true, value: 'none' }, t('view.actual')),
        master_data ? master_data.view.map(d => {
            return React.createElement("option", { key: d.id, value: d.id }, d.nom);
        }) : React.createElement(React.Fragment, null));
    const editeur_name = React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: s_value_editor_name_view, onChange: (evt) => {
            sValueEditorNameView(evt.target.value);
        }, onBlur: () => {
            master_data.view.filter(v => v.id === view)[0].nom = s_value_editor_name_view;
            set_master_data(Object.assign({}, master_data));
            sSelectOrEdit('select');
        } });
    return connected && s_select_or_edit === 'edit' ? editeur_name : selecteur;
};
export const viewsAccordion = (applicationData, t, is_activated, convert_data, DefaultSankeyData, view_selector) => {
    const { data, set_data, master_data, set_master_data, view, set_view } = applicationData;
    const _load_json = useRef(null);
    // Popover used to select a view or master we want to take the layout from. (color,font-size,position,...)
    return React.createElement(React.Fragment, null,
        React.createElement(AccordionItem
        // id='Visualisation'
        , { 
            // id='Visualisation'
            style: { 'display': (data.accordeonToShow.includes('Vis')) ? 'initial' : 'none' } },
            React.createElement(AccordionButton, { onClick: () => {
                    const scroll_x = window.scrollX;
                    const scroll_y = window.scrollY;
                    setTimeout(() => {
                        var _a;
                        (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                    }, 50);
                } },
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfig_entry' }, t('view.storytelling')),
                React.createElement(Tag, { colorScheme: 'teel' }, "Beta"),
                React.createElement(AccordionIcon, null)),
            React.createElement(AccordionPanel, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('view.select')),
                        React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' }, view_selector)),
                    React.createElement(Table, { size: 'sm' },
                        React.createElement(Thead, null,
                            React.createElement(Tr, null,
                                React.createElement(Th, null, t('view.name')),
                                React.createElement(Th, null, "Position"),
                                React.createElement(Th, null, t('view.delete')))),
                        React.createElement(Tbody, null, master_data ? Object.values(master_data.view).map(d => {
                            return (React.createElement(Tr, { style: { 'border': (d.id === view) ? '2px solid #5a9282' : 'none' } },
                                React.createElement(Td, null,
                                    React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: d.nom, isDisabled: !is_activated, onChange: evt => {
                                            // Change the name of the view
                                            master_data.view.filter(v => v.id === d.id)[0].nom = evt.target.value;
                                            set_master_data(Object.assign({}, master_data));
                                        } })),
                                React.createElement(Td, null,
                                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_in_table', isDisabled: !is_activated, onClick: () => {
                                            let ind = -1;
                                            master_data.view.map((v, i) => {
                                                ind = (v.id === d.id) ? i : ind;
                                            });
                                            const toShift = master_data.view[ind];
                                            master_data.view.splice(ind, 1);
                                            master_data.view.splice(ind - 1, 0, toShift);
                                            set_master_data(JSON.parse(JSON.stringify(master_data)));
                                            set_data(JSON.parse(JSON.stringify(data)));
                                        } },
                                        React.createElement(FaArrowUp, null)),
                                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_in_table', isDisabled: !is_activated, onClick: () => {
                                            let ind = -1;
                                            master_data.view.map((v, i) => {
                                                ind = (v.id === d.id) ? i : ind;
                                            });
                                            const toShift = master_data.view[ind];
                                            master_data.view.splice(ind, 1);
                                            master_data.view.splice(ind + 1, 0, toShift);
                                            set_master_data(JSON.parse(JSON.stringify(master_data)));
                                            set_data(JSON.parse(JSON.stringify(data)));
                                        } },
                                        React.createElement(FaArrowDown, null))),
                                React.createElement(Td, null,
                                    React.createElement(Button, { variant: 'menuconfigpanel_del_button_in_table', isDisabled: !is_activated, onClick: 
                                        // Delete the view
                                        () => {
                                            let ind = -1;
                                            master_data.view.map((v, i) => {
                                                ind = (v.id === d.id) ? i : ind;
                                            });
                                            master_data.view.splice(ind, 1);
                                            // If master is not a catalog & we delete the current view then we go to master
                                            // If master is a catalog and the catalog of view is empty then we got to master
                                            if ((master_data.current_view === view && master_data.is_catalog === false) || (master_data.view.length === 0 && master_data.is_catalog === true)) {
                                                set_view('none');
                                                set_data(JSON.parse(JSON.stringify(master_data)));
                                            }
                                            else if (master_data.is_catalog && master_data.view.length > 0) {
                                                // If master is a catalog and the catalog is not empty then we got to the first view
                                                set_view(master_data.view[0].id);
                                                const tmp = GetDataFromView(master_data, master_data.view[0].id);
                                                if (!tmp.accordeonToShow.includes('Vis')) {
                                                    tmp.accordeonToShow.push('Vis');
                                                }
                                                set_data(JSON.parse(JSON.stringify(tmp)));
                                            }
                                            if (master_data.view.length === 0) {
                                                master_data.is_catalog = false;
                                                set_data(JSON.parse(JSON.stringify(master_data)));
                                            }
                                            set_master_data(JSON.parse(JSON.stringify(master_data)));
                                        } },
                                        React.createElement(FaMinus, null)))));
                        }) : React.createElement(React.Fragment, null)))))),
        React.createElement(Input, { type: "file", ref: _load_json, style: { display: 'none' }, onChange: (evt) => {
                const files = evt.target.files;
                const reader = new FileReader();
                reader.onload = (() => {
                    return (e) => {
                        const result = String(e.target.result);
                        const result_data = JSON.parse(result);
                        let ind = -1;
                        master_data.view.map((v, i) => {
                            var _a;
                            ind = (v.id === ((_a = _load_json.current) === null || _a === void 0 ? void 0 : _a.id)) ? i : ind;
                        });
                        const cur_view = master_data.view[ind];
                        const imported_data = JSON.parse(JSON.stringify(result_data));
                        imported_data.view = [];
                        convert_data(imported_data, DefaultSankeyData);
                        let difference = getDiff(master_data, imported_data);
                        difference = JSON.parse(JSON.stringify((difference !== undefined) ?
                            difference : []));
                        difference = difference.filter((d) => !(d.path.includes('view')));
                        cur_view.view_data = { diff: difference };
                        cur_view.nom = (files[0].name).replace('.json', '');
                        set_master_data(JSON.parse(JSON.stringify(master_data)));
                        set_data(JSON.parse(JSON.stringify(imported_data)));
                        set_view(cur_view.id);
                    };
                })();
                reader.readAsText(files[0]);
            } }));
};
// Function to check if the current data of the view is unsaved
// We compare the differences saved in the master_data with the current changement of the view
export const CheckCurrentViewSaved = (master_data, data, view) => {
    const view_data = GetDataFromView(master_data, view);
    //const data=JSON.parse(JSON.stringify(data))
    //const updated_diff=JSON.parse(JSON.stringify(original_diff))
    //updateLayout(data_updated_layout,master_data,master_data.view.filter(v=>v.id===view)[0].heredited_attr_from_master)
    //updateLayout(updated_diff,master_data,master_data.view.filter(v=>v.id===view)[0].heredited_attr_from_master)
    let difference = getDiff(view_data, data);
    difference = (difference !== undefined) ? difference : [];
    difference = difference.filter((d) => {
        // Ne prend pas en compte les modif de vue, de la largeur ou hauteur du sankey
        return ((d.path[0] !== 'current_view') &&
            (d.path[0] !== 'view') &&
            (d.path[0] !== 'width') &&
            (d.path[0] !== 'height') &&
            (d.path[0] !== 'linkZIndex') &&
            !(d.path.length === 4 && d.path[3] === 'vert_shift'));
    });
    console.log(difference);
    return difference;
};
// Fucntion that return a toolbar to navigate,create or modify view, it contain :
// - a button to return to master data
// - a button to create a view if we are currently on master data
// - 2 button to navigate in the list of view
// - a dropdown to directly select the view we want to display (or select master data)
// Then if we are in a view there is additionnal button
// - a button to choose variable of the view that get their value from master
// - a button to clone the actual view
// a button that appear if the view is a unitary view and the unitary node of the view has the tag 'secteur' from the nodeTag 'Type de noeud'
export const OSPBannerView = ({ applicationData, applicationContext, dict_hook_ref_setter_show_dialog_components, convert_data, view_selector }) => {
    const { data, set_data, master_data, set_master_data, get_default_data, view, set_view } = applicationData;
    const { ref_setter_show_modal_transparent_view_attr } = dict_hook_ref_setter_show_dialog_components;
    const m_d = master_data ? master_data : data;
    const _load_json_catalog = useRef(null);
    const { t, has_open_sankey_plus } = applicationContext;
    const has_views = master_data ? master_data.view.length > 0 : false;
    const next_button_disabled = m_d.view && (m_d.view.map(d => d.id).indexOf(view) === m_d.view.length - 1);
    const prev_button_disabled = m_d.view && (m_d.view.map(d => d.id).indexOf(view) === 0 || view === 'none');
    const buttonCreateView = React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus) ?
            (t('Menu.sankeyOSPDisabled')) :
            t('view.tooltips.buttonCreateView') },
        React.createElement(Box, null,
            React.createElement(Button, { variant: 'menutop_button', isDisabled: !has_open_sankey_plus, onClick: () => {
                    const ev = document;
                    const t = new KeyboardEvent('keydown', { key: 'x', ctrlKey: true });
                    if (ev.onkeydown) {
                        ev.onkeydown(t);
                    }
                } },
                React.createElement(Box, { layerStyle: 'menutop_button_style' },
                    React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                        React.createElement(FontAwesomeIcon, { style: {
                                'height': '2rem',
                                'width': '3rem',
                                // 'opacity': (!has_open_sankey_plus) ? '0.6' : '1'
                            }, icon: faPlus }),
                        !has_open_sankey_plus ?
                            React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                    'fontSize': '1em',
                                    'position': 'absolute',
                                    'right': '0.1em',
                                    'bottom': '0em',
                                    'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                } })
                            : React.createElement(React.Fragment, null)),
                    React.createElement(Box, { gridRow: "2" }, t('Menu.addView'))))));
    // TO DELETE WHEN UNITARY SANKEY WILL BE MERGE IN SANKEYPLUS
    const special_cast_for_unit_sankey = data;
    const button_heredited_attr_from_master = !(special_cast_for_unit_sankey.unitary_node && special_cast_for_unit_sankey.unitary_node.length > 0) ? React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus) ? (t('Menu.sankeyOSPDisabled')) : t('view.tooltips.buttonCloneMasterAttrView') },
        React.createElement(Box, null,
            React.createElement(Button, { variant: 'menutop_button', isDisabled: !has_open_sankey_plus, onClick: () => {
                    ref_setter_show_modal_transparent_view_attr.current(true);
                } },
                React.createElement(Box, { layerStyle: 'menutop_button_style' },
                    React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                        React.createElement(FontAwesomeIcon, { style: {
                                'height': '2rem',
                                'width': '3rem',
                                // 'opacity': (!has_open_sankey_plus) ? '0.6' : '1'
                            }, icon: faListCheck }),
                        !has_open_sankey_plus ?
                            React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                    'fontSize': '1em',
                                    'position': 'absolute',
                                    'right': '0.1em',
                                    'bottom': '0em',
                                    'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                } })
                            : React.createElement(React.Fragment, null)),
                    React.createElement(Box, { gridRow: "2" }, t('view.keep_master_var')))))) : React.createElement(React.Fragment, null);
    const is_master = applicationData.view === 'none';
    const create_data_catalog = React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus || !is_master) ? (t('Menu.sankeyOSPDisabled')) : t('view.tooltips.catalog_data') },
        React.createElement(Box, null,
            React.createElement(Button, { variant: 'menutop_button', isDisabled: !has_open_sankey_plus || !is_master, onClick: () => {
                    if (_load_json_catalog.current) {
                        _load_json_catalog.current.name = '';
                        _load_json_catalog.current.click();
                    }
                } },
                React.createElement(Box, { layerStyle: 'menutop_button_style' },
                    React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                        React.createElement(FontAwesomeIcon, { style: {
                                'height': '2rem',
                                'width': '3rem',
                                // 'opacity': (!has_open_sankey_plus) ? '0.6' : '1'
                            }, icon: faCopy }),
                        !has_open_sankey_plus ?
                            React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                    'fontSize': '1em',
                                    'position': 'absolute',
                                    'right': '0.1em',
                                    'bottom': '0em',
                                    'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                } })
                            : React.createElement(React.Fragment, null)),
                    React.createElement(Box, { gridRow: "2" }, t('view.catalog'))))));
    const button_delete_actual_view = React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus) ? (t('Menu.sankeyOSPDisabled')) : t('view.tooltips.button_delete_actual_view') },
        React.createElement(Box, null,
            React.createElement(Button, { variant: 'menutop_button', isDisabled: !has_open_sankey_plus, onClick: 
                // Delete the view
                () => {
                    let ind = -1;
                    master_data.view.map((v, i) => {
                        ind = (v.id === view) ? i : ind;
                    });
                    master_data.view.splice(ind, 1);
                    // If master is not a catalog & we delete the current view then we go to master
                    // If master is a catalog and the catalog of view is empty then we got to master
                    if ((master_data.current_view === view && master_data.is_catalog === false) || (master_data.view.length === 0 && master_data.is_catalog === true)) {
                        set_view('none');
                        set_data(JSON.parse(JSON.stringify(master_data)));
                    }
                    else if (master_data.is_catalog && master_data.view.length > 0) {
                        // If master is a catalog and the catalog is not empty then we got to the first view
                        set_view(master_data.view[0].id);
                        const tmp = GetDataFromView(master_data, master_data.view[0].id);
                        if (!tmp.accordeonToShow.includes('Vis')) {
                            tmp.accordeonToShow.push('Vis');
                        }
                        set_data(JSON.parse(JSON.stringify(tmp)));
                    }
                    if (master_data.view.length === 0) {
                        master_data.is_catalog = false;
                        set_data(JSON.parse(JSON.stringify(master_data)));
                    }
                    set_master_data(Object.assign({}, master_data));
                } },
                React.createElement(Box, { layerStyle: 'menutop_button_style' },
                    React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                        React.createElement(FontAwesomeIcon, { style: {
                                'height': '2rem',
                                'width': '3rem',
                                // 'opacity': (!has_open_sankey_plus) ? '0.6' : '1'
                            }, icon: faMinus }),
                        !has_open_sankey_plus ?
                            React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                    'fontSize': '1em',
                                    'position': 'absolute',
                                    'right': '0.1em',
                                    'bottom': '0em',
                                    'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                } }) :
                            React.createElement(React.Fragment, null)),
                    React.createElement(Box, { gridRow: "2" }, t('view.delete'))))));
    const file_reder_for_catalog = React.createElement(Input, { type: "file", multiple: true, accept: '.json', ref: _load_json_catalog, style: { display: 'none' }, onChange: (evt) => {
            const files = evt.target.files;
            const cpy_master_data = (master_data) ? master_data : JSON.parse(JSON.stringify(data));
            cpy_master_data.is_catalog = true;
            cpy_master_data.nodeTags = {};
            cpy_master_data.fluxTags = {};
            cpy_master_data.dataTags = {};
            cpy_master_data.nodes = {};
            cpy_master_data.links = {};
            cpy_master_data.labels = {};
            cpy_master_data.linkZIndex = [];
            // Parcours tous les element de l'objet (contient le blob des fichiers mais aussi une variable length)
            for (const i in files) {
                const reader = new FileReader();
                reader.onload = (() => {
                    return (e) => {
                        const result = String(e.target.result);
                        const result_data = JSON.parse(result);
                        const imported_data = JSON.parse(JSON.stringify(result_data));
                        convert_data(imported_data, get_default_data);
                        let new_ind = 'view_' + String(new Date().getTime());
                        let first_data = {};
                        if (imported_data.view && imported_data.view.length > 0) {
                            // Import all view from the coming file
                            imported_data.view.forEach((v, i2) => {
                                const view_from_imported_data = GetDataFromView(imported_data, v.id);
                                convert_data(view_from_imported_data, get_default_data);
                                if (i2 === 0 && i === '0') {
                                    new_ind = v.id;
                                    first_data = view_from_imported_data;
                                }
                                cpy_master_data.view.push({
                                    id: v.id,
                                    view_data: view_from_imported_data,
                                    nom: (files[i].name).replace('.json', '') + ' ' + v.nom,
                                    details: '',
                                    heredited_attr_from_master: []
                                });
                            });
                        }
                        else {
                            // Import only master data  when it doesn't have view
                            imported_data.view = [];
                            first_data = imported_data;
                            cpy_master_data.view.push({
                                id: new_ind,
                                view_data: imported_data,
                                nom: (files[i].name).replace('.json', ''),
                                details: '',
                                heredited_attr_from_master: []
                            });
                        }
                        if (i === '0') {
                            set_view(new_ind);
                            cpy_master_data.current_view = new_ind;
                            set_data(JSON.parse(JSON.stringify(first_data)));
                        }
                        set_master_data(JSON.parse(JSON.stringify(cpy_master_data)));
                    };
                })();
                // Permet d'executer la transformation des blob en vues tout en evitant la var length
                //   files : {0:Blob,1:Blob,2:...,n:Blob, length:n-1}
                if (!isNaN(+i)) {
                    reader.readAsText(files[i]);
                }
            }
        } });
    return React.createElement(React.Fragment, null,
        window.SankeyToolsStatic ? React.createElement(React.Fragment, null) : file_reder_for_catalog,
        window.SankeyToolsStatic ? React.createElement(React.Fragment, null) : create_data_catalog,
        window.SankeyToolsStatic ? React.createElement(React.Fragment, null) : React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus && !has_views) ? t('Menu.sankeyOSPDisabled') : t('view.tooltips.home') },
            React.createElement(Box, null,
                React.createElement(Button, { variant: 'menutop_button', isDisabled: ((!has_open_sankey_plus && !has_views) || (master_data && master_data.is_catalog)), onClick: () => {
                        const ev = document;
                        const tmp = { key: 'F7' };
                        if (ev.onkeydown) {
                            ev.onkeydown(tmp);
                        }
                    } },
                    React.createElement(Box, { layerStyle: 'menutop_button_style' },
                        React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                            React.createElement(FontAwesomeIcon, { style: {
                                    'height': '2rem',
                                    'width': '3rem',
                                    // 'opacity': (!has_open_sankey_plus && !has_views) ? '0.6' : '1'
                                }, icon: faHome }),
                            (!has_open_sankey_plus && !has_views) ?
                                React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                        'fontSize': '1em',
                                        'position': 'absolute',
                                        'right': '0.1em',
                                        'bottom': '0em',
                                        'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                    } })
                                : React.createElement(React.Fragment, null)),
                        React.createElement(Box, { gridRow: "2" }, t('Menu.home')))))),
        window.SankeyToolsStatic ? React.createElement(React.Fragment, null) : buttonCreateView,
        React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus && !has_views) ? t('Menu.sankeyOSPDisabled') : t('view.tooltips.PrevViewButton') },
            React.createElement(Box, null,
                React.createElement(Button, { variant: 'menutop_button', isDisabled: prev_button_disabled || !has_views, onClick: () => {
                        const ev = document;
                        const tmp = { key: 'F8' };
                        if (ev.onkeydown) {
                            ev.onkeydown(tmp);
                        }
                    } },
                    React.createElement(Box, { layerStyle: 'menutop_button_style' },
                        React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                            React.createElement(FontAwesomeIcon, { style: {
                                    'height': '2rem',
                                    'width': '3rem',
                                    // 'opacity': (prev_button_disabled || !has_views) ? '0.6' : '1'
                                }, icon: faCaretSquareLeft }),
                            (!has_open_sankey_plus && !has_views) ?
                                React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                        'fontSize': '1em',
                                        'position': 'absolute',
                                        'right': '0.1em',
                                        'bottom': '0em',
                                        'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                    } })
                                : React.createElement(React.Fragment, null)),
                        React.createElement(Box, { gridRow: "2" }, t('Menu.precView')))))),
        React.createElement(OSTooltip, { placement: 'bottom', label: (!has_open_sankey_plus && !has_views) ?
                (t('Menu.sankeyOSPDisabled')) :
                t('view.tooltips.NextViewButton') },
            React.createElement(Box, null,
                React.createElement(Button, { variant: 'menutop_button', isDisabled: next_button_disabled || !has_views, onClick: () => {
                        const ev = document;
                        const tmp = { key: 'F9' };
                        if (ev.onkeydown) {
                            ev.onkeydown(tmp);
                        }
                    } },
                    React.createElement(Box, { layerStyle: 'menutop_button_style' },
                        React.createElement(Box, { gridRow: "1", padding: "0.1rem 0 0.1rem 0" },
                            React.createElement(FontAwesomeIcon, { style: {
                                    'height': '2rem',
                                    'width': '3rem',
                                    // 'opacity': (next_button_disabled || !has_views) ? '0.6' : '1'
                                }, icon: faCaretSquareRight }),
                            (!has_open_sankey_plus && !has_views) ?
                                React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                                        'fontSize': '1em',
                                        'position': 'absolute',
                                        'right': '0.1em',
                                        'bottom': '0em',
                                        'color': 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                                    } })
                                : React.createElement(React.Fragment, null)),
                        React.createElement(Box, { gridRow: "2" }, t('Menu.nextView')))))),
        React.createElement(Box, { height: '3rem', gridColumnEnd: 'span 4', alignSelf: 'center', alignContent: 'center' }, view_selector),
        (master_data ? master_data : { view: [] }).view.length > 0 && master_data.current_view !== 'none' && !window.SankeyToolsStatic ? React.createElement(React.Fragment, null,
            button_delete_actual_view,
            master_data && !master_data.is_catalog ? button_heredited_attr_from_master : React.createElement(React.Fragment, null))
            : React.createElement(React.Fragment, null));
};
export const OSPMenuPreferenceView = (t, data, set_data) => {
    return React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.accordeonToShow.includes('Vis'), onChange: () => {
            preferenceCheck('Vis', data);
            set_data(JSON.parse(JSON.stringify(data)));
        } }, t('view.storytelling'));
};
// Modal used when we want to switch to master or a view without saving some changements we made on the current view
// It give the option save or not the changements made
export const modal_view_not_saved = (view_not_saved, set_view_not_saved, t, applicationData) => {
    const { data, set_data, view, master_data, set_master_data } = applicationData;
    return (React.createElement(Modal, { isOpen: view_not_saved !== '', onClose: () => null },
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalHeader, null, t('view.ns')),
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, null, t('view.warn_ns')),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { variant: 'danger', onClick: () => {
                        // Don't save the view before changing to the selected one
                        if (view !== 'none') {
                            const data_view = GetDataFromView(master_data, view);
                            set_data(JSON.parse(JSON.stringify(data_view)));
                        }
                        else if (view === 'none') {
                            set_data(JSON.parse(JSON.stringify(master_data)));
                        }
                        set_view_not_saved('');
                    } }, t('view.dont_save')),
                React.createElement(Button, { variant: 'success', onClick: () => {
                        // Save the view before changing to the selected one
                        let difference = getDiff(master_data, data);
                        difference = (difference !== undefined) ? difference : [];
                        difference = difference.filter((d) => !(d.path.includes('view')));
                        master_data.view.filter(v => v.id === view_not_saved)[0].view_data = { diff: difference };
                        update_heredited_attr_from_master(master_data.view.filter(v => v.id === view_not_saved)[0], data, master_data);
                        if (view !== 'none') {
                            const data_view = GetDataFromView(master_data, view);
                            set_master_data(JSON.parse(JSON.stringify(master_data)));
                            set_data(JSON.parse(JSON.stringify(data_view)));
                        }
                        else if (view === 'none') {
                            set_data(JSON.parse(JSON.stringify(master_data)));
                        }
                        set_view_not_saved('');
                    } }, t('view.save'))))));
};
export const modal_transparent_view_attr = (dict_hook_ref_setter_show_dialog_components, applicationData, t) => {
    var _a, _b;
    const current_view = (_b = (_a = applicationData.master_data) === null || _a === void 0 ? void 0 : _a.view.filter(v => v.id === applicationData.master_data.current_view)[0]) !== null && _b !== void 0 ? _b : {};
    const { data, set_data, master_data, set_master_data } = applicationData;
    const { ref_setter_show_modal_transparent_view_attr } = dict_hook_ref_setter_show_dialog_components;
    const [show_modal, set_show_modal] = useState(false);
    ref_setter_show_modal_transparent_view_attr.current = set_show_modal;
    if (master_data && master_data.current_view !== undefined && (master_data === null || master_data === void 0 ? void 0 : master_data.current_view) !== 'none' && applicationData.data !== undefined) {
        return React.createElement(Modal, { isOpen: show_modal, onClose: () => {
                RecomputeViews(data, data, set_data);
                set_show_modal(false);
            } },
            React.createElement(ModalContent, { maxWidth: 'inherit' },
                React.createElement(ModalHeader, null, t('view.setTransparentAttr')),
                React.createElement(ModalCloseButton, null),
                React.createElement(ModalBody, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Topology')),
                        React.createElement(Box, { layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('addNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('addNode')) {
                                        current_view.heredited_attr_from_master.push('addNode');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('addNode'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.addNode')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('removeNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('removeNode')) {
                                        current_view.heredited_attr_from_master.push('removeNode');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('removeNode'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.removeNode')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('addFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('addFlux')) {
                                        current_view.heredited_attr_from_master.push('addFlux');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('addFlux'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.addFlux')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('removeFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('removeFlux')) {
                                        current_view.heredited_attr_from_master.push('removeFlux');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('removeFlux'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.removeFlux')))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Geometry')),
                        React.createElement(Box, { layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('posNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('posNode')) {
                                        current_view.heredited_attr_from_master.push('posNode');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('posNode'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.PosNoeud')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('posFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('posFlux')) {
                                        current_view.heredited_attr_from_master.push('posFlux');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('posFlux'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.posFlux')))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Values')),
                        React.createElement(Box, { as: 'span', layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('Values') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('Values')) {
                                        current_view.heredited_attr_from_master.push('Values');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('Values'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, current_view.heredited_attr_from_master.includes('Values') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark })))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Attribut')),
                        React.createElement(Box, { as: 'span', layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('attrNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('attrNode')) {
                                        current_view.heredited_attr_from_master.push('attrNode');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('attrNode'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.attrNode')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('attrFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('attrFlux')) {
                                        current_view.heredited_attr_from_master.push('attrFlux');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('attrFlux'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.attrFlux')))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Tags')),
                        React.createElement(Box, { layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('tagNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('tagNode')) {
                                        current_view.heredited_attr_from_master.push('tagNode');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('tagNode'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.tagNode')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('tagFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('tagFlux')) {
                                        current_view.heredited_attr_from_master.push('tagFlux');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('tagFlux'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.tagFlux')),
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('tagData') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('tagData')) {
                                        current_view.heredited_attr_from_master.push('tagData');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('tagData'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, t('Menu.Transformation.tagData')))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.tagLevel')),
                        React.createElement(Box, { as: 'span', layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('tagLevel') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('tagLevel')) {
                                        current_view.heredited_attr_from_master.push('tagLevel');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('tagLevel'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, current_view.heredited_attr_from_master.includes('tagLevel') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark })))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.attrGeneral')),
                        React.createElement(Box, { as: 'span', layerStyle: 'options_4cols' },
                            React.createElement(Button, { variant: current_view.heredited_attr_from_master.includes('attrGeneral') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                    if (!current_view.heredited_attr_from_master.includes('attrGeneral')) {
                                        current_view.heredited_attr_from_master.push('attrGeneral');
                                    }
                                    else {
                                        current_view.heredited_attr_from_master.splice(current_view.heredited_attr_from_master.indexOf('attrGeneral'), 1);
                                    }
                                    set_data(Object.assign({}, data));
                                    set_master_data(Object.assign({}, master_data));
                                } }, current_view.heredited_attr_from_master.includes('attrGeneral') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { onClick: () => {
                            updateLayoutOSTyped(data, master_data, current_view.heredited_attr_from_master);
                            // updateLayout(data,master_data,current_view.heredited_attr_from_master)
                            set_data(Object.assign({}, data));
                        } }, t('view.updateViewWithMasterVar')))));
    }
    return React.createElement(React.Fragment, null);
};
export const MenuEnregistrerView = ({ t, elementsSelected }) => {
    const [save_only_view, set_save_only_view] = useState(false);
    elementsSelected.saveViewGetter.current = save_only_view;
    return React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: save_only_view, onChange: () => set_save_only_view(!save_only_view) },
        React.createElement(OSTooltip, { label: t('view.tooltips.buttonExportView') }, t('view.export')));
};
export const OpenOSPCheckpointButton = (master_data, data, view, view_not_saved, has_open_sankey_plus, t) => {
    // Boolean used to change the logo of the button to save the current view :
    //  - if there is no differences between the the saved view and the current view, then the logo has a check
    //  - else if it contain difference, the logo contain an exclamation point
    const is_different = false;
    if (view !== 'none' && view_not_saved === '' && master_data && has_open_sankey_plus) {
        // find another way with a variable. Checking the all view consumes too much time
        // const diff=CheckCurrentViewSaved(master_data,data,view)
        // if(diff.length>0){
        //   is_different=true
        // }
    }
    return React.createElement(OSTooltip, { label: (!has_open_sankey_plus) ? (t('Menu.sankeyOSPDisabled')) : t('view.tooltips.saveView') },
        React.createElement(Button, { isDisabled: !has_open_sankey_plus, variant: 'light', onClick: () => {
                const ev = document;
                const t = new KeyboardEvent('keydown', { key: 's', ctrlKey: true });
                if (ev.onkeydown) {
                    ev.onkeydown(t);
                }
            } },
            React.createElement(FontAwesomeIcon, { icon: faFloppyDisk, style: { opacity: (!has_open_sankey_plus) ? '0.6' : '1', width: '2rem', height: '2rem' } }),
            !has_open_sankey_plus ? React.createElement(React.Fragment, null,
                React.createElement(FontAwesomeIcon, { icon: faLock, style: {
                        fontSize: '1em',
                        color: 'rgba(var(--bs-info-rgb), var(--bs-bg-opacity))'
                    } }))
                : React.createElement(React.Fragment, null, is_different ?
                    React.createElement(FontAwesomeIcon, { icon: faExclamation, style: {
                            fontSize: '1em',
                            color: 'rgba(var(--bs-danger-rgb), var(--bs-bg-opacity))'
                        } })
                    : React.createElement(React.Fragment, null))));
};
/**
 * Modify heredited_attr_from_master in function of the modification done in the view.
 * If an attribute in the view is modified which was heredited from master the set attributes
 * containing the attribute is removed from the list of heredited attributes
 */
const update_heredited_attr_from_master = (view, view_data, master_data) => {
    const heredited_attr_from_master = view.heredited_attr_from_master;
    if (heredited_attr_from_master.indexOf('attrGeneral') !== -1) {
        let differences = getDiff(view_data, master_data);
        if (differences) {
            const legend_pos = differences.filter(difference => difference.path[0] == 'legend_position');
            if (legend_pos.length > 0) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrGeneral'), 1);
            }
            differences = differences.filter((difference) => (difference.kind === 'E') &&
                (difference.path.length === 1) &&
                (difference.path[0] !== 'current_view'));
            if (differences.length > 0) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrGeneral'), 1);
            }
        }
        const display_style_diff = getDiff(view_data.display_style, master_data.display_style);
        if (display_style_diff) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrGeneral'), 1);
        }
        const node_style_differences = getDiff(view_data.style_node, master_data.style_node);
        if (node_style_differences) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrGeneral'), 1);
        }
        const link_style_differences = getDiff(view_data.style_link, master_data.style_link);
        if (link_style_differences) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrGeneral'), 1);
        }
    }
    if (heredited_attr_from_master.indexOf('addNode') !== -1) {
        let differences = getDiff(view_data.nodes, master_data.nodes);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'N') &&
                (difference.path.length === 1));
            if (differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('addNode'), 1);
            }
        }
    }
    if (heredited_attr_from_master.indexOf('removeNode') !== -1) {
        let differences = getDiff(view_data.nodes, master_data.nodes);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'D') &&
                (difference.path.length === 1));
            if (differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('removeNode'), 1);
            }
        }
    }
    if (heredited_attr_from_master.indexOf('addFlux') !== -1) {
        let differences = getDiff(view_data.links, master_data.links);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'N') &&
                (difference.path.length === 1));
            if (differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('addFlux'), 1);
            }
        }
    }
    if (heredited_attr_from_master.indexOf('removeFlux') !== -1) {
        let differences = getDiff(view_data.links, master_data.links);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'D') &&
                (difference.path.length === 1));
            if (differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('removeFlux'), 1);
            }
        }
    }
    if (heredited_attr_from_master.indexOf('posNode') !== -1) {
        let differences = getDiff(view_data.nodes, master_data.nodes);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'E') &&
                (['x', 'y', 'x_label', 'y_label'].includes(difference.path[1])));
            if (differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('posNode'), 1);
            }
        }
    }
    if (heredited_attr_from_master.indexOf('posLink') !== -1) {
        const geometry_attributes = [
            'orientation',
            'left_horiz_shift',
            'right_horiz_shift',
            'vert_shift',
            'curvature',
            'curved',
            'recycling',
            'arrow_size',
            // Geometry link labels
            'x_label',
            'y_label',
            'label_position',
            'orthogonal_label_position',
            'label_on_path'
        ];
        let differences = getDiff(view_data.links, master_data.links);
        if (differences) {
            differences = differences.filter((difference) => (difference.kind === 'D' || difference.kind === 'N') &&
                (difference.path.length === 3) &&
                (difference.path[1] === 'local') &&
                (geometry_attributes.includes(difference.path[2])) ||
                (difference.kind === 'E' && geometry_attributes.includes(difference.path[1])));
            if (differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('posLink'), 1);
            }
        }
    }
    if (heredited_attr_from_master.indexOf('attrNode') !== -1) {
        Object.entries(view_data.nodes).forEach(([key, node]) => {
            const layoutNode = master_data.nodes[key];
            if (!layoutNode) {
                return;
            }
            if (!node.local) {
                node.local = {};
            }
            if (!layoutNode.local) {
                layoutNode.local = {};
            }
            const differences = getDiff(node.local, layoutNode.local);
            if (differences && differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrNode'), 1);
            }
        });
    }
    if (heredited_attr_from_master.indexOf('attrFlux') !== -1) {
        Object.entries(view_data.links).forEach(([key, link]) => {
            const layoutLink = master_data.links[key];
            if (!layoutLink) {
                return;
            }
            if (!link.local) {
                link.local = {};
            }
            if (!layoutLink.local) {
                layoutLink.local = {};
            }
            const differences = getDiff(link.local, layoutLink.local);
            if (differences && differences.length > 1) {
                heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('attrFlux'), 1);
            }
        });
    }
    if (heredited_attr_from_master.indexOf('Values') !== -1) {
        const dataTagsNames = Object.values(view_data.dataTags).map(tagGroup => tagGroup.group_name);
        const layoutTagsNames = Object.values(master_data.dataTags).map(tagGroup => tagGroup.group_name);
        if (JSON.stringify(dataTagsNames) === (JSON.stringify(layoutTagsNames))) {
            Object.entries(view_data.links).forEach(([key, link]) => {
                const layoutLink = master_data.links[key];
                if (!layoutLink) {
                    return;
                }
                const differences = getDiff(link.value, layoutLink.value);
                if (differences) {
                    heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('Values'), 1);
                }
            });
        }
    }
    if (heredited_attr_from_master.indexOf('tagLevel') !== -1) {
        const differences = getDiff(view_data.levelTags, master_data.levelTags);
        if (differences) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('tagLevel'), 1);
            //alert('Niveau de détail modifié. Cet attribut n\'est plus hérité du maître.')
        }
    }
    if (heredited_attr_from_master.indexOf('tagNode') !== -1) {
        const differences = getDiff(view_data.nodeTags, master_data.nodeTags);
        if (differences) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('tagNode'), 1);
            //alert('Etiquettes de noeuds modifiées. Ce groupe d\'attribut n\'est plus hérité du maître.')
        }
    }
    if (heredited_attr_from_master.indexOf('tagFlux') !== -1) {
        const differences = getDiff(view_data.nodeTags, master_data.nodeTags);
        if (differences) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('tagFlux'), 1);
            //alert('Etiquettes de flux modifiées. Ce groupe d\'attribut n\'est plus hérité du maître.')
        }
    }
    if (heredited_attr_from_master.indexOf('tagData') !== -1) {
        const differences = getDiff(view_data.nodeTags, master_data.nodeTags);
        if (differences) {
            heredited_attr_from_master.splice(heredited_attr_from_master.indexOf('tagData'), 1);
            //alert('Etiquettes de données modifiées. Ce groupe d\'attribut n\'est plus hérité du maître.')
        }
    }
};
