import * as AFMTooltip from 'afmsankey/dist/src/lib/SankeyTooltip'
import * as AFMSankeyMenu from 'afmsankey/dist/src/lib/AFMSankeyMenu'
import * as AFMFreeVariables from 'afmsankey/dist/src/lib/FreeVariables'
import * as AFMReconciliation from 'afmsankey/dist/src/lib/SankeyReconciliation'
import * as AFMModule from 'afmsankey/dist/src/AFMModule'
import {
  LinkTextFType,
  UnitsSelectorsType,
  AFMEditionMenuFType,
  node_arrow_visibleFType,
  processTradeFType,
  uncert_link_textFType
} from 'afmsankey/types/AFMSankeyMenuTypes'
import { AFMLinkTooltipsContentFType, AFMNodeTooltipsContentFType } from 'afmsankey/types/SankeyTooltipTypes'
import { FreeVariablesFType,checkboxFreeNullLinkType } from 'afmsankey/types/FreeVariablesTypes'
import { FunctionComponent } from 'react'
import {SupplyUseModelisationProdTypes} from 'afmsankey/types/SankeyReconciliationTypes'
import { initializeApplicationContextType, initializeElementSelectedType, initializeApplicationDrawType, initializeShowDialogType, initializeComponentUpdaterType, initializeReinitializationType, initializeContextMenuType, initializeUIElementsRefType, initializeLinkFunctionsType, initializeNodeFunctionsType, initializeAdditionalMenusType, module_dialogsType, DrawAllType, InstallEventsOnSVGType, initializeApplicationDataType } from 'open-sankey/src/types/Types'
export const AFMLinkTooltipsContent =
  AFMTooltip.AFMLinkTooltipsContent as AFMLinkTooltipsContentFType
export const AFMNodeTooltipsContent =
  AFMTooltip.AFMNodeTooltipsContent as AFMNodeTooltipsContentFType
export const uncert_link_text =
  AFMSankeyMenu.uncert_link_text as uncert_link_textFType
export const LinkText =
  AFMSankeyMenu.LinkText as LinkTextFType
export const node_arrow_visible =
  AFMSankeyMenu.node_arrow_visible as node_arrow_visibleFType
export const processTrade =
  AFMSankeyMenu.processTrade as processTradeFType
export const FreeVariables =
  AFMFreeVariables.FreeVariables as FreeVariablesFType
export const AFMEditionMenu =
  AFMSankeyMenu.AFMEditionMenu as AFMEditionMenuFType
export const UnitsSelector =
  AFMSankeyMenu.UnitsSelector as UnitsSelectorsType

export const checkboxFreeNullLink:checkboxFreeNullLinkType=AFMFreeVariables.checkboxFreeNullLink

export const SupplyUseModelisationProd:FunctionComponent<SupplyUseModelisationProdTypes>=AFMReconciliation.SupplyUseModelisationProd

export const AFMInitializeApplicationContext = AFMModule.AFMInitializeApplicationContext as initializeApplicationContextType
export const AFMinitializeApplicationData = AFMModule.AFMInitializeApplicationData as unknown as initializeApplicationDataType
export const AFMInitializeElementSelected = AFMModule.AFMInitializeElementSelected as unknown as initializeElementSelectedType
export const AFMInitializeApplicationDraw = AFMModule.AFMInitializeApplicationDraw as  unknown as initializeApplicationDrawType
export const AFMInitializeShowDialog = AFMModule.AFMInitializeShowDialog as unknown as initializeShowDialogType
export const AFMInitializeComponentUpdater = AFMModule.AFMInitializeComponentUpdater as unknown as initializeComponentUpdaterType
export const AFMInitializeReinitialization  = AFMModule.AFMInitializeReinitialization as initializeReinitializationType
//export const AFMInitializeProcessFunctions = AFMModule.AFMInitializeProcessFunctions as initializeProcessFunctionsType
export const AFMInitializeContextMenu = AFMModule.AFMInitializeContextMenu as initializeContextMenuType
export const AFMInitializeUIElementsRef = AFMModule.AFMInitializeUIElementsRef as initializeUIElementsRefType
export const AFMInitializeLinkFunctions  = AFMModule.AFMInitializeLinkFunctions as unknown as initializeLinkFunctionsType
export const AFMInitializeNodeFunctions = AFMModule.AFMInitializeNodeFunctions as unknown as  initializeNodeFunctionsType
export const AFMInitializeAdditionalMenus = AFMModule.AFMInitializeAdditionalMenus as initializeAdditionalMenusType
export const AFMModuleDialogs = AFMModule.AFMModuleDialogs as module_dialogsType
export const AFMDrawAll = AFMModule.AFMDrawAll as DrawAllType
export const AFMInstallEventsOnSVG = AFMModule.AFMInstallEventsOnSVG as InstallEventsOnSVGType