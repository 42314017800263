import { useRef } from 'react';
import { closeAllMenu, initializeProcessFunctions } from './import/OpenSankey';
import { UnitsSelector, AFMEditionMenu, processTrade, SplitVerticalModal, LinkText as AFMLinkText } from './lib/AFMSankeyMenu';
import { FreeVariables } from './lib/FreeVariables';
import React from 'react';
import { SupplyUseModelisationProd } from './lib/SankeyReconciliation';
import { AFMLinkTooltipsContent, AFMNodeTooltipsContent } from './lib/SankeyTooltip';
export const AFMDefaultData = () => {
    return {};
};
export const AFMInitializeApplicationContext = () => {
    return {
        url_prefix: '/sankeytools/'
    };
};
export const AFMInitializeApplicationData = () => {
    return {};
};
export const AFMInitializeElementSelected = () => {
    return {
        action_type: useRef('')
    };
};
export const AFMInitializeShowDialog = () => {
    return {
        ref_setter_show_reconciliation: useRef(() => null),
        ref_setter_show_split_trade: useRef(() => null),
    };
};
export const AFMcloseAllMenu = closeAllMenu;
// Modify Application Draw
export const AFMInitializeApplicationDraw = () => {
    const _ = {};
    return _;
};
export const AFMInitializeComponentUpdater = () => {
    const _ = {};
    return _;
};
export const AFMInitializeReinitialization = () => () => {
};
export const AFMInitializeProcessFunctions = initializeProcessFunctions;
// Modify context menu
export const AFMInitializeContextMenu = () => {
    return {};
};
// Modify Ref used to open accordion item
export const AFMInitializeUIElementsRef = () => {
    return {};
};
export const AFMInitializeLinkFunctions = () => {
    return {
        LinkText: AFMLinkText,
        LinkTooltipsContent: AFMLinkTooltipsContent
    };
};
export const AFMInitializeNodeFunctions = () => {
    return {
        postProcessLoadExcel: processTrade,
        NodeTooltipsContent: AFMNodeTooltipsContent
    };
};
export const AFMInitializeAdditionalMenus = (additionalMenus, applicationContext, applicationData, applicationDraw, ComponentUpdater, applicationState, uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function) => {
    const { units_names } = applicationData.data;
    let units = undefined;
    if (units_names && units_names.length > 0 && units_names[0].length > 0) {
        units = [units_names[0][0], 'Naturelles'];
    }
    additionalMenus.additional_link_appearence_items.push(React.createElement(FreeVariables, { t: applicationContext.t, applicationData: applicationData, applicationState: applicationState, node_function: node_function, link_function: link_function }));
    // Configuration Menu
    // additionalMenus.additional_configuration_menus.push(<AFMMenuConfigurationImportExport
    //   applicationData={applicationData as AFMApplicataionDataType}
    //   t={applicationContext.t}
    //   node_function={node_function}
    //   link_function={link_function}
    // />)
    // addon_link_visual_filter_toolbar.push(displayFNL)
    if (!window.SankeyToolsStatic) {
        additionalMenus.sankey_menus['afm'] = React.createElement(AFMEditionMenu, { t: applicationContext.t, applicationData: applicationData, ref_setter_show_split_trade: dict_hook_ref_setter_show_dialog_components.ref_setter_show_split_trade, action_type: applicationState.action_type, ref_setter_show_reconciliation: dict_hook_ref_setter_show_dialog_components.ref_setter_show_reconciliation, node_function: node_function });
    }
    additionalMenus.sankey_menus['unité'] = React.createElement(UnitsSelector, { data: applicationData.data, set_data: applicationData.set_data, units: units });
};
export const AFMModuleDialogs = (applicationContext, applicationData, applicationState, contextMenu, applicationDraw, uiElementsRef, dict_hook_ref_setter_show_dialog_components, node_function, link_function, ComponentUpdater, additional_menus, menu_configuration_nodes_attributes, reDrawLegend, processFunctions) => {
    const AFMAppData = applicationData;
    const AFMElementSelected = applicationState;
    const AFMShowDialog = dict_hook_ref_setter_show_dialog_components;
    const module_dialogs = [];
    module_dialogs.push(React.createElement(SupplyUseModelisationProd, { action_type: AFMElementSelected.action_type, ref_setter_show_reconciliation: AFMShowDialog.ref_setter_show_reconciliation, applicationData: AFMAppData, postProcessLoadExcel: processTrade, updateLayout: applicationDraw.updateLayout, DefaultSankeyData: applicationData.get_default_data, launch: processFunctions.launch, applicationContext: applicationContext }));
    module_dialogs.push(React.createElement(SplitVerticalModal, { data: applicationData.data, ref_setter_show_split_trade: dict_hook_ref_setter_show_dialog_components.ref_setter_show_split_trade }));
    return module_dialogs;
};
export const AFMDrawAll = () => {
};
export const AFMInstallEventsOnSVG = () => { };
