// Standard libs
import React, { useState, useRef } from 'react';
import * as d3 from 'd3';
import { MultiSelect } from 'react-multi-select-component';
import { FaAngleDown, FaAngleUp, FaMinus, FaPlus } from 'react-icons/fa';
import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css'
// Imported libs
import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Checkbox, Button, InputGroup, Input, NumberInput, NumberDecrementStepper, NumberIncrementStepper, NumberInputField, NumberInputStepper, ButtonGroup } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
// Local libs
import { IsAllZdtAttrSameValue } from './SankeyPlusUtils';
import { deleteGLabel } from './SankeyPlusLabels';
import { OSTooltip } from './import/OpenSankey';
// OpenSankey js-code
import { preferenceCheck } from 'open-sankey/dist/dialogs/SankeyMenuPreferences';
const sep = React.createElement("hr", { style: { borderStyle: 'none', margin: '0px', color: 'grey', backgroundColor: 'grey', height: 2 } });
/**
 *  TODO
 *
 * @param { TFunction } t - TODO description
 * @param { OSPData } data - TODO description
 * @param { Function } set_data - TODO description
 *
 */
export const OSPMenuPreferenceLabels = ({ t, data, updateMenus }) => {
    return React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.accordeonToShow.includes('LL'), onChange: () => {
            preferenceCheck('LL', data);
            updateMenus[1](!updateMenus[0]);
        } }, t('Menu.LL'));
};
export const OSPMenuConfigurationFreeLabels = ({ applicationData, applicationContext, applicationState, ComponentUpdater, reDrawOSPLabels }) => {
    const { data } = applicationData;
    const { multi_selected_label } = applicationState;
    const { t, has_open_sankey_plus } = applicationContext;
    const r_editor_ZDT = useRef();
    const zdt_or_image = (multi_selected_label.current.length > 0 ? (multi_selected_label.current[0].is_image === true ? 'image' : 'zdt') : 'zdt');
    const tmplabel = Object.fromEntries(Object.entries(data.labels).sort(([, a], [, b]) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)));
    const INITIAL_OPTIONS_label = Object.values(tmplabel).map((d) => { return { 'label': d.title, 'value': d.idLabel }; });
    const selected_label = multi_selected_label.current.map((d) => { return { 'label': d.title, 'value': d.idLabel }; });
    const [button_icon_or_image, set_button_icon_or_image] = useState(zdt_or_image);
    //const [s_editor_content_fo_zdt,sEditorContentFOZdt]= useState('')
    const [forceUpdate, setForceUpdate] = useState(false);
    const { updateComponentMenuConfigZdt, updateMenus } = ComponentUpdater;
    updateComponentMenuConfigZdt.current.push(() => setForceUpdate(!forceUpdate));
    //applicationState.r_setter_editor_content_fo_zdt.current!.push(sEditorContentFOZdt)
    // if (multi_selected_label.current.length == 0 && s_editor_content_fo_zdt != '') {
    //   sEditorContentFOZdt('')
    // }
    //Dépalce la place des labels libres sélectionnés vers le debut dans le tableau de flux de data
    //Permet donc de les déssiner après
    const handleUplabel = (i) => {
        const { labels } = data;
        const listElmt = Object.keys(labels);
        const posElemt = listElmt.indexOf(i);
        listElmt.splice(posElemt, 1);
        listElmt.splice(posElemt - 1, 0, i);
        const new_cat = {};
        listElmt.forEach(elt => {
            new_cat[elt] = labels[elt];
        });
        for (const member in labels)
            delete labels[member];
        Object.assign(labels, new_cat);
        reDrawOSPLabels(Object.values(data.labels));
        setForceUpdate(!forceUpdate);
    };
    //Dépalce la place des labels libres sélectionnés vers la fin dans le tableau de flux de data
    //Permet donc de les déssiner après
    const handleDownlabel = (i) => {
        const { labels } = data;
        const listElmt = Object.keys(labels);
        const posElemt = listElmt.indexOf(i);
        listElmt.splice(posElemt, 1);
        listElmt.splice(posElemt + 1, 0, i);
        const new_cat = {};
        listElmt.forEach(elt => {
            new_cat[elt] = labels[elt];
        });
        for (const member in labels)
            delete labels[member];
        Object.assign(labels, new_cat);
        reDrawOSPLabels(Object.values(data.labels));
        setForceUpdate(!forceUpdate);
    };
    //Renvoie le menue déroulant pour la sélection des labels libres
    const dropdownMultiLabel = () => {
        const DD = (React.createElement(Box, { layerStyle: 'submenuconfig_droplist' },
            React.createElement(Box, { height: '2rem', width: '10rem' },
                React.createElement(MultiSelect, { disabled: !has_open_sankey_plus, valueRenderer: (selected) => {
                        return selected.length ? selected.map(({ label }) => label + ', ') : 'Aucun label sélectionné';
                    }, options: INITIAL_OPTIONS_label, value: selected_label, overrideStrings: {
                        'selectAll': 'Tout sélectionner',
                    }, onChange: (selected) => {
                        const new_sel = selected.map(d => d.value);
                        const m_s = Object.values(data.labels).filter(d => (new_sel.includes(d.idLabel)));
                        multi_selected_label.current = m_s;
                        reDrawOSPLabels(multi_selected_label.current);
                        if (multi_selected_label.current.length > 0) {
                            // const tmp = multi_selected_label.current[multi_selected_label.current.length-1].content
                            updateMenus[1](!updateMenus[0]);
                        }
                        else {
                            updateMenus[1](!updateMenus[0]);
                        }
                        updateComponentMenuConfigZdt.current.forEach(f => f());
                    }, labelledBy: 'hello' }))));
        return DD;
    };
    //=================FONCTION POUR TEST VALEUR MULTI SELECT LABEL===========================
    const allLabelHeight = () => {
        let display_size = true;
        let size = 25;
        if (multi_selected_label.current.length !== 0) {
            size = multi_selected_label.current[0].label_height;
        }
        multi_selected_label.current.map((d) => {
            display_size = (d.label_height === size) ? display_size : false;
        });
        return (display_size) ? Math.round(size) : -1;
    };
    const allLabelWidth = () => {
        let display_size = true;
        let size = 25;
        if (multi_selected_label.current.length !== 0) {
            size = multi_selected_label.current[0].label_width;
        }
        multi_selected_label.current.map((d) => {
            display_size = (d.label_width === size) ? display_size : false;
        });
        return (display_size) ? Math.round(size) : -1;
    };
    const allLabelTitle = () => {
        return multi_selected_label.current.length > 0 ? multi_selected_label.current[0].title : '';
    };
    const allLabelTransparent = () => {
        let display_size = true;
        let opa = 100;
        if (multi_selected_label.current.length !== 0) {
            opa = multi_selected_label.current[0].opacity;
        }
        multi_selected_label.current.map((d) => {
            display_size = (d.opacity === opa) ? display_size : false;
        });
        return (display_size) ? opa : 0;
    };
    const valAllLabelBorderTransparent = IsAllZdtAttrSameValue(data, multi_selected_label.current, 'transparent_border');
    const modules = {
        toolbar: [
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['clean'],
        ],
    };
    const formats = [
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'list',
        'bullet',
        'align'
    ];
    const disable_options = has_open_sankey_plus ? (multi_selected_label.current.length === 0) : true;
    const content_image = React.createElement(React.Fragment, null,
        React.createElement(OSTooltip, { label: !has_open_sankey_plus ? t('Menu.sankeyOSPDisabled') : '' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.img_src')),
                React.createElement(Input, { accept: 'image/*', type: "file", disabled: disable_options, onChange: (evt) => {
                        const files = evt.target.files;
                        const reader = new FileReader();
                        reader.onload = (() => {
                            return (e) => {
                                const resultat = e.target.result;
                                const res = resultat === null || resultat === void 0 ? void 0 : resultat.toString().replaceAll('=', '');
                                Object.values(data.labels).filter(f => multi_selected_label.current.map(d => d.idLabel).includes(f.idLabel))
                                    .forEach(n => n.image_src = res);
                                reDrawOSPLabels(multi_selected_label.current);
                                setForceUpdate(!forceUpdate);
                            };
                        })();
                        reader.readAsDataURL(files[0]);
                    } }))));
    const content_menu_zdt = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_zdt_row_droplist' },
            React.createElement(Button, { isDisabled: !has_open_sankey_plus, variant: 'menuconfigpanel_add_button', onClick: () => {
                    let idZdt = Object.keys(data.labels).length;
                    const tab_title = Object.values(data.labels).map(zdt => zdt.title);
                    while (tab_title.includes('Zone de texte ' + idZdt)) {
                        idZdt = idZdt + 1;
                    }
                    const new_label = {
                        idLabel: 'label_' + String(new Date().getTime()),
                        title: 'Zone de texte ' + idZdt,
                        content: 'Text Label ...',
                        label_width: 100,
                        label_height: 25,
                        color: 'white',
                        color_border: 'black',
                        opacity: 100,
                        transparent_border: false,
                        is_image: false,
                        image_src: '',
                        x: 50,
                        y: 50,
                    };
                    data.labels[new_label.idLabel] = new_label;
                    multi_selected_label.current = [new_label];
                    reDrawOSPLabels(multi_selected_label.current);
                    setForceUpdate(!forceUpdate);
                } },
                React.createElement(FaPlus, null)),
            dropdownMultiLabel(),
            React.createElement(Button, { variant: 'menuconfigpanel_del_button', isDisabled: disable_options, onClick: () => {
                    deleteGLabel(updateMenus, multi_selected_label.current);
                    data.labels = Object.fromEntries(Object.entries(data.labels).filter(d => !multi_selected_label.current.map(l => l.idLabel).includes(d[0])));
                    multi_selected_label.current = [];
                    updateComponentMenuConfigZdt.current.forEach(f => f());
                } },
                React.createElement(FaMinus, null)),
            React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: disable_options, onClick: () => {
                    multi_selected_label.current.map(l => {
                        handleDownlabel(l.idLabel);
                    });
                } },
                React.createElement(FaAngleUp, null)),
            React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: disable_options, onClick: () => {
                    multi_selected_label.current.map(l => {
                        handleUplabel(l.idLabel);
                    });
                } },
                React.createElement(FaAngleDown, null))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols', gridTemplateColumns: '1fr 9fr' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', textStyle: 'h3' }, t('LL.title')),
            React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                React.createElement(Input, { variant: 'menuconfigpanel_option_input', max: 100, disabled: disable_options, style: {
                        color: (disable_options) ? '#666666' : '',
                        backgroundColor: (disable_options) ? '#cccccc' : ''
                    }, value: allLabelTitle(), onChange: evt => {
                        const value = evt.target.value;
                        multi_selected_label.current.map(d => d.title = value);
                        setForceUpdate(!forceUpdate);
                    } }))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Noeud.illustration_type')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Button, { isDisabled: disable_options, variant: 'menuconfigpanel_option_button', onClick: () => {
                        Object.values(data.labels).filter(f => multi_selected_label.current.map(d => d.idLabel).includes(f.idLabel))
                            .forEach(n => n.is_image = false);
                        set_button_icon_or_image('zdt');
                        reDrawOSPLabels(multi_selected_label.current);
                        setForceUpdate(!forceUpdate);
                    } }, "Texte"),
                React.createElement(Button, { disabled: disable_options, variant: 'menuconfigpanel_option_button', onClick: () => {
                        Object.values(data.labels).filter(f => multi_selected_label.current.map(d => d.idLabel).includes(f.idLabel))
                            .forEach(n => n.is_image = true);
                        set_button_icon_or_image('image');
                        reDrawOSPLabels(multi_selected_label.current);
                        setForceUpdate(!forceUpdate);
                    } }, "Image"))),
        button_icon_or_image === 'zdt' ? React.createElement(Box, { style: { 'height': '300px' } },
            React.createElement(ReactQuill, { className: 'quill_editor', value: multi_selected_label.current.length > 0 ? multi_selected_label.current[0].content : '', ref: r_editor_ZDT, onChange: (evt) => {
                    //sEditorContentFOZdt(evt)
                    Object.values(data.labels).filter(f => multi_selected_label.current.map(d => d.idLabel).includes(f.idLabel)).map(d => {
                        d.content = evt;
                    });
                    reDrawOSPLabels(multi_selected_label.current);
                    setForceUpdate(!forceUpdate);
                }, theme: "snow", modules: modules, formats: formats, readOnly: disable_options, style: {
                    'height': '300px',
                    color: (disable_options) ? '#666666' : '',
                    backgroundColor: (disable_options) ? '#cccccc' : '',
                    overflowY: 'scroll'
                } })) : content_image,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.hl')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput_with_right_addon', min: 0, isDisabled: disable_options, value: allLabelHeight(), onChange: evt => {
                            multi_selected_label.current.map(d => d.label_height = +evt);
                            reDrawOSPLabels(multi_selected_label.current);
                            setForceUpdate(!forceUpdate);
                        } },
                        React.createElement(NumberInputField, null),
                        React.createElement(NumberInputStepper, null,
                            React.createElement(NumberIncrementStepper, null),
                            React.createElement(NumberDecrementStepper, null))))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.ll')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput', min: 0, isDisabled: disable_options, value: allLabelWidth(), onChange: evt => {
                            multi_selected_label.current.map(d => d.label_width = +evt);
                            reDrawOSPLabels(multi_selected_label.current);
                            setForceUpdate(!forceUpdate);
                        } },
                        React.createElement(NumberInputField, null),
                        React.createElement(NumberInputStepper, null,
                            React.createElement(NumberIncrementStepper, null),
                            React.createElement(NumberDecrementStepper, null)))))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.cfl')),
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', id: 'form_color_zdt', name: 'form_color_zdt', isDisabled: disable_options, value: (multi_selected_label.current.length === 1) ? multi_selected_label.current[0].color : '#ffffff', onChange: evt => {
                        const val = evt.target.value;
                        multi_selected_label.current.map(d => d.color = val);
                        reDrawOSPLabels(multi_selected_label.current);
                        setForceUpdate(!forceUpdate);
                    } })),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.ft')),
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput_with_right_addon', max: 100, min: 0, step: 1, isDisabled: disable_options, value: allLabelTransparent(), onChange: evt => {
                            const value = +evt;
                            multi_selected_label.current.map(d => d.opacity = value);
                            reDrawOSPLabels(multi_selected_label.current);
                            setForceUpdate(!forceUpdate);
                        } },
                        React.createElement(NumberInputField, null),
                        React.createElement(NumberInputStepper, null,
                            React.createElement(NumberIncrementStepper, null),
                            React.createElement(NumberDecrementStepper, null)))))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('LL.cbl')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', id: 'form_color_border_zdt', name: 'form_color_border_zdt', disabled: !has_open_sankey_plus && !valAllLabelBorderTransparent, value: (multi_selected_label.current.length === 1) ? multi_selected_label.current[0].color_border : '#ffffff', onChange: evt => {
                        const val = evt.target.value;
                        multi_selected_label.current.map(d => d.color_border = val);
                        reDrawOSPLabels(multi_selected_label.current);
                        setForceUpdate(!forceUpdate);
                    } }),
                React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', iconColor: valAllLabelBorderTransparent[1] ? '#78C2AD' : 'white', isDisabled: disable_options, isIndeterminate: valAllLabelBorderTransparent[1], isChecked: valAllLabelBorderTransparent[0], onChange: (evt) => {
                        multi_selected_label.current.map(d => d.transparent_border = evt.target.checked);
                        reDrawOSPLabels(multi_selected_label.current);
                        setForceUpdate(!forceUpdate);
                    } }, t('LL.bt')))));
    return content_menu_zdt;
};
export const ContextZDT = ({ contextMenu, t, applicationData, dict_hook_ref_setter_show_dialog_components, applicationState, ComponentUpdater, reDrawOSPLabels }) => {
    const { data } = applicationData;
    const { pointer_pos, contextualised_zdt } = contextMenu;
    const { multi_selected_label } = applicationState;
    const [zdt_to_contextualise, set_zdt_to_contextualise] = useState();
    const { updateComponentMenuConfigZdt } = ComponentUpdater;
    contextualised_zdt.current = set_zdt_to_contextualise;
    dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_zdt.current;
    let style_c_zdd = '0px 0px auto auto';
    if (zdt_to_contextualise) {
        style_c_zdd = (pointer_pos.current[1] - 20) + 'px auto auto ' + (pointer_pos.current[0] + 10) + 'px';
    }
    const valAllLabelBorderTransparent = IsAllZdtAttrSameValue(data, multi_selected_label.current, 'transparent_border');
    const btn_mask_border = React.createElement(Button, { onClick: () => {
            multi_selected_label.current.forEach(zdt => zdt.transparent_border = !valAllLabelBorderTransparent[0]);
            set_zdt_to_contextualise(undefined);
            reDrawOSPLabels(multi_selected_label.current);
            updateComponentMenuConfigZdt.current.forEach(f => f());
        }, variant: 'contextmenu_button' }, valAllLabelBorderTransparent[0] ? t('LL.display_border') : t('LL.hide_border'));
    const btn_change_color = React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: 'contextmenu_button' },
            React.createElement(Input, { hidden: true, type: 'color', id: 'form_color_zdt', name: 'color_bg_zdd', value: (multi_selected_label.current.length === 1) ? multi_selected_label.current[0].color : '#ffffff', onChange: (evt) => {
                    const val = evt.target.value;
                    multi_selected_label.current.map(d => d.color = val);
                    reDrawOSPLabels(multi_selected_label.current);
                    updateComponentMenuConfigZdt.current.forEach(f => f());
                } }),
            React.createElement("label", { htmlFor: 'form_color_zdt', style: { width: '100%', margin: 0 } }, t('LL.cfl'))));
    const button_open_layout = React.createElement(Button, { onClick: () => {
            dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_zdt.current(true);
            set_zdt_to_contextualise(undefined);
        }, variant: 'contextmenu_button' },
        t('Menu.LL'),
        " ",
        icon_open_modal);
    return zdt_to_contextualise ? React.createElement(Box, { layerStyle: 'context_menu', id: "context_zdd_pop_over", style: { maxWidth: '100%', position: 'absolute', inset: style_c_zdd, zIndex: 4 } },
        React.createElement(ButtonGroup, { orientation: 'vertical', isAttached: true },
            btn_mask_border,
            btn_change_color,
            sep,
            button_open_layout)) : React.createElement(React.Fragment, null);
};
const icon_open_modal = React.createElement(FontAwesomeIcon, { style: { float: 'right' }, icon: faUpRightFromSquare });
export const blur_ZDT_wysiwyg = (r_editor_ZDT) => {
    var _a, _b, _c;
    if (r_editor_ZDT && r_editor_ZDT.current && ((_c = (_b = (_a = d3.select(document.activeElement)) === null || _a === void 0 ? void 0 : _a.attr('class')) === null || _b === void 0 ? void 0 : _b.includes('ql-editor')) !== null && _c !== void 0 ? _c : false)) {
        r_editor_ZDT.current.getEditor().focus();
        r_editor_ZDT.current.getEditor().blur();
    }
};
/**
 *  Function that return content_menu_zdt with JSX to imbricate it in the config menu
 *
 * @param {OSPData} data
 * @param {uiElementsRefType} uiElementsRef
 * @param {boolean} has_open_sankey_plus
 * @param {TFunction} t
 * @param {JSX.Element} content_menu_zdt
 * @return {*}
 */
export const ZDTMenuAsAccordeonItem = ({ data, uiElementsRef, applicationContext, content_menu_zdt }) => {
    // const {ref_nav_item_active,ref_setter_sub_nav_item_active,zdt_accordion_ref}=uiElementsRef
    const { t } = applicationContext;
    return React.createElement(AccordionItem, { style: { 'display': (data.accordeonToShow.includes('LL')) ? 'initial' : 'none' } },
        React.createElement(AccordionButton, { ref: uiElementsRef.zdt_accordion_ref, onClick: () => {
                const scroll_x = window.scrollX;
                const scroll_y = window.scrollY;
                setTimeout(() => {
                    var _a;
                    (_a = document.getElementsByTagName('html')[0]) === null || _a === void 0 ? void 0 : _a.scrollTo(scroll_x, scroll_y);
                }, 50);
            } },
            React.createElement(Box, { as: 'span', layerStyle: 'submenuconfig_entry' }, t('Menu.LL')),
            React.createElement(AccordionIcon, null)),
        React.createElement(AccordionPanel, null, content_menu_zdt));
};
