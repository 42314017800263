import React from 'react';
import { Box, Button, Checkbox, Input, Select } from '@chakra-ui/react';
import { OSTooltip } from '../configmenus/SankeyUtils';
import { MenuDraggable } from '../topmenus/SankeyMenuTop';
export const OpenSankeyDefaultModalePreferenceContent = (t, applicationData, trad, updateMenus, node_function) => {
    const { data, display_nodes } = applicationData;
    const ui = {
        'lang': React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.lang')),
            React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: trad.language, onChange: evt => {
                    trad.changeLanguage((evt.target.value));
                } },
                React.createElement("option", { key: 'francais', value: 'fr' }, "Fran\u00E7ais"),
                React.createElement("option", { key: 'english', value: 'en' }, "English"))),
        'form': [
            React.createElement("h4", null, t('Menu.pref_title_sub_menu')),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                React.createElement(Box, { layerStyle: 'options_2cols' },
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_left', onClick: () => {
                            sessionStorage.removeItem('modepref');
                            data.accordeonToShow = ['MEP'];
                            // ComponentUpdater.updateComponentMenuConfig.current()
                            // ComponentUpdater.updatePreference.current()
                            updateMenus[1](!updateMenus[0]);
                        } }, "Mode Simple"),
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button_right', onClick: () => {
                            sessionStorage.setItem('modepref', 'expert');
                            data.accordeonToShow = ['MEP', 'EN', 'EF', 'ED', 'LL', 'Vis'];
                            // ComponentUpdater.updateComponentMenuConfig.current()
                            // ComponentUpdater.updatePreference.current()
                            updateMenus[1](!updateMenus[0]);
                        } }, "Mode Expert"))),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.accordeonToShow.includes('MEP'), onChange: () => {
                    preferenceCheck('MEP', data);
                    // ComponentUpdater.updateComponentMenuConfig.current()
                    // ComponentUpdater.updatePreference.current()
                    updateMenus[1](!updateMenus[0]);
                } }, t('Menu.MEP')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: true, disabled: true }, t('Menu.Noeuds')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.accordeonToShow.includes('EN'), onChange: () => {
                    preferenceCheck('EN', data);
                    // ComponentUpdater.updateComponentMenuConfig.current()
                    // ComponentUpdater.updatePreference.current()
                    updateMenus[1](!updateMenus[0]);
                } }, t('Menu.EN')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: true, disabled: true }, t('Menu.flux')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.accordeonToShow.includes('EF'), onChange: () => {
                    preferenceCheck('EF', data);
                    // ComponentUpdater.updateComponentMenuConfig.current()
                    // ComponentUpdater.updatePreference.current()
                    updateMenus[1](!updateMenus[0]);
                } }, t('Menu.EF')),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.accordeonToShow.includes('ED'), onChange: () => {
                    preferenceCheck('ED', data);
                    // ComponentUpdater.updateComponentMenuConfig.current()
                    // ComponentUpdater.updatePreference.current()
                    updateMenus[1](!updateMenus[0]);
                } }, t('Menu.ED')),
        ],
        'node_label_sep': React.createElement(OSTooltip, { label: t('Menu.tooltips.node_label_sep') },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.node_label_sep')),
                React.createElement(Input, { variant: 'menuconfigpanel_option_input', value: data.node_label_separator, onChange: evt => {
                        data.node_label_separator = evt.target.value;
                        // ComponentUpdater.updateComponentMenuConfig.current()
                        // ComponentUpdater.updatePreference.current()
                        updateMenus[1](!updateMenus[0]);
                    }, onBlur: () => node_function.RedrawNodes(Object.values(display_nodes)) }))),
    };
    return ui;
};
export const preferenceCheck = (str, data) => {
    sessionStorage.removeItem('modepref');
    if (!data.accordeonToShow.includes(str)) {
        data.accordeonToShow.push(str);
    }
    else {
        const posElemt = data.accordeonToShow.indexOf(str);
        data.accordeonToShow.splice(posElemt, 1);
    }
};
export const ModalPreference = ({ dict_hook_ref_setter_show_dialog_components, ui, t, pointer_pos }) => {
    const content = React.createElement(React.Fragment, null, Object.values(ui).map((d, i) => {
        return React.createElement(React.Fragment, { key: i }, d);
    }));
    return MenuDraggable(dict_hook_ref_setter_show_dialog_components, 'ref_setter_show_modal_preference', content, pointer_pos, t('Menu.title_pref'));
};
