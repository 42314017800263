import * as d3 from 'd3';
import React, { useEffect } from 'react';
import { DeleteLink, deleteSelectedNodeFromData, windowSankey } from '../configmenus/SankeyUtils';
import { ClickSaveDiagram } from '../dialogs/SankeyPersistence';
import { AgregationModal } from './SankeyDrawLayout';
import { RemoveAnimate, DrawGrid, SelectVisualyLinks, DeselectVisualyLinks, DeselectVisualyNodes, SelectVisualyNodes, nodeTransform } from './SankeyDrawFunction';
import LZString from 'lz-string';
import { SvgDragMiddleMouseStart, SvgDragMiddleMouseMove, EventZDDContextMenu } from './SankeyDrawEventFunction';
import { AddDrawNodesEvent } from './SankeyDrawNodes';
import { DeleteGLinks } from './SankeyDrawLinks';
const SankeyDraw = ({ contextMenu, applicationData, animation, applicationState, agregation, ref_alt_key_pressed, GetSankeyMinWidthAndHeight, }) => {
    const { ref_getter_mode_selection, ref_setter_mode_selection } = applicationState;
    // Il faut détruire les tooltips à chaque passage dans le draw
    d3.selectAll('.sankey-tooltip').remove();
    d3.select('body')
        .append('div')
        .style('opacity', 0)
        .attr('class', 'sankey-tooltip');
    window.focus();
    d3.select(window).on('keydown', (event) => {
        if (event.keyCode === 18) {
            ref_alt_key_pressed.current = true;
            window.focus();
        }
    });
    d3.select(window).on('keyup', (event) => {
        if (event.keyCode === 18) {
            ref_alt_key_pressed.current = false;
            window.focus();
        }
    });
    const position = (windowSankey.SankeyToolsStatic ? windowSankey.SankeyToolsStatic : false) ? 'relative' : 'absolute';
    useEffect(() => {
        var _a, _b;
        if (animation.current) {
            return;
        }
        [applicationData.data.width, applicationData.data.height] = GetSankeyMinWidthAndHeight(applicationData);
        RemoveAnimate();
        d3.select('#svg').style('background-color', applicationData.data.couleur_fond_sankey);
        // Permet d'affecter une class au svg selon le mode
        if (ref_getter_mode_selection.current == 's') {
            d3.select(' .opensankey #svg').attr('class', 'mode_selection');
        }
        if (ref_getter_mode_selection.current == 'ln') {
            d3.select(' .opensankey #svg').attr('class', 'mode_add_flux');
        }
        // Disable zoom outside of the sankey draw zone
        d3.select('body')
            .call(d3.zoom()
            .filter(ev => {
            return (ev.ctrlKey || ev.metaKey) && ev.buttons == 0;
        })
            .wheelDelta(ev => {
            return -ev.deltaY * (ev.deltaMode === 1 ? 0.05 : ev.deltaMode ? 1 : 0.002);
        })
            .on('zoom', function () {
            null;
        }))
            .on('dblclick.zoom', null);
        const svgSankey = d3.select('.opensankey #svg');
        svgSankey.style('width', applicationData.data.width + 'px');
        svgSankey.style('height', applicationData.data.height + 'px');
        // Fonction permettant de déplacer les éléments dans la zone de dessin, seulement quand on drag avec le boutons du milieu de la souris
        svgSankey
            .call(d3.drag()
            .subject(Object)
            .filter(evt => {
            evt.stopPropagation();
            evt.preventDefault();
            return d3.select(evt.target).attr('id') == 'svg' && evt.which == 2;
        })
            .on('start', () => {
            // Cache les handles des liens
            SvgDragMiddleMouseStart();
        })
            .on('drag', event => {
            SvgDragMiddleMouseMove(event, applicationData.data);
        })
            .on('end', () => {
            applicationData.set_data(Object.assign({}, applicationData.data));
        }));
        svgSankey.on('contextmenu', (evt) => {
            if (!window.SankeyToolsStatic && d3.select(evt.target).attr('class') == 'mode_selection') {
                return EventZDDContextMenu(evt, contextMenu);
            }
        });
        DrawGrid(applicationData.data);
        const shift_top = ((_a = document.getElementsByClassName('MenuNavigation')[0]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().y) + ((_b = document.getElementsByClassName('MenuNavigation')[0]) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().height);
        d3.select('#svg-container').style('margin-top', shift_top + 'px');
        d3.select(' .opensankey #svg').selectAll('.defsArrow').remove();
        d3.select(' .opensankey #svg').append('defs').attr('class', 'defsArrow');
        d3.selectAll('.navbar').on('mouseup', () => {
            if (ref_getter_mode_selection.current == 'ln') {
                ref_setter_mode_selection.current('s');
            }
        });
        d3.select('.sankey-menu').on('click', e => {
            if (ref_getter_mode_selection.current == 'ln' && d3.select(e.target).attr('class') !== 'accordion-item') {
                ref_setter_mode_selection.current('s');
            }
        });
    });
    let border = '0px';
    if (!(windowSankey.SankeyToolsStatic ? windowSankey.SankeyToolsStatic : false)) {
        border = '2px solid #d3d3d3';
    }
    const width_to_display = ((applicationData.data.width) ? applicationData.data.width : window.innerWidth * 0.975);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "span12", id: 'visualization_div' },
            React.createElement("div", { id: "svg-container", className: 'opensankey', style: { 'position': position } },
                React.createElement("div", { className: 'scroll_zone' },
                    React.createElement("svg", { id: 'svg', "transform-origin": '0 0', style: { margin: '10px', 'height': applicationData.data.height, 'width': width_to_display, 'border': border, boxShadow: '2px 2px 2px #d3d3d3,-2px -2px 2px #d3d3d3' }, preserveAspectRatio: "xMidYMin meet" },
                        React.createElement("g", { className: 'grid', id: 'grid' }),
                        React.createElement("g", { className: 'g_links', id: 'g_links', style: { 'position': position } }),
                        React.createElement("g", { className: 'g_nodes', id: 'g_nodes', style: { 'position': position } }),
                        React.createElement("g", { className: 'g_link_handles', id: 'g_link_handles' }),
                        React.createElement("g", { className: 'g_legend', id: 'g_legend' }))))),
        React.createElement(AgregationModal, { agregationRef: agregation, applicationData: applicationData })));
};
// Function used to handle event when some key are pressed
// If the keyboard arrows are pressed it shift the selected nodes according to the arrow direction and the grid square
// Escape key open and close configuration sankey menu
// ctrl + s save a view of the data
// Delete key allow us to delete selected elments (nodes,links, free label)
export const keyHandler = (applicationData, uiElementsRef, contextMenu, e, applicationState, closeAllMenu, ref_alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, applicationContext, node_function, applicationDraw) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { data } = applicationData;
    const { multi_selected_nodes, multi_selected_links, ref_setter_mode_selection } = applicationState;
    const { updateComponentMenuConfigNode, updateComponentMenuConfigLink, updateComponentMenuConfigNodeAppearence } = ComponentUpdater;
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key) &&
        (((((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'INPUT') ?
            d3.select(document.activeElement).attr('value') === 'menuConfigButton' :
            true) &&
            (!((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.className.includes('ql-editor'))))) {
        // Deplace les noeuds sélectionné avec les flèches du clavier, cependant ne ce déplace pas si jamais on utilise les flèches pour dépalcer le curseur dans un input
        // (exemples : le input de la largeur minimal d'un noeud)
        if (e.key == 'ArrowUp') {
            Object.values(data.nodes).filter(f => multi_selected_nodes.current.map(d => {
                if (d != undefined) {
                    return d.idNode;
                }
            }).includes(f.idNode)).map(d => {
                // if (ReturnValueNode(data,d,'position') === 'relative') {
                //   return
                // }
                d.y = d.y - data.grid_square_size;
                let y_max = 0;
                Object.values(data.nodes).map(d => {
                    y_max = (d.y > y_max) ? d.y : y_max;
                });
                //Diminue hauteur svg si le noeud est près du bord
                if (y_max < data.height - 100 && data.height - 100 >= window.innerHeight) {
                    data.height -= 90;
                }
            });
        }
        else if (e.key == 'ArrowDown') {
            Object.values(data.nodes).filter(f => multi_selected_nodes.current.map(d => {
                if (d != undefined) {
                    return d.idNode;
                }
            }).includes(f.idNode)).map(d => {
                // if (ReturnValueNode(data,d,'position') === 'relative') {
                //   return
                // }
                d.y = d.y + data.grid_square_size;
                //Augumente hauteur svg si le noeud est près du bord
                if (d.y > data.height - 100) {
                    data.height += 100;
                }
            });
        }
        else if (e.key == 'ArrowLeft') {
            Object.values(data.nodes).filter(f => multi_selected_nodes.current.map(d => {
                if (d != undefined) {
                    return d.idNode;
                }
            }).includes(f.idNode)).map(d => {
                // if (ReturnValueNode(data,d,'position') === 'relative') {
                //   return
                // }
                d.x = d.x - data.grid_square_size;
                //Diminue largeur svg si le noeud est près du bord
                if (d.x < data.width - 100 && data.width - 100 >= window.innerWidth - 50) {
                    data.width -= 50;
                }
            });
        }
        else if (e.key == 'ArrowRight') {
            Object.values(data.nodes).filter(f => multi_selected_nodes.current.map(d => {
                if (d != undefined) {
                    return d.idNode;
                }
            }).includes(f.idNode)).map(d => {
                // if (ReturnValueNode(data,d,'position') === 'relative') {
                //   return
                // }
                d.x = d.x + data.grid_square_size;
                //Augumente largeur svg si le noeud est près du bord
                if (d.x > data.width - 100) {
                    data.width += 100;
                }
            });
        }
        let link_to_update = [];
        multi_selected_nodes.current.forEach(n => {
            link_to_update = link_to_update.concat(n.outputLinksId);
            link_to_update = link_to_update.concat(n.inputLinksId);
            d3.selectAll('#ggg_' + n.idNode).attr('transform', nodeTransform(applicationData, n, link_function, true));
        });
        link_to_update = [...new Set(link_to_update)];
        link_function.RedrawLinks(Object.values(applicationData.display_links));
    }
    else if (e.key == 'Escape') {
        ref_setter_mode_selection.current('s');
        applicationState.ref_getter_mode_selection.current = 's';
        d3.select(' .opensankey #svg').attr('class', 'mode_selection');
        // Visualy deselect nodes then deselect in the app data
        multi_selected_nodes.current.forEach(d => {
            DeselectVisualyNodes(d);
        });
        multi_selected_nodes.current = [];
        multi_selected_links.current.forEach(l => {
            DeselectVisualyLinks(l);
        });
        multi_selected_links.current = [];
        closeAllMenu();
        AddDrawNodesEvent(contextMenu, applicationData, uiElementsRef, applicationState, applicationContext, ref_alt_key_pressed, accept_simple_click, link_function, ComponentUpdater, dict_hook_ref_setter_show_dialog_components, node_function, applicationDraw.GetSankeyMinWidthAndHeight, applicationDraw.resizeCanvas);
        updateComponentMenuConfigNode.current();
        updateComponentMenuConfigNodeAppearence.current();
        updateComponentMenuConfigLink.current();
    }
    else if (e.key == 'Delete' && (!((_c = document.activeElement) === null || _c === void 0 ? void 0 : _c.className.includes('ql-editor')))) {
        if (((_d = document.activeElement) === null || _d === void 0 ? void 0 : _d.tagName) !== 'INPUT' || d3.select(document.activeElement).attr('value') == 'menuConfigButton') {
            DeleteGLinks(multi_selected_links.current.map(l => l.idLink));
            multi_selected_links.current.forEach(el => {
                DeleteLink(data, el);
            });
            deleteSelectedNodeFromData(applicationData, applicationState);
            multi_selected_nodes.current = [];
            multi_selected_links.current = [];
            node_function.recomputeDisplayedElement();
            node_function.RedrawNodes(Object.values(applicationData.display_nodes));
            link_function.RedrawLinks(Object.values(applicationData.display_links));
            updateComponentMenuConfigNode.current();
            updateComponentMenuConfigLink.current();
        }
    }
    else if (e.key == 'a' && e.ctrlKey) {
        e.preventDefault();
        multi_selected_nodes.current = Object.values(data.nodes);
        multi_selected_nodes.current.forEach(n => {
            SelectVisualyNodes(n);
        });
        multi_selected_links.current = Object.values(data.links);
        multi_selected_links.current.forEach(l => {
            SelectVisualyLinks(l);
        });
        ComponentUpdater.updateComponentMenuConfigNode.current();
        ComponentUpdater.updateComponentMenuConfigLink.current();
    }
    else if (e.key == 'Enter' && ((_e = document.activeElement) === null || _e === void 0 ? void 0 : _e.tagName) == 'INPUT' && (['form-control', 'chakra-numberinput__field', 'chakra-input', 'input_label'].some(r => { var _a; return (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.className.includes(r); }))) {
        document.activeElement.blur();
    }
    else if (e.key == 's' && e.ctrlKey && !e.shiftKey) {
        e.preventDefault();
        applicationData.function_on_wait.current = () => {
            localStorage.setItem('data', LZString.compress(JSON.stringify(data)));
            localStorage.setItem('last_save', 'true');
            ComponentUpdater.updateComponenSaveInCache.current(true);
        };
        dict_hook_ref_setter_show_dialog_components.ref_lauchToast.current();
    }
    else if ((e.key == 's' && e.ctrlKey && e.shiftKey) || (e.key == 'S' && e.ctrlKey && e.shiftKey)) {
        e.preventDefault();
        ClickSaveDiagram(applicationData, applicationData.data, applicationState, { mode_save: true, mode_visible_element: false });
    }
    else if ((e.key === 'f') && !e.ctrlKey && ((_f = document.activeElement) === null || _f === void 0 ? void 0 : _f.tagName) !== 'INPUT') {
        if ((!((_h = (_g = d3.select(document.activeElement)) === null || _g === void 0 ? void 0 : _g.attr('class')) === null || _h === void 0 ? void 0 : _h.includes('ql-editor')))) {
            e.preventDefault();
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            }
            else if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }
};
export default SankeyDraw;
