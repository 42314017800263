import React from 'react';
import { Checkbox, Tag } from '@chakra-ui/react';
import { DeleteLink, ReturnValueLink, IsAllNodeAttrSameValue, ReturnValueNode, AssignNodeValueToCorrectVar, } from './FunctionOSTyped';
/**
 * Function that search and hide node that have 1 input link, 1 output link and have hideLoneNode at true
* To do so a link is created and start from the source of the input link to the target of the output link
* finally we hide the node by putting it visibility to false
*/
export const hiddeLoneNodes = (applicationData) => {
    const { data, display_nodes, display_links, set_data } = applicationData;
    const list_key_link_displayed = Object.values(display_links).map(l => l.idLink);
    const displayed_product = Object.values(display_nodes).filter(n => {
        const is_to_hide = ReturnValueNode(data, n, 'hide_lone_node');
        const has_only_one_displayed_inputLink = Object.values(n.inputLinksId).filter(l => {
            if (data.links[l] == undefined) {
                return;
            }
            return list_key_link_displayed.includes(data.links[l].idLink) && !ReturnValueLink(data, data.links[l], 'recycling');
        }).length === 1;
        const has_only_one_displayed_outputLink = Object.values(n.outputLinksId).filter(l => {
            if (data.links[l] == undefined) {
                return;
            }
            return list_key_link_displayed.includes(data.links[l].idLink) && !ReturnValueLink(data, data.links[l], 'recycling');
        }).length === 1;
        return is_to_hide && has_only_one_displayed_inputLink && has_only_one_displayed_outputLink;
    });
    displayed_product.forEach(n => {
        const src = Object.values(n.inputLinksId).filter(l => list_key_link_displayed.includes(data.links[l].idLink))[0];
        const trgt = Object.values(n.outputLinksId).filter(l => list_key_link_displayed.includes(data.links[l].idLink))[0];
        const n_l = JSON.parse(JSON.stringify(data.links[src]));
        n_l.idSource = data.links[src].idSource;
        n_l.idTarget = data.links[trgt].idTarget;
        n_l.idLink = 'link-for-hidded-node-' + n.idNode + '-hidded';
        if (!Object.keys(data.links).includes(n_l.idLink)) {
            const ind_in_src = data.nodes[data.links[src].idSource].outputLinksId.indexOf(src);
            const ind_in_trgt = data.nodes[data.links[trgt].idTarget].inputLinksId.indexOf(trgt);
            data.nodes[data.links[src].idSource].outputLinksId.splice(ind_in_src, 0, n_l.idLink);
            data.nodes[data.links[trgt].idTarget].inputLinksId.splice(ind_in_trgt, 0, n_l.idLink);
            data.links[n_l.idLink] = n_l;
            data.linkZIndex.push(n_l.idLink);
            set_data(Object.assign({}, data));
        }
        delete display_nodes[n.idNode];
        delete display_links[src];
        delete display_links[trgt];
    });
};
//Function to restore hidden node when we deselect the hideLonNode property
export const searchAndRestoreLoneNodes = (data, set_data, dev_token) => {
    const link_tmp = Object.values(data.links).filter(l => l.idLink.includes('link-for-hidded-node-'));
    const dev_data = data;
    const link_to_delete = [];
    if (dev_token) {
        Object.values(dev_data.nodes).filter(n => !ReturnValueNode(dev_data, n, 'hide_lone_node')).forEach(n => {
            link_tmp.filter(l => l.idLink.includes('link-for-hidded-node-' + n.idNode + '-hidded')).forEach(l => {
                link_to_delete.push(l);
            });
        });
    }
    else {
        link_tmp.filter(l => l.idLink.includes('link-for-hidded-node-')).forEach(l => {
            link_to_delete.push(l);
        });
    }
    if (link_to_delete.length > 0) {
        link_to_delete.forEach(l => DeleteLink(data, l));
        set_data(Object.assign({}, data));
    }
};
export const is_all_node_dev_attr_same_value = (m_s_n, k) => {
    if (m_s_n.length === 0) {
        return [null, null];
    }
    const first_value = m_s_n[0][k];
    let all_same = true;
    m_s_n.forEach(l => {
        all_same = l[k] !== first_value ? false : all_same;
    });
    return (all_same ? [first_value, false] : [0, true]);
};
export const MenuConfigHideLoneNode = ({ t, data, set_data, applicationState, menu_for_style }) => {
    const { multi_selected_nodes, ref_selected_style_node } = applicationState;
    const parameter_to_modify = (menu_for_style) ? data.style_node : data.nodes;
    const selected_parameter = (menu_for_style) ? [data.style_node[ref_selected_style_node.current]] : multi_selected_nodes.current;
    const list_of_key = ['hide_lone_node'];
    const list_value = IsAllNodeAttrSameValue(data, selected_parameter, list_of_key, menu_for_style);
    return (React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', iconColor: list_value['hide_lone_node'][1] ? '#78C2AD' : 'white', isIndeterminate: list_value['hide_lone_node'][1], isChecked: list_value['hide_lone_node'][0], onChange: (evt) => {
            Object.values(parameter_to_modify)
                .filter(f => selected_parameter.map(d => d.idNode).includes(f.idNode))
                .forEach(d => {
                AssignNodeValueToCorrectVar(d, 'hide_lone_node', evt.target.checked, menu_for_style);
            });
            set_data(Object.assign({}, data));
        } },
        t('Noeud.apparence.HideAlone'),
        React.createElement(Tag, { colorScheme: 'teal', style: { marginLeft: 'auto' } }, "Dev")));
};
