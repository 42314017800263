import React, { useRef, useState } from 'react';
import { FaPlus, FaMinus, FaChevronDown, } from 'react-icons/fa';
import { Box, Button, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { CutName, DefaultNodeStyle, DefaultLinkStyle } from '../configmenus/SankeyUtils';
import { SankeyWrapperConfigInModalOrMenu } from '../configmenus/SankeyMenuConfigurationNodesAttributes';
import { MenuConfigurationLinksAppearence } from '../configmenus/SankeyMenuConfigurationLinksAppearence';
import { MenuDraggable } from '../topmenus/SankeyMenuTop';
export const SankeyModalStyleNode = ({ applicationContext, applicationData, dict_hook_ref_setter_show_dialog_components, ref_selected_style_node, ComponentUpdater, node_function, pointer_pos, node_attribute_tab }) => {
    const { t } = applicationContext;
    const { data } = applicationData;
    const { updateComponentMenuConfigNodeAppearence } = ComponentUpdater;
    const { RedrawNodes } = node_function;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [selected_style_node, set_selected_style_node] = useState('default');
    ref_selected_style_node.current = selected_style_node;
    if (data.style_node && !Object.keys(data.style_node).includes(selected_style_node)) {
        set_selected_style_node('default');
    }
    const has_product_or_sector = Object.values(data.nodes).filter(n => 'Type de noeud' in n.tags).length > 0;
    const ref_input_name = useRef(null);
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menustylepanel_row_droplist' },
            React.createElement(Button, { variant: 'menuconfigpanel_add_button', onClick: () => {
                    const new_style = DefaultNodeStyle();
                    new_style.name = 'New Style';
                    const new_id = 'style_node_' + String(new Date().getTime());
                    new_style.idNode = new_id;
                    data.style_node[new_id] = new_style;
                    updateComponentMenuConfigNodeAppearence.current();
                    ref_selected_style_node.current = new_style.idNode;
                    set_selected_style_node(new_style.idNode);
                    setForceUpdate(!forceUpdate);
                    ref_input_name.current.value = data.style_node[new_id].name;
                } },
                React.createElement(FaPlus, null)),
            React.createElement(Menu, null,
                React.createElement(MenuButton, { as: Button, variant: 'menuconfigpanel_option_button', rightIcon: React.createElement(FaChevronDown, null) }, (selected_style_node !== '') ?
                    CutName(data.style_node[selected_style_node].name, 30) :
                    'Choix Style'),
                React.createElement(MenuList, null, Object
                    .keys(data.style_node)
                    .filter(key => has_product_or_sector ? true : data.style_node[key].idNode !== 'NodeSectorStyle' && data.style_node[key].idNode !== 'NodeProductStyle' && data.style_node[key].idNode !== 'NodeImportStyle' && data.style_node[key].idNode !== 'NodeExportStyle')
                    .map((d, i) => {
                    return (React.createElement(MenuItem, { key: i, onClick: () => {
                            ref_selected_style_node.current = d;
                            set_selected_style_node(d);
                            ref_input_name.current.value = data.style_node[d].name;
                            updateComponentMenuConfigNodeAppearence.current();
                            setForceUpdate(!forceUpdate);
                        } }, data.style_node[d].name));
                }))),
            React.createElement(Button, { variant: 'menuconfigpanel_del_button', isDisabled: (selected_style_node === 'default'), onClick: () => {
                    Object.values(data.nodes).filter(n => n.style == selected_style_node).forEach(n => n.style = 'default');
                    delete data.style_node[selected_style_node];
                    const new_style = (Object.keys(data.style_node).length > 0) ? Object.keys(data.style_node)[0] : '';
                    set_selected_style_node(new_style);
                    ref_selected_style_node.current = new_style;
                    updateComponentMenuConfigNodeAppearence.current();
                    RedrawNodes(Object.values(applicationData.display_nodes));
                    ComponentUpdater.updateComponenSaveInCache.current(false);
                    setForceUpdate(!forceUpdate);
                } },
                React.createElement(FaMinus, null))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols', gridTemplateColumns: '1fr 7fr' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', textStyle: 'h3' }, t('Menu.ns')),
            React.createElement(Box, null,
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(Input, { variant: 'menuconfigpanel_option_input', disabled: (selected_style_node === 'default') ? true : false, ref: ref_input_name, defaultValue: data.style_node[selected_style_node].name, onBlur: () => {
                            var _a, _b;
                            data.style_node[selected_style_node].name = (_b = (_a = ref_input_name.current) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
                            setForceUpdate(!forceUpdate);
                            updateComponentMenuConfigNodeAppearence.current();
                            ComponentUpdater.updateComponenSaveInCache.current(false);
                        } })))),
        React.createElement(SankeyWrapperConfigInModalOrMenu, { menu_to_wrap: node_attribute_tab, for_modal: true, idTab: 'node_attr' }));
    return MenuDraggable(dict_hook_ref_setter_show_dialog_components, 'ref_setter_show_style_node', content, pointer_pos, t('Menu.esn'));
};
//Modal et fonctions pour l'edition et affectation des style de flux
export const SankeyModalStyleLink = ({ applicationContext, applicationData, applicationState, dict_hook_ref_setter_show_dialog_components, pointer_pos, additional_link_appearence_items, link_function, ComponentUpdater }) => {
    const { data } = applicationData;
    const { t } = applicationContext;
    const { ref_selected_style_link } = applicationState;
    const { updateComponentMenuConfigLink } = ComponentUpdater;
    const [selected_style_link, set_selected_style_link] = useState('default');
    const [forceUpdate, setForceUpdate] = useState(false);
    const ref_input_name = useRef(null);
    ref_selected_style_link.current = selected_style_link;
    if (data.style_link && !Object.keys(data.style_link).includes(selected_style_link)) {
        // Protection if style is not existing (issue with old files)
        set_selected_style_link('default');
    }
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menustylepanel_row_droplist' },
            React.createElement(Button, { variant: 'menuconfigpanel_add_button', onClick: () => {
                    const new_style = DefaultLinkStyle();
                    new_style.name = 'New Style';
                    const new_id = 'style_link_' + String(new Date().getTime());
                    new_style.idLink = new_id;
                    data.style_link[new_id] = new_style;
                    updateComponentMenuConfigLink.current();
                    set_selected_style_link(new_style.idLink);
                    ref_input_name.current.value = data.style_link[new_id].name;
                } },
                React.createElement(FaPlus, null)),
            React.createElement(Menu, null,
                React.createElement(MenuButton, { as: Button, variant: 'menuconfigpanel_option_button', rightIcon: React.createElement(FaChevronDown, null) }, (selected_style_link !== '') ?
                    CutName(data.style_link[selected_style_link].name, 30) :
                    'Choix Style'),
                React.createElement(MenuList, null, Object
                    .keys(data.style_link)
                    .map((d, i) => React.createElement(MenuItem, { key: i, onClick: () => {
                        ref_input_name.current.value = data.style_link[d].name;
                        set_selected_style_link(d);
                    } }, data.style_link[d].name)))),
            React.createElement(Button, { variant: 'menuconfigpanel_del_button', isDisabled: (selected_style_link === 'default'), onClick: () => {
                    Object.values(data.links).filter(l => l.style == selected_style_link).forEach(l => l.style = 'default');
                    delete data.style_link[selected_style_link];
                    set_selected_style_link('default');
                    ref_selected_style_link.current = 'default';
                    updateComponentMenuConfigLink.current();
                    link_function.RedrawLinks(Object.values(applicationData.display_links));
                } },
                React.createElement(FaMinus, null))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols', gridTemplateColumns: '1fr 7fr' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', textStyle: 'h3' }, t('Menu.ns')),
            React.createElement(Box, null,
                React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                    React.createElement(Input, { ref: ref_input_name, variant: 'menuconfigpanel_option_input', disabled: (selected_style_link === 'default') ? true : false, defaultValue: (selected_style_link !== '') ? data.style_link[selected_style_link].name : '', onBlur: () => {
                            var _a, _b;
                            data.style_link[selected_style_link].name = (_b = (_a = ref_input_name.current) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
                            setForceUpdate(!forceUpdate);
                            updateComponentMenuConfigLink.current();
                        } })))),
        React.createElement(MenuConfigurationLinksAppearence, { applicationData: applicationData, applicationState: applicationState, applicationContext: applicationContext, additional_link_appearence_items: additional_link_appearence_items, menu_for_style: true, link_function: link_function, ComponentUpdater: ComponentUpdater }));
    return MenuDraggable(dict_hook_ref_setter_show_dialog_components, 'ref_setter_show_style_link', content, pointer_pos, t('Menu.esf'));
};
