import React from 'react';
import { UploadExemple } from '../dialogs/SankeyPersistence';
import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
/**
 * Description placeholder
 *
 * @param {ExempleItemTypes} { exemple_menu, url_prefix, data, set_data, current_path, multi_selected_nodes, multi_selected_links,multi_selected_label,launch}
 * @returns {*}
 */
export const ExempleItem = ({ applicationContext, applicationData, applicationState, exemple_menu, current_path, launch, Reinitialization }) => {
    const { data, set_data, convert_data, get_default_data } = applicationData;
    const { url_prefix } = applicationContext;
    const { multi_selected_nodes, multi_selected_links } = applicationState;
    let content = React.createElement(React.Fragment, null);
    let item = Object.keys(exemple_menu).map((key, index) => {
        const tmp_title = key.replaceAll('_', '__').split('__');
        if (tmp_title.length > 1) {
            tmp_title.shift();
        }
        let title = tmp_title.join(' ');
        if (title === 'Formations') {
            title = 'Démos';
        }
        const the_current_path = current_path !== '' ? current_path + '/' + key : key;
        if (key === 'Etude' || key === 'Démos') {
            if (typeof exemple_menu[key] === 'string') {
                return React.createElement(React.Fragment, null);
            }
            return React.createElement(ExempleItem, { applicationContext: applicationContext, applicationData: applicationData, applicationState: applicationState, exemple_menu: exemple_menu[key], current_path: the_current_path, launch: launch, Reinitialization: Reinitialization, initial_list: false });
        }
        if (typeof exemple_menu[key] === 'string') {
            return React.createElement(React.Fragment, null);
        }
        if (key === 'Files') {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement(Menu, { variant: 'menu_subnav_item_demo', placement: 'right-start', key: index, id: key },
            React.createElement(MenuButton, null,
                React.createElement(Box, { gridColumn: '1' }, title),
                React.createElement(Box, { gridColumn: "2", height: "1rem", width: "1rem" },
                    React.createElement(ChevronRightIcon, { style: { 'height': '1rem', 'width': '1rem' } }))),
            React.createElement(MenuList, null,
                React.createElement(ExempleItem, { applicationContext: applicationContext, applicationData: applicationData, applicationState: applicationState, exemple_menu: exemple_menu[key], current_path: the_current_path, launch: launch, Reinitialization: Reinitialization, initial_list: false }))));
    });
    if ('Files' in exemple_menu) {
        const files_item = exemple_menu['Files'].map((item, index) => {
            // let path = current_path+'/sankey/'+item
            // if (item.includes('.xlsx')) {
            const path = current_path + '/' + item;
            //}
            return (React.createElement(MenuItem, { key: index, onClick: () => {
                    multi_selected_nodes.current = [];
                    multi_selected_links.current = [];
                    if (path.includes('xlsx')) {
                        launch(path);
                    }
                    UploadExemple(path, url_prefix, data, set_data, Reinitialization, convert_data, get_default_data);
                    // UploadExemple(
                    //   path, url_prefix, data, set_data,Reinitialization,convert_data,get_default_data
                    // )}
                } }, item.includes('xlsx') ? item.includes('reconciled') ? item.split('.x')[0].replace(/_/g, ' ').replace('reconciled', ' excel') : item.split('.x')[0].replace(/_/g, ' ') + ' excel'
                : item.includes('json') ? item.replace(/_/g, ' ').replace(' layout.json', ' sankey') : item.replace('afmsankey_0.9.0.', '')));
        });
        item = [...item, ...files_item];
        // content=<>
        //   {list_item}
        // </>
    }
    content = React.createElement(React.Fragment, null, item);
    return content;
};
export default ExempleItem;
