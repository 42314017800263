import { defineStyle } from '@chakra-ui/react'


export const buttongroup_base_style = defineStyle({
  width:'40px',
  height:'120px',
  right:0,
  // top:(window.innerHeight/2)-120,
 	position:'fixed',
  zIndex:100, 
})

export const buttongroup_sideBar = defineStyle({

})
