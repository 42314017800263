import React, { useState } from 'react';
import { Box, Checkbox, Select, TabPanel } from '@chakra-ui/react';
/**
   * Tab that handle tag association to nodes, a nodes can have tags from the same grouptag or from different group
   * To visaulize nodes according to their tag associated, the groupTags must be at least have it banner in mode one or mutliple
   * then in the nodes filter button, select the groupTag you want to apply and in the dropdown select the node/nodes you want to see
   *
   * @type {*}
   */
export const SankeyMenuConfigurationNodesTags = ({ applicationContext, applicationData, applicationState, node_function, link_function, ComponentUpdater, menu_for_modal }) => {
    const { t } = applicationContext;
    const { data } = applicationData;
    const { multi_selected_nodes } = applicationState;
    const [tags_group_key, set_tags_group_key] = useState(Object.keys(data.nodeTags).length > 0 ? Object.keys(data.nodeTags)[0] : '');
    const [forceUpdate, setForceUpdate] = useState(false);
    const tags_visible = Object.keys(data.nodeTags).length > 0;
    if ((tags_group_key == '' && Object.keys(data.nodeTags).length > 0) || (!Object.keys(data.nodeTags).includes(tags_group_key) && Object.keys(data.nodeTags).length > 0)) {
        set_tags_group_key(Object.keys(data.nodeTags)[0]);
    }
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Menu.EN')),
        React.createElement(Select, { variant: 'menuconfigpanel_option_select', value: tags_group_key, onChange: (evt) => set_tags_group_key(evt.target.value) }, Object
            .entries(data.nodeTags)
            .map((tags_group, i) => React.createElement("option", { key: i, value: tags_group[0] }, tags_group[1].group_name))),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, tags_visible && tags_group_key != '' && Object.keys(data.nodeTags).includes(tags_group_key) ?
            Object
                .entries(data.nodeTags[tags_group_key].tags)
                .map(tags => {
                const allChecked = IsAllNodeTagsSame(multi_selected_nodes.current, tags[0], tags_group_key);
                return (React.createElement(Checkbox, { variant: 'menuconfigpanel_tag_checkbox', isIndeterminate: allChecked[1], isChecked: allChecked[0], onChange: (evt) => {
                        const visible = evt.target.checked;
                        Object
                            .values(data.nodes)
                            .filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode))
                            .map(d => {
                            if (visible) {
                                if (!d.tags[tags_group_key]) {
                                    d.tags[tags_group_key] = [];
                                }
                                d.tags[tags_group_key].push(tags[0]);
                                // If the groue tage is 'Type de noeud' then we change the style
                                // to style of product or sector
                                if (tags_group_key === 'Type de noeud') {
                                    if (tags[0] === 'secteur') {
                                        d.style = 'NodeSectorStyle';
                                    }
                                    else if (tags[0] === 'produit') {
                                        d.style = 'NodeProductStyle';
                                    }
                                    else if (tags[0] === 'echange') {
                                        if (d.inputLinksId.length === 0) {
                                            d.style = 'NodeImportStyle';
                                            d.outputLinksId.forEach(lid => data.links[lid].style = 'LinkImportStyle');
                                        }
                                        else {
                                            d.style = 'NodeExportStyle';
                                            d.inputLinksId.forEach(lid => data.links[lid].style = 'LinkExportStyle');
                                        }
                                    }
                                }
                            }
                            else {
                                // Remove deselected tag from array of selected for the groupe tag tags_group_key of selected nodes
                                d.tags[tags_group_key].splice(d.tags[tags_group_key].indexOf(tags[0]), 1);
                                // If the groue tage is 'Type de noeud' then we change the style
                                // to style of product or sector according to tag still affected
                                // if neither 'produit' or 'secteur' are affected the change style to default
                                if (tags_group_key === 'Type de noeud' && d.tags[tags_group_key].length == 0) {
                                    d.style = 'default';
                                }
                                else if ((tags_group_key === 'Type de noeud') &&
                                    (d.tags[tags_group_key].includes('secteur') || d.tags[tags_group_key].includes('produit'))) {
                                    if (tags[0] === 'secteur') {
                                        d.style = 'NodeProductStyle';
                                    }
                                    else if (tags[0] === 'produit') {
                                        d.style = 'NodeSectorStyle';
                                    }
                                    else if (tags[0] === 'echange') {
                                        if (d.inputLinksId.length === 0) {
                                            d.style = 'NodeImportStyle';
                                            d.outputLinksId.forEach(lid => data.links[lid].style = 'LinkImportStyle');
                                        }
                                        else {
                                            d.style = 'NodeExportStyle';
                                            d.inputLinksId.forEach(lid => data.links[lid].style = 'LinkExportStyle');
                                        }
                                    }
                                }
                            }
                        });
                        setForceUpdate(!forceUpdate);
                        node_function.RedrawNodes(multi_selected_nodes.current);
                        let link_to_update = [];
                        multi_selected_nodes.current.forEach(n => {
                            link_to_update = link_to_update.concat(n.outputLinksId);
                            link_to_update = link_to_update.concat(n.inputLinksId);
                        });
                        link_to_update = [...new Set(link_to_update)];
                        link_function.RedrawLinks(Object.values(applicationData.display_links).filter(l => link_to_update.includes(l.idLink)));
                        ComponentUpdater.updateComponenSaveInCache.current(false);
                    } }, tags[1].name));
            }) :
            (React.createElement(React.Fragment, null))));
    return menu_for_modal ? content :
        // <><Tab>
        //   <Box
        //     layerStyle='submenuconfig_tab'
        //   >
        //     {t('Noeud.tabs.tags')}
        //   </Box>
        // </Tab>,
        React.createElement(TabPanel, null, content);
};
// Check if all value of the attribute "k" is the same in the selected nodes (or selected style)
// If the value come from local attribute or the style of the node doesn't matter, we look only the value
const IsAllNodeTagsSame = (m_s_n, key_tag, key_grp_tag) => {
    // store_value : variable that contain an array forEach key we are looking for
    // Each array contain in first position the value of the selected nodes attribute
    // In second position it contain a boolean that return true if all selected nodes have the same value for the key
    let store_value = [false, false];
    if (m_s_n.length > 0) {
        // For each selected nodes
        m_s_n.map((d, i) => {
            const val = (key_grp_tag in d.tags && d.tags[key_grp_tag].includes(key_tag));
            if (i === 0) {
                store_value = [val, false];
            }
            else {
                store_value[1] = val !== store_value[0] ? true : store_value[1];
            }
        });
    }
    else {
        store_value = [false, false];
    }
    return store_value;
};
