import React, { useState } from 'react';
import { Box, Checkbox, Select, TabPanel } from '@chakra-ui/react';
import { updateLinkTagValue } from './SankeyUtils';
export const MenuConfigurationLinksTags = ({ applicationData, applicationState, applicationContext, menu_for_modal, ComponentUpdater, node_function, link_function }) => {
    const { data } = applicationData;
    const { multi_selected_links } = applicationState;
    const { t } = applicationContext;
    const [forceUpdate, setForceUpdate] = useState(false);
    const newEntries = new Map(Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
        return (Object.keys(dataTag.tags).length > 0) ? [
            dataTagKey,
            Object.entries(dataTag.tags).filter(tag => tag[1].selected).length > 0 ? Object.entries(dataTag.tags).filter(tag => tag[1].selected)[0][0] : Object.keys(dataTag.tags)[0]
        ] : ['n', 'n'];
    }));
    const dataTagsSelected = Object.fromEntries(newEntries);
    const [tags_group_key, set_tags_group_key] = useState(Object.keys(data.fluxTags).length > 0 ? Object.keys(data.fluxTags)[0] : '');
    const [data_tags_selected, set_data_tags_selected] = useState(dataTagsSelected);
    if (Object.keys(data_tags_selected).length !== Object.keys(dataTagsSelected).length) {
        set_data_tags_selected(dataTagsSelected);
    }
    const { fluxTags } = data;
    const tags_visible = Object.keys(fluxTags).length > 0;
    const ValueSelectedParameter = () => {
        if (multi_selected_links.current.length == 0) {
            return {};
        }
        else {
            if (Object.keys(data.links).length === 0 || !(multi_selected_links.current[0].idLink in data.links)) {
                let val = JSON.parse(JSON.stringify(Object(multi_selected_links.current[0].value)));
                Object.values(data_tags_selected).map(tag_selected => {
                    if (val[tag_selected] === undefined) {
                        val[tag_selected] = {};
                    }
                    val = val[tag_selected];
                });
                return val;
            }
            let val = JSON.parse(JSON.stringify(Object(data.links[multi_selected_links.current[0].idLink].value)));
            Object.values(data_tags_selected).map(tag_selected => {
                if (val[tag_selected] === undefined) {
                    val[tag_selected] = { 'display_value': '', tags: {}, value: 0 };
                }
                val = val[tag_selected];
            });
            return val;
        }
    };
    // Si le tags_group_key n'est pas (qu'il soit vide ou différent) dans la liste des keys de fluxTags, alors on met à jour en prenant le premier
    if (!Object.keys(fluxTags).includes(tags_group_key) && Object.keys(fluxTags).length > 0) {
        set_tags_group_key(Object.keys(fluxTags)[0]);
    }
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Menu.EF')),
        React.createElement(Select, { variant: 'menuconfigpanel_option_select', onChange: (evt) => {
                set_tags_group_key(evt.target.value);
                setForceUpdate(!forceUpdate);
            }, value: tags_group_key }, Object.entries(fluxTags).map((tags_group, i) => React.createElement("option", { key: i, value: tags_group[0] }, tags_group[1].group_name))), //Définition des valeurs selon les paramètre dataTags
        Object.entries(data.dataTags).map(([dataTagKey, dataTag]) => {
            if (Object.keys(dataTag.tags).length != 0) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, dataTag.group_name),
                    React.createElement(Select, { variant: 'menuconfigpanel_option_select', name: dataTagKey, value: data_tags_selected[dataTagKey], onChange: (evt) => {
                            //Modifie les paramètres selectionnés
                            const { name, value } = evt.target;
                            set_data_tags_selected(prevState => (Object.assign(Object.assign({}, prevState), { [name]: value })));
                            setForceUpdate(!forceUpdate);
                        } }, Object.entries(dataTag.tags).map(([tag_key, tag]) => {
                        return (React.createElement("option", { key: tag.name, value: tag_key }, tag.name));
                    }))));
            }
        }),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, tags_visible && tags_group_key != '' && Object.keys(fluxTags).includes(tags_group_key) && multi_selected_links.current.length != 0 ? Object.entries(fluxTags[tags_group_key].tags).map(([tag_key, tag]) => {
            const is_selected = ValueSelectedParameter().tags[tags_group_key] && ValueSelectedParameter().tags[tags_group_key].includes(tag_key);
            return (React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: is_selected, onChange: (evt) => {
                    const visible = evt.target.checked;
                    Object.values(data.links).filter(f => multi_selected_links.current.map(d => d.idLink).includes(f.idLink)).map(d => {
                        updateLinkTagValue(d, data_tags_selected, tags_group_key, tag_key, visible);
                    });
                    node_function.RedrawNodes(Object.values(applicationData.display_nodes));
                    link_function.RedrawLinks(multi_selected_links.current);
                    ComponentUpdater.updateComponenSaveInCache.current(false);
                    setForceUpdate(!forceUpdate);
                } }, tag.name));
        }) : (React.createElement(React.Fragment, null))));
    return menu_for_modal ? content :
        // [ 
        //   <Tab>
        //     <Box
        //       layerStyle='submenuconfig_tab'
        //     >
        //       {t('Noeud.tags_node.tags')}
        //     </Box>
        //   </Tab>,
        React.createElement(TabPanel, null, content);
};
