import React, { useRef, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Box, Checkbox, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Input, InputGroup, InputRightAddon, } from '@chakra-ui/react';
import * as d3 from 'd3';
import { DrawGrid } from '../draw/SankeyDrawFunction';
import { OSTooltip } from './SankeyUtils';
export const OpenSankeyMenuConfigurationLayout = ({ applicationContext, applicationData, extra_background_element, node_function, link_function, reDrawLegend, ComponentUpdater }) => {
    const { t } = applicationContext;
    const { data, set_data } = applicationData;
    const { RedrawNodes } = node_function;
    const { RedrawLinks } = link_function;
    const { updateComponentMenuConfigLayout } = ComponentUpdater;
    const [legend_position, set_legend_position] = useState(data.legend_position);
    const [forceUpdate, setForceUpdate] = useState(false);
    updateComponentMenuConfigLayout.current = () => setForceUpdate(!forceUpdate);
    const { updateComponentMenuConfigLink } = ComponentUpdater;
    updateComponentMenuConfigLink.current = () => setForceUpdate(!forceUpdate);
    const right_addon_pixel = (val) => {
        if (val === 1) {
            return 'pixel';
        }
        return 'pixels';
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('Menu.background')),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.BgC')),
            React.createElement(OSTooltip, { label: t('MEP.tooltips.BgC') },
                React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: data.couleur_fond_sankey, onChange: evt => {
                        data.couleur_fond_sankey = evt.target.value;
                        d3.select('#svg').style('background-color', evt.target.value);
                    }, onBlur: () => {
                        set_data(Object.assign({}, data));
                    } }))),
        extra_background_element,
        React.createElement(Box, { as: 'span' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.grid_visible, icon: data.grid_visible ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), onChange: (evt) => {
                    data.grid_visible = evt.target.checked;
                    DrawGrid(data);
                    setForceUpdate(!forceUpdate);
                } },
                React.createElement(OSTooltip, { label: t('MEP.tooltips.GV') }, t('MEP.TCG')))),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols', style: { display: (data.grid_visible ? '' : 'none') } },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.TCG_shift')),
            React.createElement(Box, null,
                React.createElement(OSTooltip, { label: t('MEP.tooltips.TCG') },
                    React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'grid_square_size', function_onBlur: () => DrawGrid(data), minimum_value: 10, stepper: true, unitText: right_addon_pixel(data.grid_square_size) })))),
        React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('MEP.links_size')),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.Echelle')),
            React.createElement(Box, null,
                React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'user_scale', function_onBlur: () => {
                        reDrawLegend();
                        RedrawNodes(Object.values(applicationData.display_nodes));
                        RedrawLinks(Object.values(applicationData.display_links));
                        ComponentUpdater.updateComponenSaveInCache.current(false);
                    }, minimum_value: 1, stepper: true, unitText: 'unit. / 100 pixels' }))),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_2row_3cols' },
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', gridColumnStart: '1', gridColumnEnd: '2', gridRowStart: '2', gridRowEnd: '3' }, t('MEP.link_size_limit')),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', gridColumnStart: '2', gridColumnEnd: '3', gridRowStart: '1', gridRowEnd: '2', alignItems: 'flex-end' }, t('MEP.MinFlux')),
            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name', gridColumnStart: '3', gridColumnEnd: '4', gridRowStart: '1', gridRowEnd: '2', alignItems: 'flex-end' }, t('MEP.MaxFlux')),
            React.createElement(Box, { gridColumnStart: '2', gridColumnEnd: '3', gridRowStart: '2', gridRowEnd: '3' },
                React.createElement(OSTooltip, { label: t('MEP.tooltips.MinFlux') },
                    React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'minimum_flux', function_onBlur: () => {
                            RedrawNodes(Object.values(applicationData.display_nodes));
                            RedrawLinks(Object.values(applicationData.display_links));
                            ComponentUpdater.updateComponenSaveInCache.current(false);
                        }, unitText: right_addon_pixel(data.minimum_flux) }))),
            React.createElement(Box, { gridColumnStart: '3', gridColumnEnd: '4', gridRowStart: '2', gridRowEnd: '3' },
                React.createElement(OSTooltip, { label: t('MEP.tooltips.MaxFlux') },
                    React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'maximum_flux', function_onBlur: () => {
                            RedrawNodes(Object.values(applicationData.display_nodes));
                            RedrawLinks(Object.values(applicationData.display_links));
                            ComponentUpdater.updateComponenSaveInCache.current(false);
                        }, unitText: right_addon_pixel(data.maximum_flux) })))),
        React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_part_title_1_checkbox', icon: data.mask_legend ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), isChecked: data.mask_legend, onChange: (evt) => {
                    data.mask_legend = evt.target.checked;
                    setForceUpdate(!forceUpdate);
                    reDrawLegend();
                } }, t('Menu.Leg'))),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_grid', style: { display: (data.mask_legend ? '' : 'none') } },
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('MEP.leg_layout')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, t('MEP.leg_layout_text')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('Menu.fontSize')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.fontSize') },
                    React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'legend_police', function_onBlur: () => {
                            reDrawLegend();
                        }, minimum_value: 1, stepper: true }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_3' }, t('MEP.leg_layout_background')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('Menu.LegBgColor')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegBgColor') },
                    React.createElement(Input, { variant: 'menuconfigpanel_option_input_color', type: 'color', value: data.legend_bg_color, onChange: evt => {
                            data.legend_bg_color = evt.target.value;
                            reDrawLegend();
                            setForceUpdate(!forceUpdate);
                        } }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('Menu.LegBgOpacity')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegBgOpacity') },
                    React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'legend_bg_opacity', function_onBlur: () => {
                            reDrawLegend();
                        }, minimum_value: 0, maximum_value: 100, stepper: true, unitText: '%' }))),
            React.createElement(Box, { as: 'span' },
                React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.legend_bg_border, onChange: (evt) => {
                        data.legend_bg_border = evt.target.checked;
                        reDrawLegend();
                        setForceUpdate(!forceUpdate);
                    } },
                    React.createElement(OSTooltip, { label: t('Menu.tooltips.LegBgBorder') }, t('Menu.LegBgBorder')))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('MEP.leg_pos')),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.LegX')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegX') },
                    React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                        React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput_with_right_addon', min: 0, step: 1, value: Math.round(legend_position[0]), inputMode: 'numeric', onChange: value => set_legend_position([Number(value), legend_position[1]]), onBlur: () => {
                                data.legend_position = legend_position;
                                reDrawLegend();
                                setForceUpdate(!forceUpdate);
                            } },
                            React.createElement(NumberInputField, null),
                            React.createElement(NumberInputStepper, null,
                                React.createElement(NumberIncrementStepper, null),
                                React.createElement(NumberDecrementStepper, null))),
                        React.createElement(InputRightAddon, null, right_addon_pixel(Math.round(legend_position[0])))))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.LegY')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegY') },
                    React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
                        React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput_with_right_addon', min: 0, step: 1, value: Math.round(legend_position[1]), inputMode: 'numeric', onChange: value => set_legend_position([legend_position[0], Number(value)]), onBlur: () => {
                                data.legend_position = legend_position;
                                reDrawLegend();
                                setForceUpdate(!forceUpdate);
                            } },
                            React.createElement(NumberInputField, null),
                            React.createElement(NumberInputStepper, null,
                                React.createElement(NumberIncrementStepper, null),
                                React.createElement(NumberDecrementStepper, null))),
                        React.createElement(InputRightAddon, null, right_addon_pixel(Math.round(legend_position[1])))))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.LegWidth')),
                React.createElement(OSTooltip, { label: t('Menu.tooltips.LegWidth') },
                    React.createElement(ConfigLayoutNumberInput, { data: applicationData.data, var_of_data: 'legend_width', function_onBlur: () => {
                            reDrawLegend();
                        }, minimum_value: 0, stepper: true, unitText: right_addon_pixel(data.legend_width) }))),
            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('MEP.leg_info')),
            React.createElement(Box, { as: 'span' },
                React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.display_legend_scale, checked: data.display_legend_scale, onChange: (evt) => {
                        data.display_legend_scale = evt.target.checked;
                        reDrawLegend();
                        setForceUpdate(!forceUpdate);
                    } }, t('Menu.display_scale'))),
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.legend_show_dataTags, checked: data.legend_show_dataTags, onChange: (evt) => {
                    data.legend_show_dataTags = evt.target.checked;
                    reDrawLegend();
                    setForceUpdate(!forceUpdate);
                } }, t('MEP.leg_show_dataTags'))));
};
/**
 * Component developped for number input of the layout config menu
 *
 * @param {SankeyData} data
 * @param {keyof SankeyData} var_of_data keyof of the variable we want to reference in the inputn the variable in SankeyData need to be a number
 * @param {number} minimum_value (optional, if not specified it mean the value can be undefined )
 * @param {boolean} stepper (default:false) add stepper to the input to increase or decrease the value
 * @param {boolean} hasUnit (default:false) add an addon after the input
 * @param {string} unitText (default:'') text of the addon
 * @param {function} function_onBlur function called when we leave the input, it is generally used to update the draw area
 *
 * @return {JSX.Elmement}
 */
export const ConfigLayoutNumberInput = ({ data, var_of_data, minimum_value, maximum_value, stepper = false, unitText, function_onBlur }) => {
    const [update, setUpdate] = useState(false);
    const ref_input = useRef(null);
    const isModifying = useRef();
    const variantOfInput = unitText ? 'menuconfigpanel_option_numberinput_with_right_addon' : 'menuconfigpanel_option_numberinput';
    const val_of_key = data[var_of_data];
    // Add stepper addon if specified
    const stepperBtn = stepper ? React.createElement(NumberInputStepper, null,
        React.createElement(NumberIncrementStepper, null),
        React.createElement(NumberDecrementStepper, null)) : React.createElement(React.Fragment, null);
    // Add unit addon if specified
    const inputUnit = unitText ? React.createElement(InputRightAddon, null, unitText) : React.createElement(React.Fragment, null);
    return React.createElement(InputGroup, { variant: 'menuconfigpanel_option_input' },
        React.createElement(NumberInput, { allowMouseWheel: true, variant: variantOfInput, min: minimum_value, max: maximum_value, step: 1, value: (val_of_key || val_of_key === 0) ? val_of_key : '', onChange: (_, value) => {
                data[var_of_data] = value;
                setUpdate(!update);
                if (isModifying.current) {
                    clearTimeout(isModifying.current);
                }
                isModifying.current = setTimeout(() => {
                    var _a;
                    function_onBlur();
                    (_a = ref_input.current) === null || _a === void 0 ? void 0 : _a.blur();
                }, 2000);
            }, onBlur: () => {
                clearTimeout(isModifying.current);
                function_onBlur();
            } },
            React.createElement(NumberInputField, { ref: ref_input }),
            stepperBtn),
        inputUnit);
};
