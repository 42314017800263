import * as d3 from 'd3';
import React, { useState } from 'react';
import { SelectVisualyLinks, nodeTransform } from '../draw/SankeyDrawFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { NodeContextHasAggregate, NodeContextHasDesaggregate, DeleteNode, ReturnValueLink, ReturnValueNode, AssignNodeLocalAttribute } from '../configmenus/SankeyUtils';
import { Aggregate, Desaggregate } from '../draw/SankeyDrawLayout';
import { reorganize_node_outputLinksId } from '../draw/SankeyDrawLayout';
import { reorganize_node_inputLinksId } from '../draw/SankeyDrawLayout';
import { DeleteGLinks } from '../draw/SankeyDrawLinks';
import { DeleteGNodes } from '../draw/SankeyDrawNodes';
import { Box, Button, ButtonGroup, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
const icon_open_modal = React.createElement(FontAwesomeIcon, { style: { float: 'right' }, icon: faUpRightFromSquare });
const sep = React.createElement("hr", { style: { borderStyle: 'none', margin: '0px', color: 'grey', backgroundColor: 'grey', height: 2 } });
export const ContextMenuNode = ({ applicationContext, applicationData, applicationState, contextMenu, dict_hook_ref_setter_show_dialog_components, agregation, node_function, link_function, ComponentUpdater, additional_context_element_menu, additional_context_element_other }) => {
    const [contextualised_node, set_contextualised_node] = useState();
    contextMenu.ref_setter_contextualised_node.current = set_contextualised_node;
    const { updateComponentMenuConfigLink } = ComponentUpdater;
    const [forceUpdate, setForceUpdate] = useState(false);
    const { t } = applicationContext;
    const { data, set_data } = applicationData;
    const { multi_selected_nodes, multi_selected_links } = applicationState;
    const { pointer_pos } = contextMenu;
    const { RedrawNodes } = node_function;
    const { RedrawLinks } = link_function;
    let style_c_n = '0px 0px auto auto';
    let is_top = true;
    let pos_x = pointer_pos.current[0] + 10;
    let pos_y = pointer_pos.current[1] - 20;
    // The limit value of the mouse position that engages the shift of the context menu
    // is arbitrary and taken by hand because it is not possible to know the dimensions of the menu before it is render
    if (contextualised_node) {
        if (pointer_pos.current[0] + 410 > window.innerWidth) {
            pos_x = pointer_pos.current[0] - 400;
        }
        if (pointer_pos.current[1] + 490 > window.innerHeight) {
            pos_y = pointer_pos.current[1] - 470;
            is_top = false;
        }
        style_c_n = pos_y + 'px auto auto ' + pos_x + 'px';
    }
    const contextualised_node_shape_visible = contextualised_node !== undefined ? ReturnValueNode(data, contextualised_node, 'shape_visible') : false;
    const contextualised_node_label_visible = contextualised_node !== undefined ? ReturnValueNode(data, contextualised_node, 'label_visible') : false;
    const contextualised_node_value_visible = contextualised_node !== undefined ? ReturnValueNode(data, contextualised_node, 'show_value') : false;
    // b:before,m:middle,a:after
    const align_node = (ref, attr, pos) => {
        const node_ref = multi_selected_nodes.current.filter(nf => ReturnValueNode(data, nf, 'position') != 'relative').sort((n1, n2) => {
            return ref == 'min' ? n1[attr] - n2[attr] : n2[attr] - n1[attr];
        })[0];
        const pos_ref = node_ref[attr];
        const wORh = (attr == 'x') ? 'width' : 'height';
        const is_circle = d3.select('#shape_' + node_ref.idNode).attr('rx') !== null;
        const wORh_ref = is_circle ? Number(d3.select('#shape_' + node_ref.idNode).attr('r' + attr)) : Number(d3.select('#shape_' + node_ref.idNode).attr(wORh));
        let center_ref = 0;
        if (pos === 'm') {
            center_ref = pos_ref + (wORh_ref / 2);
        }
        multi_selected_nodes.current.filter(n => n != node_ref && ReturnValueNode(data, n, 'position') != 'relative').forEach(n => {
            const is_circle_to_shift = d3.select('#shape_' + n.idNode).attr('rx') !== null;
            const wORh_to_shift = is_circle_to_shift ? Number(d3.select('#shape_' + n.idNode).attr('r' + attr)) : Number(d3.select('#shape_' + n.idNode).attr(wORh));
            if (pos === 'm') {
                n[attr] = center_ref - ((wORh_to_shift) / 2);
            }
            else if (pos === 'b') {
                n[attr] = pos_ref;
            }
            else if (pos === 'a') {
                n[attr] = (pos_ref + wORh_ref) - wORh_to_shift;
            }
        });
        multi_selected_nodes.current.forEach(n => {
            d3.select('#ggg_' + n.idNode).attr('transform', nodeTransform(applicationData, n, link_function, false));
        });
        let link_to_update = [];
        multi_selected_nodes.current.forEach(n => {
            link_to_update = link_to_update.concat(n.outputLinksId);
            link_to_update = link_to_update.concat(n.inputLinksId);
        });
        link_to_update = [...new Set(link_to_update)];
        RedrawLinks(link_to_update.map(lid => data.links[lid]));
        ComponentUpdater.updateComponenSaveInCache.current(false);
    };
    const dropdown_c_n_apparence = React.createElement(Button, { onClick: () => {
            dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_node_apparence.current(true);
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
        }, variant: 'contextmenu_button' },
        t('Noeud.apparence.apparence'),
        " ",
        icon_open_modal);
    const dropdown_c_n_tooltip = React.createElement(Button, { onClick: () => {
            dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_node_tooltip.current(true);
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
        }, variant: 'contextmenu_button' },
        t('Noeud.IS'),
        " ",
        icon_open_modal);
    // Menu to change some pararmeter concerning the style of the node
    const dropdown_c_n_style_select = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.SelectStyle')),
        React.createElement(MenuList, null, Object.values(data.style_node).map(sn => {
            return React.createElement(MenuItem, { onClick: () => {
                    if (contextualised_node) {
                        contextualised_node.style = sn.idNode;
                        multi_selected_nodes.current.filter(n => n != contextualised_node).forEach(n => n.style = sn.idNode);
                        RedrawNodes(multi_selected_nodes.current);
                        ComponentUpdater.updateComponenSaveInCache.current(false);
                    }
                } }, sn.name);
        })));
    const dropdown_c_n_style = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.editStyle')),
        React.createElement(MenuList, null,
            React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
                    delete contextualised_node.local;
                    multi_selected_nodes.current.filter(n => n != contextualised_node).forEach(n => delete n.local);
                    RedrawNodes(multi_selected_nodes.current);
                    ComponentUpdater.updateComponenSaveInCache.current(false);
                } }, t('Noeud.AS')),
            dropdown_c_n_style_select));
    const dropdown_c_n_io = React.createElement(Button, { onClick: () => {
            dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_node_io.current(true);
            ComponentUpdater.updateComponentMenuNodeIOSelectSideNode.current.forEach(_ => _());
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
        }, variant: 'contextmenu_button' },
        t('Noeud.PF.PF'),
        icon_open_modal);
    const dropdown_c_n_align_h_min_ori = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align_horiz_min')),
        React.createElement(MenuList, null,
            React.createElement(MenuItem, { onClick: () => {
                    align_node('min', 'x', 'b');
                } }, t('Noeud.align_horiz_left')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('min', 'x', 'm');
                } }, t('Noeud.align_horiz_center')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('min', 'x', 'a');
                } }, t('Noeud.align_horiz_right'))));
    const dropdown_c_n_align_h_max_ori = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align_horiz_max')),
        React.createElement(MenuList, null,
            React.createElement(MenuItem, { onClick: () => {
                    align_node('max', 'x', 'b');
                } }, t('Noeud.align_horiz_left')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('max', 'x', 'm');
                } }, t('Noeud.align_horiz_center')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('max', 'x', 'a');
                } }, t('Noeud.align_horiz_right'))));
    const dropdown_c_n_align_h = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align_horiz')),
        React.createElement(MenuList, null,
            dropdown_c_n_align_h_min_ori,
            dropdown_c_n_align_h_max_ori));
    // ===============ALIGNEMENT VERTICAL DES NOEUDS=======================================
    const dropdown_c_n_align_v_min_ori = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align_vert_min')),
        React.createElement(MenuList, null,
            React.createElement(MenuItem, { onClick: () => {
                    align_node('min', 'y', 'b');
                } }, t('Noeud.align_vert_top')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('min', 'y', 'm');
                } }, t('Noeud.align_horiz_center')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('min', 'y', 'a');
                } }, t('Noeud.align_vert_bottom'))));
    const dropdown_c_n_align_v_max_ori = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align_vert_max')),
        React.createElement(MenuList, null,
            React.createElement(MenuItem, { onClick: () => {
                    align_node('max', 'y', 'b');
                } }, t('Noeud.align_vert_top')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('max', 'y', 'm');
                } }, t('Noeud.align_horiz_center')),
            React.createElement(MenuItem, { onClick: () => {
                    align_node('max', 'y', 'a');
                } }, t('Noeud.align_vert_bottom'))));
    const dropdown_c_n_align_v = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align_vert')),
        React.createElement(MenuList, null,
            dropdown_c_n_align_v_min_ori,
            dropdown_c_n_align_v_max_ori));
    const dropdown_c_n_align = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.align')),
        React.createElement(MenuList, null,
            dropdown_c_n_align_h,
            dropdown_c_n_align_v));
    const button_edit_label_node = React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const label_x = (_b = (_a = document.getElementById('text_' + contextualised_node.idNode)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().x) !== null && _b !== void 0 ? _b : 0;
            const label_y = (_d = (_c = document.getElementById('text_' + contextualised_node.idNode)) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect().y) !== null && _d !== void 0 ? _d : 0;
            const node_x = (_f = (_e = document.getElementById('shape_' + contextualised_node.idNode)) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect().x) !== null && _f !== void 0 ? _f : 0;
            const node_y = (_h = (_g = document.getElementById('shape_' + contextualised_node.idNode)) === null || _g === void 0 ? void 0 : _g.getBoundingClientRect().y) !== null && _h !== void 0 ? _h : 0;
            d3.select('#fo_input_label_' + contextualised_node.idNode).style('display', 'inline-block');
            d3.select('#fo_input_label_' + contextualised_node.idNode).attr('x', (label_x - node_x)).attr('y', label_y - node_y);
            d3.select('#text_' + contextualised_node.idNode).style('visibility', 'hidden');
            (_j = document.getElementById('input_label_' + contextualised_node.idNode)) === null || _j === void 0 ? void 0 : _j.focus();
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
        } }, t('Noeud.labels.edit_node_label'));
    // Menu to select output or input links of the contextualised node
    const drp_dwn_slct_link = React.createElement(Menu, { placement: 'end' },
        React.createElement(MenuButton, { variant: 'contextmenu_button', as: Button, rightIcon: React.createElement(ChevronRightIcon, null), className: "dropdown-basic" }, t('Noeud.SlctL')),
        React.createElement(MenuList, null,
            React.createElement(MenuItem, { onClick: () => {
                    Object.values(data.nodes).filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode)).map(d => {
                        multi_selected_links.current = multi_selected_links.current.concat(Object.values(data.links).filter(l => d.outputLinksId.includes(l.idLink)));
                        const opacity = ReturnValueLink(data, multi_selected_links.current[0], 'opacity');
                        applicationState.ref_display_link_opacity.current.forEach(setter => setter(opacity));
                    });
                    multi_selected_links.current.forEach(l => SelectVisualyLinks(l));
                    updateComponentMenuConfigLink.current();
                    set_contextualised_node(undefined);
                } }, t('Noeud.SlctOL')),
            React.createElement(MenuItem, { onClick: () => {
                    Object.values(data.nodes).filter(f => multi_selected_nodes.current.map(d => d.idNode).includes(f.idNode)).map(d => {
                        multi_selected_links.current = multi_selected_links.current.concat(Object.values(data.links).filter(l => d.inputLinksId.includes(l.idLink)));
                        const opacity = ReturnValueLink(data, multi_selected_links.current[0], 'opacity');
                        applicationState.ref_display_link_opacity.current.forEach(setter => setter(opacity));
                    });
                    multi_selected_links.current.forEach(l => SelectVisualyLinks(l));
                    updateComponentMenuConfigLink.current();
                    set_contextualised_node(undefined);
                } }, t('Noeud.SlctIL'))));
    const btn_reorganise_link_io = React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            reorganize_node_inputLinksId(data, contextualised_node, data.nodes, data.links);
            reorganize_node_outputLinksId(data, contextualised_node, data.nodes, data.links);
            multi_selected_nodes.current.filter(n => n != contextualised_node).forEach(n => {
                reorganize_node_inputLinksId(data, n, data.nodes, data.links);
                reorganize_node_outputLinksId(data, n, data.nodes, data.links);
            });
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
            RedrawNodes(multi_selected_nodes.current);
            // Redraw link attached to modified node when the modification to the node
            let link_to_update = [];
            multi_selected_nodes.current.forEach(n => {
                link_to_update = link_to_update.concat(n.outputLinksId);
                link_to_update = link_to_update.concat(n.inputLinksId);
            });
            link_to_update = [...new Set(link_to_update)];
            const list_links = link_to_update.map(lid => data.links[lid]);
            RedrawLinks(list_links);
            ComponentUpdater.updateComponenSaveInCache.current(false);
        } }, t('Noeud.Reorg'));
    const btn_aggregate = multi_selected_nodes.current.filter(n => n != contextualised_node).length == 0 && contextualised_node && NodeContextHasAggregate(contextualised_node, data) ? React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            Aggregate(contextualised_node, data, agregation);
            multi_selected_nodes.current = [];
            node_function.recomputeDisplayedElement();
            set_data(Object.assign({}, data));
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
            ComponentUpdater.updateComponenSaveInCache.current(false);
        } }, t('Noeud.context_agregate')) : React.createElement(React.Fragment, null);
    const btn_desagregate = multi_selected_nodes.current.filter(n => n != contextualised_node).length == 0 && contextualised_node && NodeContextHasDesaggregate(contextualised_node, data) ? React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            Desaggregate(contextualised_node, applicationData, agregation);
            multi_selected_nodes.current = [];
            node_function.recomputeDisplayedElement();
            set_data(Object.assign({}, data));
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
            ComponentUpdater.updateComponenSaveInCache.current(false);
        } }, t('Noeud.context_desagregate')) : React.createElement(React.Fragment, null);
    const btn_mask_shape = React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            multi_selected_nodes.current.forEach(n => {
                AssignNodeLocalAttribute(n, 'shape_visible', !contextualised_node_shape_visible);
            });
            RedrawNodes(multi_selected_nodes.current);
            setForceUpdate(!forceUpdate);
            ComponentUpdater.updateComponenSaveInCache.current(false);
        } }, contextualised_node_shape_visible ? t('Noeud.apparence.hide_shape') : t('Noeud.apparence.display_shape'));
    const btn_mask_label = React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            multi_selected_nodes.current.forEach(n => {
                AssignNodeLocalAttribute(n, 'label_visible', !contextualised_node_label_visible);
            });
            RedrawNodes(multi_selected_nodes.current);
            setForceUpdate(!forceUpdate);
            ComponentUpdater.updateComponenSaveInCache.current(false);
        } }, contextualised_node_label_visible ? t('Noeud.apparence.hide_label') : t('Noeud.apparence.display_label'));
    const btn_mask_value = React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
            multi_selected_nodes.current.forEach(n => {
                AssignNodeLocalAttribute(n, 'show_value', !contextualised_node_value_visible);
            });
            RedrawNodes(multi_selected_nodes.current);
            setForceUpdate(!forceUpdate);
            ComponentUpdater.updateComponenSaveInCache.current(false);
        } }, contextualised_node_value_visible ? t('Noeud.apparence.hide_value') : t('Noeud.apparence.display_value'));
    const btn_c_n_show_tags_menu = React.createElement(Button, { onClick: () => {
            dict_hook_ref_setter_show_dialog_components.ref_setter_show_menu_node_tags.current(true);
            set_contextualised_node(undefined);
            contextMenu.ref_contextualised_node.current = undefined;
        }, variant: 'contextmenu_button' },
        t('Menu.Etiquettes'),
        " ",
        icon_open_modal);
    // Pop over that serve as context menu
    return contextualised_node !== undefined ? React.createElement(Box, { layerStyle: 'context_menu', id: "context_node_pop_over", className: 'context_popover ' + (is_top ? '' : 'at_bot'), zIndex: '3', style: { maxWidth: '100%', position: 'absolute', inset: style_c_n } },
        React.createElement(ButtonGroup, { orientation: 'vertical', isAttached: true },
            btn_aggregate,
            btn_desagregate,
            sep,
            multi_selected_nodes.current.length > 1 ? React.createElement(React.Fragment, null,
                dropdown_c_n_align,
                sep) : React.createElement(React.Fragment, null),
            additional_context_element_other,
            button_edit_label_node,
            React.createElement(Button, { variant: 'contextmenu_button', onClick: () => {
                    multi_selected_nodes.current.map(d => DeleteNode(data, d));
                    multi_selected_nodes.current = [];
                    set_contextualised_node(undefined);
                    contextMenu.ref_contextualised_node.current = undefined;
                    const tmp_node = Object.keys(data.nodes);
                    Object.entries(applicationData.display_nodes).filter(n => {
                        return !tmp_node.includes(n[0]);
                    }).forEach(n => {
                        DeleteGNodes([n[0]]);
                        delete applicationData.display_nodes[n[0]];
                    });
                    const tmp_link = Object.keys(data.links);
                    Object.entries(applicationData.display_links).filter(l => {
                        return !tmp_link.includes(l[0]);
                    }).forEach(l => {
                        DeleteGLinks([l[0]]);
                        delete applicationData.display_links[l[0]];
                    });
                    RedrawNodes(Object.values(applicationData.display_nodes));
                    RedrawLinks(Object.values(applicationData.display_links));
                    ComponentUpdater.updateComponenSaveInCache.current(false);
                } }, t('Menu.suppr')),
            sep,
            dropdown_c_n_style,
            btn_mask_shape,
            btn_mask_label,
            btn_mask_value,
            sep,
            btn_reorganise_link_io,
            drp_dwn_slct_link,
            sep,
            dropdown_c_n_apparence,
            btn_c_n_show_tags_menu,
            multi_selected_nodes.current.length == 1 ? dropdown_c_n_io : React.createElement(React.Fragment, null),
            dropdown_c_n_tooltip,
            additional_context_element_menu)) : React.createElement(React.Fragment, null);
};
